import React, { useEffect } from 'react';
import { 
  Route, Switch, useRouteMatch, 
  useHistory, Redirect 
} from 'react-router-dom';
import ActivityHistory from './ActivityHistory';
import ActivityUniqueUsers from './ActivityUniqueUsers';

const Activity = () => {
  const { path } = useRouteMatch()
  
  return (
    <Switch>
      <Route path={`${path}/users/:ghost/:actions`}>
        <ActivityUniqueUsers/>
      </Route>
      
      <Route path={`${path}/:ghost/:actions`}>
        <ActivityHistory/>
      </Route>
    </Switch>
  )
}

export default Activity