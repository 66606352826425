import React from 'react'

import {
  useForm,
  SubmitHandler,
  Controller
} from 'react-hook-form'

import {
  SimpleGrid ,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Switch,
  useColorModeValue,
  Box,
  Flex,
  Button,
  Alert,
  AlertIcon,
  Checkbox,
  Progress,
  useDisclosure
} from '@chakra-ui/react'
import Card from 'components/card/Card';

import { useHistory } from 'react-router-dom'
import Editor from 'components/editor/editor'
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { EmailInput } from 'types/email.types';
import SendTestEmailRecipientsDrawer from './send-test-drawer';

import { sendMassEmail } from 'apis/email';

const EmailForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  const { 
    control,
    register, 
    handleSubmit, 
    watch, 
    trigger,
    getValues,
    reset,
    formState: { errors } 
  } = useForm<EmailInput>();

  const mutation = useMutation((data: EmailInput) => {
    return sendMassEmail(data)
  })

  const onSubmit: SubmitHandler<EmailInput> = (data) => {
    mutation.mutate(data)
  }

  const onTest = async () => {
    const isValid = await trigger()
    if (isValid) {
      onOpen()
    }
  }

  return (
    <Card p='20px' boxShadow={cardShadow} width={700}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.subject !== undefined}>
          <FormLabel>Subject</FormLabel>
          <Input type='text'
            {...register('subject', {
              required: 'This is required'
            })} />
          <FormErrorMessage>
            {errors.subject && errors.subject.message}
          </FormErrorMessage>
          <FormHelperText></FormHelperText>
        </FormControl>

        <FormControl isInvalid={errors.introduction !== undefined}>
          <FormLabel>Introduction</FormLabel>
          <Controller
            name="introduction"
            control={control}
            rules={{
              required: true
            }}
            render={({ field }) => <Editor {...field} />}
          />
          <FormHelperText></FormHelperText>
          <FormErrorMessage>
            {errors.introduction && errors.introduction.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.body !== undefined}>
          <FormLabel>Body</FormLabel>
          <Controller
            name="body"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Editor {...field} />}
          />
          <FormHelperText></FormHelperText>
          <FormErrorMessage>
            {errors.body && errors.body.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.callToActionLabel !== undefined}>
          <FormLabel>Call to Action Label</FormLabel>
          <Input type='text'
          {...register('callToActionLabel', {
            required: 'This is required'
          })} />
          <FormHelperText></FormHelperText>
          <FormErrorMessage>
            {errors.callToActionLabel && errors.callToActionLabel.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.callToActionUrl !== undefined}>
          <FormLabel>Call to Action Url</FormLabel>
          <Input type='text'
          {...register('callToActionUrl', {
            required: 'This is required'
          })} />
          <FormHelperText></FormHelperText>
          <FormErrorMessage>
            {errors.callToActionUrl && errors.callToActionUrl.message}
          </FormErrorMessage>
        </FormControl>

        <Flex justifyContent={'flex-end'} mt={10}>
          <Button variant='outline' mr={3} onClick={onTest}>
            Test
          </Button>
            
          <Button
            type="submit"
            variant='darkBrand'
            color='white'
            fontSize='sm'
            fontWeight='500'
            borderRadius='70px'
            px='24px'
            py='5px'>
            Send
          </Button>
        </Flex>
      </form>
      <SendTestEmailRecipientsDrawer 
        data={getValues()}
        isOpen={isOpen} 
        onClose={onClose} 
        btnRef={btnRef} 
      />
    </Card>
  )
}

export default EmailForm