// Chakra imports
import { Flex } from '@chakra-ui/react';

export function SidebarBrand() {
	//   Chakra color mode

	return (
		<Flex alignItems='center' flexDirection='column'>
		</Flex>
	);
}

export default SidebarBrand;
