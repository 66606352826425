import React from 'react'
import {
  Box, SimpleGrid, Flex,
  useColorModeValue, Link
} from '@chakra-ui/react'

const Flags = () => {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      Feature not available yet
    </Box>
  )
}

export default Flags