import type { Spread } from "lexical";

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode
} from "lexical";


export type SerializedMentionNode = Spread<
  {
    mentionName: string;
    mentionId: string;
    type: "mention";
    version: 1;
  },
  SerializedTextNode
>;

function convertMentionElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const textContent = domNode.textContent;
  const id = domNode.getAttribute('data-userid')

  if (textContent !== null) {
    const node = $createMentionNode(textContent, id);
    return {
      node
    };
  }

  return null;
}

export class MentionNode extends TextNode {
  __mention: string;
  __id: string;

  static getType(): string {
    return "mention";
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__mention, node.__id, node.__text, node.__key);
  }
  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(serializedNode.mentionName, serializedNode.mentionId);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(mentionName: string, id:string, text?: string,key?: NodeKey) {
    super(text ?? mentionName, key);
    this.__mention = mentionName;
    this.__id = id;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      mentionName: this.__mention,
      mentionId: this.__id,
      type: "mention",
      version: 1
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.className = "mention";
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-mention", "true");
    element.setAttribute("data-userid", this.__id);
    
    //element.textContent = this.__text;

    const innerElement = document.createElement("a")
    innerElement.setAttribute("href", "/user/" + this.__id);
    innerElement.textContent = this.__mention;

    element.appendChild(innerElement)

    return { element };
  }

  isSegmented(): false {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-mention")) {
          return null;
        }
        return {
          conversion: convertMentionElement,
          priority: 1
        };
      }
    };
  }

  isTextEntity(): true {
    return true;
  }

  isToken(): true {
    return true;
  }
}

export function $createMentionNode(mentionName: string, id: string): MentionNode {
  const mentionNode = new MentionNode(mentionName, id);
  mentionNode.setMode("segmented").toggleDirectionless();
  return mentionNode;
}

export function $isMentionNode(
  node: LexicalNode | null | undefined
): node is MentionNode {
  return node instanceof MentionNode;
}
