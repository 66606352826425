import React from 'react'
import { 
  Box, SimpleGrid, Flex,
  useColorModeValue
} from "@chakra-ui/react";
import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import SubNavLink from 'components/subNav/link';
import AnalyticsView from './view/index'

const Artworks = () => {
  const { path } = useRouteMatch()

  return (
    <Box>
      <Flex
        ms="14px"
        mb="20px"
        align="center"
      >
        <SubNavLink 
          to={`${path}/ext`}
          label="Public"/>
        <SubNavLink 
          to={`${path}/int`}
          label="Actual"/>
      </Flex>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/ext`} />
        </Route>
        <Route path={`${path}/ext`}>
          <AnalyticsView />
        </Route>
        <Route path={`${path}/int`}>
          <AnalyticsView />
        </Route>
      </Switch>
    </Box>
  )
}

export default Artworks
