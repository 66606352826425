import React from 'react'
import { 
  Box, SimpleGrid, Flex,
  useColorModeValue, Link, Alert, AlertIcon, FormControl, 
  FormErrorMessage, FormHelperText, FormLabel, Select, Textarea, Button,
  useToast
} from "@chakra-ui/react";
import {
  useForm,
  SubmitHandler,
  Controller
} from 'react-hook-form'
import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Card from 'components/card/Card';

import { deleteUsers } from 'apis/users.api'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { ArticleTheme } from 'types/article.types';

type FormInput = {
  ids: string
}

const DeleteAccounts = () => {
  const { goBack } = useHistory()
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  const toast = useToast()

  const { 
    control,
    register, 
    handleSubmit, 
    watch, 
    reset,
    formState: { errors } 
  } = useForm<FormInput>();

  const onSubmit: SubmitHandler<FormInput> = (data) => {
    deleteUsers(data.ids)
    toast({
      title: "Accounts Deleted",
      description: "The accounts have been deleted",
      status: "success",
      duration: 4000,
      isClosable: true,
    })
  }

  const hasErrors = Object.keys(errors).length > 0

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card p='20px' boxShadow={cardShadow} width={700}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={1} spacing={5}>
          {hasErrors ? <Alert status='error'>
            <AlertIcon />
            Account ids are required
          </Alert> : null}

          <FormControl>
            <FormLabel>Account Ids</FormLabel>
            <Textarea 
              rows={8}
              {...register('ids', {
                required: true
              })}></Textarea>
            <FormErrorMessage>
              {errors.ids && errors.ids.message}
            </FormErrorMessage>
            <FormHelperText>Enter each account id on a new line,, this delete action is instant and permanent</FormHelperText>
          </FormControl>

          <Flex justifyContent={'flex-end'}>
            <Button variant='outline' mr={3} 
              onClick={() => {
                goBack()
              }}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant='darkBrand'
              color='white'
              fontSize='sm'
              fontWeight='500'
              borderRadius='70px'
              px='24px'
              py='5px'>
              Submit
            </Button>
          </Flex>

        </SimpleGrid>
      </form>
      </Card>
    </Box>
  )
}

export default DeleteAccounts
