import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { AuthProvider } from './providers/auth-provider'
import PrivateRoute from 'private.route';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<Router>
						<Switch>
							<Route path="/" exact>
								<Redirect to="/auth" />
							</Route>
							<Route path={`/auth`} component={AuthLayout} />
							<PrivateRoute path={`/admin`}>
								<AdminLayout/>
							</PrivateRoute>
						</Switch>
					</Router>
				</AuthProvider>
			</QueryClientProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
