import React from 'react'
import { 
  Box, SimpleGrid, Flex,
  useColorModeValue
} from "@chakra-ui/react";
import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import SubNavLink from 'components/subNav/link';
import Artworks from './artworks/index'
import ActionUsersTable from './action-users/action-users-table';
import ActionUserItemsTable from './action-user-items/action-user-items-table';

const Analytics = () => {
  const { path } = useRouteMatch()

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        ms="14px"
        mb="20px"
        align="center"
      >
        <SubNavLink 
          to={`${path}/artworks`}
          label="Artworks"/>
        <SubNavLink 
          to={`${path}/users/artworks/voted`}
          label="Voters"/>
      </Flex>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/artworks`} />
        </Route>
        <Route path={`${path}/artworks`}>
          <Artworks/>
        </Route>
        <Route path={`${path}/users/:schemaName/:action`} exact>
          <ActionUsersTable/>
        </Route>
        <Route path={`${path}/users/:userId/:schemaName/:action/items`}>
          <ActionUserItemsTable/>
        </Route>
      </Switch>
    </Box>
  )
}

export default Analytics
