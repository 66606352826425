import React, { ChangeEvent, useEffect, useState } from 'react';
import { 
  Route, Switch, useRouteMatch, 
  useHistory, Redirect 
} from 'react-router-dom';
import ArticleDetail from './ArticleDetail';
import ArticlesListing from './ArticlesListing'


const Articles = () => {
  const { path } = useRouteMatch()
  
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ArticlesListing/>
      </Route>
      <Route path={`${path}/:id`}>
        <ArticleDetail/>
      </Route>
    </Switch>
  )
}

export default Articles