import React from "react";
import { 
  Box
} from '@chakra-ui/react';
import { 
  FaEye
} from 'react-icons/fa';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';

import { getActivityItemsByUserActions } from 'apis/analytics.api';
import DataTable from 'components/dataTable/dataTable2'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Config from 'config'
import { GetItemsByUserActionsItem } from 'types/analytic.types'

dayjs.extend(localizedFormat)

type StatsProps = {
  size?: number
}

const urlMap: Record<string, (id: string) => unknown> = {
  'artworks': (id: string) => `/admin/submissions/artworks/${id}`,
  'articles': (id: string) => `/admin/submissions/articles/${id}`,
}

export default function Stats({
  size = 30
}: StatsProps) {
  const { path, url } = useRouteMatch()
  const { push } = useHistory()
  const { id } = useParams<{
    id: string
  }>()

  const searchParams = new URLSearchParams(window.location.search);
  const actions = searchParams.get('actions')?.split(',') ?? []

  const columnHelper = createColumnHelper<GetItemsByUserActionsItem>()

  const columns: Array<ColumnDef<GetItemsByUserActionsItem, any>> = [
    columnHelper.accessor('imageUrl', {
      header: 'Image',
      cell: props => <img style={{width:'200px'}} src={`${Config.uploadsUrl}/${props.getValue()}`} alt=""/>,
      enableSorting: false,
      size: 50
    }),
    columnHelper.accessor('schemaName', {
      header: 'Type',
      cell: props => props.getValue(),
      enableSorting: false,
      size: 15
    }),
    columnHelper.accessor('title', {
      header: 'Title',
      cell: props => props.getValue(),
      enableSorting: false,
      size: 35
    }),
    columnHelper.accessor('id', {
      header: '',
      cell: (props) => <FaEye onClick={() => {
        push(urlMap[props.row.original.schemaName](props.getValue()))
      }} />,
      size: 5
    })
  ]

  const props: DataTableProps<GetItemsByUserActionsItem, DataTableQuery> = {
    title: '',
    enableSorting: true,
    columnsMeta: columns,
    page: Number(searchParams.get('page') ?? 0),
    size: Number(searchParams.get('size') ?? size),
    sort: searchParams.get('sort'),
    desc: (searchParams.get('desc') ?? 'true') === 'true',
    cacheKey: `activity-action-user-${actions.join('-')}-items`,
    queryFn: ({ page, size }) => getActivityItemsByUserActions({
      actions,
      userId: id,
      page,
      size
    }),
    onRowClick: (data: GetItemsByUserActionsItem) => {
    }
  }

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      <DataTable {...props} />
    </Box>
  )
}