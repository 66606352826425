import React, { useEffect } from 'react'
import {
  useForm,
  SubmitHandler,
  Controller
} from 'react-hook-form'
import {
  SimpleGrid ,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Switch,
  useColorModeValue,
  Box,
  Flex,
  Button,
  Alert,
  AlertIcon,
  Checkbox,
  Progress
} from '@chakra-ui/react'
import { useHistory,useParams } from 'react-router-dom'

import Editor from 'components/editor/editor'
import ImageUpload from 'components/upload-image/upload-image'

import { CreateItchArticleInput, UpdateItchArticleInput, ArticleTheme } from 'types/article.types'
import Card from 'components/card/Card';

import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { getOneArticle, addArticle, updateOneArticle, deleteOneArticle } from 'apis/zine.api'

import { ReviewStatus } from 'types/shared/approvable.types'

const ArticleForm = () => {
  const dangerColor = 'red.500'
  const { id } = useParams<{
    id: string
  }>()
  const { push } = useHistory()
  const { isLoading, isSuccess, data } = useQuery({ 
    queryFn: () => {
      return getOneArticle(id)
    },
    cacheTime: 0,
    staleTime: 0
  })
  const queryClient = useQueryClient()
  const mutationAddArticle = useMutation((data: CreateItchArticleInput) => {
    return addArticle({
      ...data,
      tags: []
    })
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['itch-articles']})
    }
  })
  const mutationUpdateArticle = useMutation((data: UpdateItchArticleInput) => {
    return updateOneArticle({
      id,
      ...data,
      tags: []
    })
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['itch-articles']})
      queryClient.invalidateQueries({ queryKey: ['itch-articles', { id }]})
    }
  })
  const mutationDeleteArticle = useMutation((id: string) => {
    return deleteOneArticle(id)
  }, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['itch-articles']})
      queryClient.invalidateQueries(['chapter', variables])
    }
  })

  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  const { 
    control,
    register, 
    handleSubmit, 
    watch, 
    reset,
    formState: { errors } 
  } = useForm<CreateItchArticleInput>({
    mode: 'onSubmit',
    defaultValues: null
  });
  const watchId = watch('_id')

  const onSubmit: SubmitHandler<CreateItchArticleInput> = async (data) => {
    if (!id) {
      mutationAddArticle.mutate(data)
    } else {
      await mutationUpdateArticle.mutate(data)
    }
    push('/admin/zine/articles')
  }

  const onDelete = () => {
    mutationDeleteArticle.mutate(id)
    push('/admin/zine/articles')
  }

  const onClose = () => {
    push('/admin/zine/articles')
  }

  const hasErrors = Object.keys(errors).length > 0

  useEffect(() => {
    if (isSuccess && data) { 
      const { theme, title, coverImage, subHead, 
        caption, category, itchArticleMentionUser, content, 
        reviewStatus, isItchArticleLatest, _id } = data
      reset({
        _id,
        theme,
        title,
        coverImage,
        subHead,
        caption,
        category,
        itchArticleMentionUser,
        content,
        published: reviewStatus === ReviewStatus.Approved,
        isItchArticleLatest
      })
    }
  }, [isSuccess, data])

  const isEditing = (id !== undefined);
  console.log(isLoading, isSuccess, data, watchId)

  return (
    <Card p='20px' boxShadow={cardShadow} width={700}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {((isEditing && isSuccess && data && watchId) || !isEditing) ? <SimpleGrid columns={1} spacing={5}>
          {hasErrors ? <Alert status='error'>
            <AlertIcon />
            There was an error processing your request
          </Alert> : null}
          <FormControl>
            <FormLabel>Theme</FormLabel>
            
            <Select placeholder='Select Status' width={130} 
              {...register('theme', {
                required: 'This is required'
              })}
            >
              <option value={ArticleTheme.Pink}>Pink</option>
              <option value={ArticleTheme.White}>White</option>
              <option value={ArticleTheme.Dark}>Dark</option>
            </Select>
            <FormErrorMessage>
              {errors.theme && errors.theme.message}
            </FormErrorMessage>
            <FormHelperText></FormHelperText>
          </FormControl>
          
          <FormControl>
            <FormLabel>Upload Cover Image</FormLabel>
            <Controller
              name="coverImage"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => <ImageUpload 
                width={400} 
                height={400} 
                disabled={false} 
                noUpload={false}
                placeholderImg={''}
                {...field}
              />}
            />
            <FormHelperText></FormHelperText>
            <FormErrorMessage>
              {errors.coverImage && errors.coverImage.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel>Caption</FormLabel>
            <Input type='text'
            {...register('caption', {
              required: 'This is required'
            })} />
            <FormHelperText></FormHelperText>
            <FormErrorMessage>
              {errors.caption && errors.caption.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel>Article Type</FormLabel>
            <Input type='text'
            {...register('category', {
              required: 'This is required'
            })} />
            <FormHelperText></FormHelperText>
            <FormErrorMessage>
              {errors.caption && errors.caption.message}
            </FormErrorMessage>
          </FormControl>
          
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input type='text'
            {...register('title', {
              required: 'This is required'
            })} />
            <FormHelperText></FormHelperText>
            <FormErrorMessage>
              {errors.title && errors.title.message}
            </FormErrorMessage>
          </FormControl>
          
          <FormControl>
            <FormLabel>Sub Head</FormLabel>
            <Input type='text' 
            {...register('subHead', {
              required: 'This is required'
            })} />
            <FormHelperText></FormHelperText>
            <FormErrorMessage>
              {errors.subHead && errors.subHead.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel>By User</FormLabel>
            <Input type='text'
            {...register('itchArticleMentionUser', {
              required: 'This is required'
            })} />
            <FormHelperText>Enter user ID</FormHelperText>
            <FormErrorMessage>
              {errors.userId && errors.userId.message}
            </FormErrorMessage>
          </FormControl>
          
          <FormControl>
            <FormLabel>Paragraph Text</FormLabel>
            <Controller
              name="content"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => <Editor {...field} />}
            />
            <FormHelperText></FormHelperText>
            <FormErrorMessage>
              {errors.content && errors.content.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel>Published</FormLabel>
            <Controller
              control={control}
              name="published"
              render={({ 
                field: { onChange, onBlur, value, name, ref },
                fieldState, formState }) => (
                <Checkbox
                  type="checkbox"
                  onChange={(evt) => {
                    onChange(evt.target.checked)
                  }}
                  onBlur={onBlur}
                  isChecked={value}
                  name={name} 
                  ref={ref} />
              )}
            />
            <FormHelperText></FormHelperText>
          </FormControl>

          <Flex justifyContent={'flex-end'}>
            <Button variant='outline' 
              color={dangerColor}
              borderColor={dangerColor} 
              mr={'auto'} onClick={onDelete}>
              Delete
            </Button>

            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant='darkBrand'
              color='white'
              fontSize='sm'
              fontWeight='500'
              borderRadius='70px'
              px='24px'
              py='5px'>
              Save
            </Button>
          </Flex>
        </SimpleGrid > : <Progress size='sm' isIndeterminate /> }
      </form>
    </Card>
  )
}

export default ArticleForm