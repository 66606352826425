import React, { 
  forwardRef,
  useRef,
  useEffect,
  useState
} from 'react';

import {
  Box,
  Flex,
  useColorModeValue,
  Button
} from '@chakra-ui/react'
import { MdRemoveRedEye } from 'react-icons/md'

import useUpload from 'hooks/use-upload.hook';
import useDeleteUpload from 'hooks/delete-upload.hook';
import { Progress } from '@chakra-ui/react'

import {
  DeleteIcon
} from '@chakra-ui/icons'

import {
  imageUrl
} from 'shared/utils'

type Image = {
  url: string,
  position: string,
  mimeType: string,
  width: number,
  height: number,
  filename: string
}

const EyeAxis = {
  Horizontal: 1,
  Vertical: 2
}

const getImageEyeAxis = (previewDimensions: number[], imageDimensions: number[]) => {
  let eyeAxis = null
  const imageRatio = imageDimensions[0] / imageDimensions[1];

  // compare the dimensions
  const imageDimensionsScaled = [previewDimensions[0], previewDimensions[0] / imageRatio];
  
  if (imageDimensionsScaled[1] >= previewDimensions[1]) {
    eyeAxis = EyeAxis.Vertical;
  } else {
    eyeAxis = EyeAxis.Horizontal;
  }

  return eyeAxis;
}

type ImageUploadProps = {
  value?: Image,
  width: number,
  height: number,
  disabled?: boolean,
  noUpload?: boolean,
  onChange?: (props: {
    url: string,
    position: string,
    mimeType: string,
    width: number,
    height: number,
    filename: string
  }) => unknown,
  onBlur?: () => unknown,
  placeholderImg: string
}

export default forwardRef(function ImageUpload(
  props: ImageUploadProps,
  ref
) {
  // const { setErrorAlert } = useErrorAlert()
  const { 
    uploadFiles,
    isLoading,
    isSuccess,
    isError,
    result,
    error,
    resetUpload
  } = useUpload()

  const deleteUpload = useDeleteUpload()

  const { 
    value,
    width,
    height,
    disabled,
    noUpload,
    ...rest
  } = props;

  const dropZoneOnDragOverColor = useColorModeValue('navy.700', 'white');

  const [uploadCount, setUploadCount] = useState(0);
  const [touchDrag, setTouchDrag] = useState(false)
  const [isDragging, setIsDragging] = useState(false);
  const previewElem = useRef(null);
  const eyeElem = useRef(null);
  const containerElem = useRef(null);
  const eyeDraggableElem = useRef(null);
  const [fileDraggedOver, setFileDraggedOver] = useState(false)
    
  function dragElement(elmnt: HTMLDivElement, followElmnt: HTMLDivElement, fileUploaded: Image) {
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let eyeAxis: number = null;
    elmnt.onmousedown = dragMouseDown;

    function dragMouseDown(e: MouseEvent) {
      pos1 = 0;
      pos2 = 0;
      pos3 = 0;
      pos4 = 0;
      // get the image orientation
      eyeAxis = Number(followElmnt.getAttribute('data-eye-axis'));
      
      setTouchDrag(true)
      setIsDragging(true)

      // e = e || window.event;
      e.preventDefault();
      e.stopPropagation();
      // get the mouse cursor position at startup:
      if (eyeAxis === EyeAxis.Horizontal) {
        pos3 = e.clientX;
      }
      if (eyeAxis === EyeAxis.Vertical) {
        pos4 = e.clientY
      }

      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;

      eyeElem.current.classList.add('on-drag')
    }

    function elementDrag(e: MouseEvent) {
      // e = e || window.event;
      e.preventDefault();
      
      const dragHintElem = eyeElem.current.querySelector('.drag-hint')
      if (dragHintElem) {
        dragHintElem.style.visibility = 'hidden'
      }

      document.body.style.cursor = 'grabbing'

      // calculate the new cursor position:
      if (eyeAxis === EyeAxis.Horizontal) {
        pos1 = pos3 - e.clientX;
        pos3 = e.clientX;
      }

      if (eyeAxis === EyeAxis.Vertical) {
        pos2 = pos4 - e.clientY;
        pos4 = e.clientY;
      }
      // set the element's new position:
      const newTop = elmnt.offsetTop - pos2;
      const newLeft = elmnt.offsetLeft - pos1;

      // check bounds, cannot exceed parent bounding rect
      const elmntBox = elmnt.getBoundingClientRect()
      const { width, height } = elmnt.parentElement.getBoundingClientRect();

      if (newTop >= 0 && newTop <= (height - elmntBox.height)
        && newLeft >= 0 && newLeft <= (width - elmntBox.width)) {
          elmnt.style.top = newTop + "px";
          elmnt.style.left = newLeft + "px";
    
          const pX = 100 * newLeft / width;
          const pY = 100 * newTop / height;
    
          if (followElmnt) {
            const position = `${pX}% ${pY}%`
            followElmnt.style.objectPosition = position;
          }
      }
    }

    function closeDragElement(evt: MouseEvent) {
      document.body.style.cursor = 'default'

      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
      // because the event bubbling continues after this
      // causes the file input to trigger the browse file

      const { url, mimeType, width, height, filename } = fileUploaded
      if (props.onChange)
        props.onChange({
          url,
          position: followElmnt.style.objectPosition,
          mimeType,
          width,
          height,
          filename
        })
      props.onBlur && props.onBlur()

      eyeElem.current.classList.remove('on-drag')
      
      setTimeout(() => {
        setIsDragging(false)
      }, 500)
    }
  }

  function handleFile(files: FileList) {
    if (files.length === 1) {
      uploadFiles(files)

      const reader = new FileReader();
      reader.onload = null;

      const img = new Image()
      reader.readAsDataURL(files[0]); // read the data blob from upload
      
      img.onload = function () {
        previewElem.current.setAttribute('data-width', img.width);
        previewElem.current.setAttribute('data-height', img.height);

        // find the axis for adjusting eye based on cover rule
        const previewDimensions = [previewElem.current.width, previewElem.current.height];
        const imageDimensions = [img.width, img.height];
        const eyeAxis = getImageEyeAxis(previewDimensions, imageDimensions)
        
        previewElem.current.setAttribute('data-eye-axis', eyeAxis)
      };

      reader.onload = () => {
        img.src = reader.result as string;
      };
    }
  }

  useEffect(() => {
    if (value) {
      const { url, width, height, position, filename } = props.value ?? null
      previewElem.current.src = `${process.env.REACT_APP_UPLOADS_URL}/${filename}`
      // show the eye element if there is a file passed thru props
      if (url) {
        eyeElem.current.style.display = 'flex';

        const [leftPercent, topPercent] = (position?? '50% 50%').split(' ')
        
        eyeDraggableElem.current.style.top = `${parseInt(topPercent.replace('%',''))/100 * previewElem.current.height}px`
        eyeDraggableElem.current.style.left = `${parseInt(leftPercent.replace('%',''))/100 * previewElem.current.width}px`
        dragElement(eyeDraggableElem.current, previewElem.current, props.value)

        previewElem.current.setAttribute('data-width', width);
        previewElem.current.setAttribute('data-height', height);

        // find the axis for adjusting eye based on cover rule
        const previewDimensions = [previewElem.current.width, previewElem.current.height];
        const imageDimensions = [width, height];
        const eyeAxis = getImageEyeAxis(previewDimensions, imageDimensions)
        
        previewElem.current.setAttribute('data-eye-axis', eyeAxis)
        
        previewElem.current.style.objectPosition = position ?? '50% 50%';

        if (position) {
          const dimensions = [
            eyeDraggableElem.current.height,
            eyeDraggableElem.current.width
          ];
          const positionsValues = position.split(' ').map((v, i) => Number(v.replace('%', '')) * dimensions[i]);
          if (eyeAxis === EyeAxis.Horizontal) {
            eyeDraggableElem.current.style.left = positionsValues[0];
          }
          if (eyeAxis === EyeAxis.Vertical) {
            eyeDraggableElem.current.style.top = positionsValues[1];
          }
        }
      }
    }
  }, [value])

  /*
  useEffect(() => {
    if (value) {
      previewElem.current.src = imageUrl(value)
    }
  }, [value])
  */

  useEffect(() => {
    if (isSuccess) {
      if (result && result.length > 0) {
        const url = process.env.REACT_APP_UPLOADS_URL + '/' + result[0].name;
        previewElem.current.src = url
          
        eyeElem.current.style.display = 'flex';
        // reset eye position
        eyeDraggableElem.current.style.top = 'unset';
        eyeDraggableElem.current.style.left = 'unset'; 

        const fileUploaded:Image = {
          url,
          position: null,
          mimeType: result[0].mimetype,
          width: result[0].width,
          height: result[0].height,
          filename: result[0].name
        }

        props.onChange && props.onChange(fileUploaded)
        props.onBlur && props.onBlur()

        setUploadCount(uploadCount + 1)

        dragElement(eyeDraggableElem.current, previewElem.current, fileUploaded)
      }
    } else
    if (isError) {
      let body = ''
      if (error) {
        switch(error[0].code) {
          case 'ERR_FILE_TYPES':
            body = 'Please upload a JPEG or PNG with a file size of no more than 20mb.'
            break;
          case 'ERR_FILE_SIZE':
            body = 'Please upload a JPEG or PNG with a file size of no more than 20mb.'
            break;
          default:
            body = 'Please upload a JPEG or PNG with a file size of no more than 20mb.'
        }
        /*
        setErrorAlert({
          active: true,
          body,
        })
        */
      }
    }
  }, [isSuccess, isError])

  return (
    <Flex
      justifyContent={'center'}
      width={width ?? '100%'}
      height={height ?? '100%'}
      cursor={'pointer'}
      pointerEvents={disabled || isDragging ? 'none':'all'}
      position={'relative'}
      userSelect={'none'}
      onDragOver={(evt: React.DragEvent<HTMLDivElement>) => {
        setFileDraggedOver(true)
        evt.preventDefault()
      }}
      onDrop={(evt: React.DragEvent<HTMLDivElement>) => {
        // handle file
        if (evt.dataTransfer.files 
          && evt.dataTransfer.files.length === 1) {
          handleFile(evt.dataTransfer.files)
        }

        setFileDraggedOver(false)
        
        evt.preventDefault()
      }}
    >
      {(result?.length === 1 || uploadCount === 1 || value) ? <Button type="button" position={'absolute'} zIndex={11} top={5} right={2} onClick={() => {
        previewElem.current.src = null
        deleteUpload.deleteFile(result[0].name)
        setUploadCount(0)
        eyeElem.current.style.display = 'none'
        resetUpload()
        props.onChange(null)
      }}>
        <DeleteIcon/>
      </Button> : null}

      <Box
        width={width ?? '100%'}
        height={height ?? '100%'}
        position={'absolute'}
        zIndex={4}
        backgroundColor={'transparent'}
        cursor={'pointer'}
      >
        <Flex
          opacity={0}
          width={'100%'}
          height={'100%'}
        >
          <input 
          style={{
            zIndex: 1,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: 'pointer'
          }}
          disabled={disabled || noUpload}
          type="file"
          onChange={(evt) => {
            if (evt.target.files.length === 1) {
              handleFile(evt.target.files)
            }
        }} />
        </Flex>
      </Box>
      {isLoading ? 
      <Box 
        position={'absolute'}
        zIndex={9}
        top={0}
        left={0}
        width={'100%'}
        height={'100%'}
        backgroundColor={'rgba(0,0,0, .85'}
      >
        <Progress/>
      </Box> : null}
      
      {!disabled ? <Box
        width={width} 
        height={height}
        position={'absolute'}
        zIndex={3}
        padding={12}
      >
        <Box
          border={props.placeholderImg === undefined && result === undefined ? `1px solid ${dropZoneOnDragOverColor}` : 'none'}
          backgroundColor={fileDraggedOver ? dropZoneOnDragOverColor : 'transparent'}
          width={'100%'}
          height={'100%'}
        />
      </Box> : null}
      <Flex
        position={'absolute'}
        alignItems={'center'}
        justifyContent={'center'}
        display={'none'}
        width={'100%'}
        height={'100%'}
        zIndex={10}
        ref={eyeElem}>
        <Flex
          ref={eyeDraggableElem}
          position={'absolute'}
          zIndex={2}
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'50%'}
          backgroundColor={'#fff'}
        >
          <Box
            width={'32px'} 
            height={'32px'} 
            borderRadius={'50%'}
            cursor={'grab'} 
            padding={'4px'}
          >
            <MdRemoveRedEye
              size={'24px'}
            />
          </Box>
          {(uploadCount === 1) ? 
            <Box 
              position={'absolute'}
              top={'100%'}
              whiteSpace={'nowrap'}
              transform={'translateY(5px'}
              padding={'5px 30px'}
              color={'rgba()'}
              backgroundColor={'rgba(255,255,255,0.6)'}
            >
            drag eye to adjust
          </Box> : null}
        </Flex>
        <input 
          style={{
            zIndex: 1,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: 'pointer'
          }}
          disabled={disabled || noUpload}
          type="file"
          onChange={(evt) => {
            if (evt.target.files.length === 1) {
              handleFile(evt.target.files)
            }
        }} />
      </Flex>
      <Box
        width={props.width ?? '100%'}
        height={props.height ?? '100%'}
        overflow={'hidden'}
        position={'relative'}
        padding={0}
        ref={containerElem}>
        <Box
          width={props.width ?? '100%'}
          height={props.height ?? '100%'}
        >
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              visibility: `${props.placeholderImg !== undefined || isSuccess || props.value ? 'visible':'hidden'}`
            }}
            alt=""
            ref={previewElem}
            src={(props.placeholderImg) ?? ''}
          />
        </Box>
      </Box>
    </Flex>
  );
});