// Chakra imports
import { Flex, FormLabel, Input, Text, useColorModeValue, forwardRef } from '@chakra-ui/react';
// Custom components

export default forwardRef(function Default(props: {
	id: string;
	label: string;
	extra?: JSX.Element;
	defaultValue?: string;
	placeholder: string;
	type: string;
	mb?: any;
	error?: string;
}, ref) {
	const { id, label, extra, placeholder, type, mb, defaultValue, error, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorDanger = useColorModeValue('red.500', 'red.300');

	return (
		<Flex direction='column' mb={mb ? mb : '30px'}>
			<FormLabel
				display='flex'
				ms='10px'
				htmlFor={id}
				fontSize='sm'
				color={textColorPrimary}
				fontWeight='bold'
				_hover={{ cursor: 'pointer' }}>
				{label}
				<Text fontSize='sm' fontWeight='400' ms='2px'>
					{extra}
				</Text>
			</FormLabel>
			<Input
				{...rest}
				defaultValue={defaultValue}
				type={type}
				ref={ref}
				id={id}
				fontWeight='500'
				variant='main'
				placeholder={placeholder}
				_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
				h='44px'
				maxH='44px'
			/>
			{error ? <Text fontSize={13} color={textColorDanger}>{error}</Text> : null}
		</Flex>
	);
})
