import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import CommentsTable from './CommentsTable'

type Props = {
  id: string;
  schemaName: string
}

const Comments = (props: Props) => {
  const { id, schemaName } = props
  return (
    <Box w={'100%'}>
      <CommentsTable id={id} schemaName={schemaName} />
    </Box>
  )
}

export default Comments