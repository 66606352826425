// Chakra imports
import { Avatar, Box, Button, Flex, Icon, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { globalStyles } from 'theme/styles'
import { approve, reject } from 'apis/articles.api';
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { ReviewStatus } from 'types/shared/approvable.types';
import dayjs from 'dayjs'
import { ArticleApproveInput } from 'types/article.types';

export default function ArticleCard(props: {
	id: string;
	slug: string;
	image: string;
	name: string;
	subtext: string;
	author: string;
	showApprovableActions?: boolean;
	status?: ReviewStatus;
	createdAt: Date;
	onReject?: (id:string) => unknown;
	onClick?: () => unknown;
}) {
	const { id, slug, image, name, subtext, author, showApprovableActions, status, onReject, onClick, createdAt } = props;
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorSub = useColorModeValue('gray.500', '');
	const textColorBid = useColorModeValue('brand.500', 'white');
	const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

	const queryClient = useQueryClient()
  const mutationApprove = useMutation((data: ArticleApproveInput) => {
    return approve(data)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['articles']})
    }
  })

	const handleApprove = () => {
		// mutationApprove.mutate(id)
	}

	const handleApproveClick = () => {

	}

	const handleReject = () => {
		onReject && onReject(id)
	}

	return (
		<Card p='20px' boxShadow={cardShadow}>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
					<Image
						onClick={onClick} cursor={onClick ? 'pointer': 'default'}
						src={image}
						minHeight={300}
						objectFit={"cover"}
						w={{ base: '100%', '3xl': '100%' }}
						h={{ base: '400px', '3xl': '400px' }}
						borderRadius='20px'
					/>
				</Box>
				<Flex flexDirection='column'justify='space-between' h='100%'>
					<Flex
						flex={'0 0 160px'} 
						overflow={'auto'}
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb='auto'>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{name}
							</Text>
							<Text
								color={textColorSub}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{subtext}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'sm'
								}}
								fontWeight='400'
								me='14px'>
								{author}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'sm'
								}}
								fontWeight='400'
								me='14px'>
								{dayjs(createdAt).format('LL')}
							</Text>
						</Flex>
					</Flex>
					{showApprovableActions ? <Flex
						align={{
							base: 'center',
							md: 'start',
							lg: 'center',
							xl: 'start',
							'2xl': 'center'
						}}
						marginTop={'auto'}
						justifySelf={'flex-end'}
						justify='space-between'
						direction={{
							base: 'row',
							md: 'row',
							lg: 'row',
							xl: 'row',
							'2xl': 'row'
						}}
						mt='25px'>
						<Button
							onClick={() => {
								window.open(`${process.env.REACT_APP_PUBLIC_URL}/articles/${slug}`)
							}}
							variant='outline'
							fontSize='sm'
							color='blue'
							mt="5"
							fontWeight='500'
							borderRadius='70px'
							px='24px'
							py='5px'>
							View Solo Artwork
						</Button>
						{false && status === ReviewStatus.Pending || status === ReviewStatus.Approved ? <Button
							onClick={handleReject}
							variant='outline'
							color={globalStyles.colors.red[500]}
							fontSize='sm'
							fontWeight='500'
							borderRadius='70px'
							px='24px'
							py='5px'>
							Reject
						</Button> : null }
					</Flex> : null}
				</Flex>
			</Flex>
		</Card>
	);
}
