import React, { createContext } from 'react'

type AuthContextProp = {
  state: AuthState | null,
  dispatch: React.Dispatch<AuthAction>
}

export const defaultValue: AuthContextProp = {
  state: null,
  dispatch: () => {}
}

export type AuthProviderProps = {
  children?: React.ReactNode
}

export enum AuthActionType {
  login,
  logout
}

type AuthActionPayload = {
  token: string
}

type AuthAction = {
  type: AuthActionType
  payload?: AuthActionPayload
}

export type AuthState = {
  loggedIn: boolean;
  token: string | null;
}

export const getAuthToken = (): string => {
  const token = localStorage.getItem('token')
  return token
}

export const setAuthToken = (token: string) => {
  localStorage.setItem('token', token)
}

export const clearAuthToken = () => {
  console.log('clear session')
  localStorage.clear();
}

export const AuthContext = createContext(defaultValue)

export const setAuth = (
  state: AuthState, 
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionType.login:
      setAuthToken(action.payload.token)
      return {
        ...state,
        loggedIn: true,
        token: action.payload.token
      }
    case AuthActionType.logout:
      clearAuthToken()
      return {
        ...state,
        loggedIn: true,
        token: null
      }
    default: {
      throw new Error(`Unhandled action type ${action.type}`)
    }
  }
}
