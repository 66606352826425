import React from 'react'
import { getActivityHistoryDistinctUsers, deleteActivityUsers } from 'apis/activity.api'
import DataTable from 'components/dataTable/dataTable2'
import { ActivityHistoryListItem } from 'types/activity.types'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { useRouteMatch, useHistory, useParams, Link } from 'react-router-dom'
import usePagingationQuery from 'hooks/use-pagination-query.hook'
import countries from 'i18n-iso-countries'
import dayjs from 'dayjs'
import { 
  FaEye
} from 'react-icons/fa';
import {
  Button,
  Checkbox
} from '@chakra-ui/react'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const ActivityUniqueUsers = () => {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const { page, size } = usePagingationQuery()
  const { id, ghost, actions } = useParams<{
    id: string,
    ghost: string,
    actions: string
  }>()
  const queryClient = useQueryClient()

  const mutateDeleteUserActivities = useMutation((ids: Array<string>) => {
    return deleteActivityUsers({
      ids,
      schemaName: 'artworks',
      action: actions.split('+').join(','),
      relatedId: id
    })
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['artwork-activity-users', {
        id,
        ghost,
        actions: actions.split('+').join(','),
        page,
        size
      }]})
    }
  })

  const columnHelper = createColumnHelper<ActivityHistoryListItem>()
  const columns: Array<ColumnDef<ActivityHistoryListItem, any>> = [
    columnHelper.accessor('name', {
      header: 'By',
      cell: props => props.getValue(),
      size: 60
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created',
      cell: props => <>{dayjs(props.getValue()).format('YYYY-MMM-D LTS')}</>,
      size: 30
    }),
    columnHelper.accessor('id', {
      header: 'View User',
      cell: props => <Link to={`/admin/users/sign-ups/${props.getValue()}`}><FaEye /></Link>,
      size: 10
    })
  ]

  const tableActions = <>
    <Button onClick={() => {
      // mutateDeleteActivities()
    }}>Delete</Button>
  </>
  
  // console.log(actions.split('+').join(','))

  const props: DataTableProps<ActivityHistoryListItem, DataTableQuery> = {
    title: 'Activity Logs',
    columnsMeta: columns,
    cacheKey: 'activity-history-users',
    getRowId: (row, index, parent) => {
      return row.id
    },
    queryFn: ({ page, size }) => getActivityHistoryDistinctUsers({
      page,
      size,
      schema: 'artworks',
      actions: actions.split('+'),
      relatedId: id,
      ghost: ghost === 'int'
    }),
    onPageChange: (page => {
      push(`${url}?page=${page}&`)
    }),
    enableRowSelection: true,
    actions: tableActions
  }
  return (
    <DataTable {...props} size={50} page={page} />
  )
}

export default ActivityUniqueUsers;