import axiosInstance from './api';
import { 
  InviteListRow,
  Invitation,
  SendInvitesInput
} from 'types/invite.types'
import { 
  PaginatedQuery,
  PaginatedResult
} from './api.types'
import { DataTableQuery } from 'components/dataTable/dataTable.types';

type GetAllProps = DataTableQuery & PaginatedQuery

export const getAll = async (props: GetAllProps): Promise<PaginatedResult<InviteListRow>> => {
  let query = `?${props.page ? 'page=' + props.page : ''}`
  if (props.size)
    query = query + '&size=' + props.size
  if (props.query) {
    if (props.query['searchKeyword'])
      query = query + '&searchKeyword=' + props.query['searchKeyword']
  }
  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<Invitation>>(`/invites${query}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map(o => ({
            id: o._id,
            status: o.status,
            name: o.name,
            code: o.code,
            invitedAt: o.invitedAt,
            email: o.email,
            updatedAt: o.updatedAt
          }))
        })
      })
      .catch(err => reject(err))
  })
}

type GetAllUnsubscribedProps = DataTableQuery & PaginatedQuery

export const getAllUnsubscribed = async (props: GetAllUnsubscribedProps): Promise<PaginatedResult<InviteListRow>> => {
  let query = `?${props.page ? 'page=' + props.page : ''}`
  if (props.size)
    query = query + '&size=' + props.size
  if (props.query) {
    if (props.query['searchKeyword'])
      query = query + '&searchKeyword=' + props.query['searchKeyword']
  }
  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<Invitation>>(`/invites/unsubscribes${query}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map(o => ({
            id: o._id,
            status: o.status,
            name: o.name,
            code: o.code,
            invitedAt: o.invitedAt,
            email: o.email,
            updatedAt: o.updatedAt
          }))
        })
      })
      .catch(err => reject(err))
  })
}

export const deleteOne = async (id:string): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.delete<Invitation>(`/invites/${id}`)
      .then(response => {
        resolve()    
      })
      .catch(err => reject(err))
  })
}

export const sendInvites = async (
  invites: SendInvitesInput
): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.post(`/invites`, {
      invites: invites.invites,
      uploadedFileName: invites.uploadedFile?.filename
    })
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}

export const uploadInvites = (
  files: FileList
) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++ ){
    const file = files.item(i)
    formData.append("files", file);
  }

  return axiosInstance.post('/invites/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const checkEmailSignedUp = async (email: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axiosInstance.post(`/invites/is-signedup`, {
      email
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  })
}

