import React from 'react'
import { 
  Box, SimpleGrid, Flex,
  useColorModeValue
} from "@chakra-ui/react";

import ActivityLogTable from './ActivityLogTable';

import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import SubNavLink from '../../../components/subNav/link';

const ActvityLog = () => {
  const { path } = useRouteMatch()
  const { push } = useHistory()
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  return (  
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        ms="14px"
        mb="20px"
        align="center"
      >
      </Flex>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "20px", xl: "20px" }}>
          <Switch>
            <Route exact path={`${path}`}>
              <ActivityLogTable />
            </Route>
          </Switch>
      </SimpleGrid>
    </Box>
  )
}

export default ActvityLog;