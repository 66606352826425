import React, { ChangeEvent, useEffect, useState } from 'react';
import { 
  Route, Switch, useRouteMatch, 
  useHistory, Redirect 
} from 'react-router-dom';
import Detail from './Detail';
import Activity from './activity'

const ArtworkDetail = () => {
  const { path } = useRouteMatch()
  
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Detail/>
      </Route>
      <Route path={`${path}/activity`}>
        <Activity/>
      </Route>
    </Switch>
  )
}

export default ArtworkDetail