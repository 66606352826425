import axiosInstance from './api';
import { 
  User, GetUserDetail, UserListRow, AccountType, 
  CompanyType, GetAllUserItem, GetOneUser, FollowingUserListRow,
  hydrateDerivedUser, 
  UserCompact,
  UserMethods,
} from 'types/user.types'
import { 
  PaginatedQuery,
  PaginatedResult
} from './api.types'
import { Artwork } from 'types/artwork.types'
import { Article } from 'types/article.types';
import { DataTableQuery } from 'components/dataTable/dataTable.types';
import { reject } from './artworks.api';

type GetAllProps = DataTableQuery & PaginatedQuery

export const getAll = async (props: GetAllProps): Promise<PaginatedResult<UserListRow>> => {
  let query = `?${props.page ? 'page=' + props.page : ''}`
  if (props.size)
    query = query + '&size=' + props.size
  if (props.query) {
    query = query + '&searchKeyword=' + props.query['name']
  }
  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<GetAllUserItem>>(`/users${query}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map(hydrateDerivedUser).map(o => ({
            id: o.id,
            accountType: o.accountType,
            accountTypeName: o.accountTypeName,
            categories: UserMethods.getCategoriesNames(o),
            /*(o.accountType === AccountType.FashionCompany ? 
              [UserMethods.getCompanyTypeName(o.companyType)] 
              : [o.primaryProfession ? (o.primaryProfession.charAt(0).toUpperCase() + o.primaryProfession.slice(1)) : null, ...(o.secondaryProfessions ?? []).map(c => c.charAt(0).toUpperCase() + c.slice(1))]),
            */
            name: o.name,
            countryCode: o.countryCode,
            verifiedOn: o.verifiedOn,
            email: o.email,
            isGhostUser: o.isGhostUser
          }))
        })
      })
      .catch(err => reject(err))
  })
}

export const getOne = async (id:string): Promise<GetUserDetail> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get<GetOneUser>(`/users/${id}`)
      .then(response => {
        const { _id, ...rest } = response.data
        resolve(hydrateDerivedUser({
          id: _id,
          ...rest
        }))    
      })
      .catch(err => reject(err))
  })
}

export const getUserArtworks = async (
  id: string
): Promise<PaginatedResult<Artwork>> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<Artwork>>(`/users/${id}/artworks?page=0&size=100`)
      .then(response => {
        resolve({
          data: response.data.data.map(artwork => ({
            ...artwork,
            user: hydrateDerivedUser({
              ...artwork.user,
              id: artwork.user.id
            })
          })),
          page: response.data.page,
          size: response.data.size
        })
      })
      .catch(err => reject(err))
  })
}

export const getUserArticles = async (
  id: string
): Promise<PaginatedResult<Article>> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<Article>>(`/users/${id}/articles?page=0&size=100`)
      .then(response => {
        resolve({
          data: response.data.data.map(article => ({
            ...article,
            user: hydrateDerivedUser({
              ...article.user,
              id: article.user._id
            })
          })),
          page: response.data.page,
          size: response.data.size
        })
      })
      .catch(err => reject(err))
  })
}

export const findUsersByNameStartWith = async (
  { name, page = 0, size = 100 }: PaginatedQuery & {
    name: string
  }
): Promise<PaginatedResult<UserCompact>> => {
  return new Promise((resolve, reject) => {
    if (name == null) resolve({
      data: [],
      page,
      size
    })

    axiosInstance.get<PaginatedResult<UserCompact>>(`/users/search-name/${name}?page=${page}&size=${size}`)
      .then(response => {
        resolve({
          data: response.data.data,
          page: response.data.page,
          size: response.data.size
        })
      })
      .catch(err => reject(err))
  })
}

export const deleteUsers = async (
  ids: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const idList = ids.split('\n')
    axiosInstance.post('/users-delete', {
      ids: idList
    }).then(() => {
      resolve()
    }).catch(() => {
      reject()
    })
  })
}

export const setUserGhost = async (
  id: string,
  ghost: boolean
): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.put(`/users/${id}/is-ghost/${ghost}`)
      .then(() => {
        resolve()
      })
      .catch(() => {
        reject()
      })
  })
}

export const updateUserProfile = async (
  id: string,
  data: Partial<User>
): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.put(`/users/${id}`, data)
      .then(() => {
        resolve()
      })
      .catch(() => {
        reject()
      })
  })
}

export const getFollowers = async ({
  id,
  page = 0,
  size = 20
}: PaginatedQuery & {
  id: string
}):Promise<PaginatedResult<FollowingUserListRow>> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get(`/users/${id}/followers?page=${page}&size=${size}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(() => {
        reject()
      })
  })
}

export const getFollowing = async ({
  id,
  page = 0,
  size = 20
}: PaginatedQuery & {
  id: string
}):Promise<PaginatedResult<FollowingUserListRow>> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get(`/users/${id}/following?page=${page}&size=${size}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch(() => {
        reject()
      })
    })
}
