import React from 'react'
import { 
  Box, Text, useColorModeValue, Grid
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';

import { getList } from 'apis/artworks.api'
import DataTable from 'components/dataTable/dataTable2'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'

import { ArtworkListRow, MediaType } from 'types/artwork.types'
import { PaginatedQuery } from 'apis/api.types'
import { useRouteMatch, useHistory } from 'react-router-dom'
import countries from 'i18n-iso-countries'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { ReviewStatus } from 'types/shared/approvable.types';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
dayjs.extend(localizedFormat)

type ArtworksTableProps = {
  size?: number,
  reviewStatus: ReviewStatus
}

const ArtworksTable = ({
  size = 30,
  reviewStatus
}: ArtworksTableProps) => {
  const { push } = useHistory()
  const { url } = useRouteMatch()

  const searchParams = new URLSearchParams(window.location.search);

  const columnHelper = createColumnHelper<ArtworkListRow>()

  const columns: Array<ColumnDef<ArtworkListRow, any>> = [
    columnHelper.accessor('imageUrl', {
      header: 'Artwork',
      cell: props => <img style={{display:'block', width:'400px'}} src={props.getValue()} alt=""/>,
      size: 30
    }),
    columnHelper.accessor('title', {
      header: 'Title',
      cell: props => props.getValue(),
      size: 20
    }),
    columnHelper.accessor('mediaType', {
      header: 'Type',
      cell: props => props.getValue() === MediaType.Image ? 'IMAGE': 'VIDEOLINK',
      size: 10
    }),
    columnHelper.accessor('userName', {
      header: 'Author',
      cell: props => props.getValue(),
      size: 20
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      cell: props => dayjs(props.getValue()).format('LLLL'),
      size: 20
    })
  ]

  const props: DataTableProps<ArtworkListRow, DataTableQuery> = {
    title: 'Artworks',
    columnsMeta: columns,
    query: {
      reviewStatus
    },
    page: Number(searchParams.get('page') ?? 0),
    size: Number(searchParams.get('size') ?? 10),
    cacheKey: `artworks-${reviewStatus}`,
    queryFn: getList,
    onRowClick: (data: ArtworkListRow) => {
      push(`${url}/` + data.id)
    }
  } 

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      {false && <InputField id="searchKeyword" label="Search" type="text" placeholder="Enter a search keyword"/>}
      <DataTable {...props} />
    </Box>
  )
}

export default ArtworksTable;