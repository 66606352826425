import axiosInstance from './api';
import { Artwork, ArtworkListRow, MediaType } from 'types/artwork.types'
import { ReviewStatus } from 'types/shared/approvable.types';
import { 
  PaginatedQuery,
  PaginatedResult
} from './api.types'
import { toQueryParams } from 'utils'
import { ArticleApproveInput } from 'types/article.types';
import Config from 'config'
import { UserMethods } from 'types/user.types';

type GetAllQueryInput = PaginatedQuery & {
  query: Record<string, string>
}

export const getList = async ({page, size, query}: GetAllQueryInput): Promise<PaginatedResult<ArtworkListRow>> => {
  const queryString = '?' + toQueryParams({
    page,
    size,
    ...query
  })

  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<Artwork>>(`/artworks${queryString}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map(o => ({
            id: o.id,
            title: o.title,
            mediaType: o.mediaType,
            imageUrl: o.mediaType === MediaType.Image ? `${Config.uploadsUrl}/${o.imageVariants?.small?.filename?? o.image.filename}` : o.video.posterUrl,
            userName: o.user ? UserMethods.getName(o.user) : '',
            createdAt: o.createdAt
          }))
        })
      })
      .catch(err => reject(err))
  })
}

export const getAll = async (
  { page = 0, size = 10, query }: GetAllQueryInput
): Promise<PaginatedResult<Artwork>> => {
  
  const queryString = '?' + toQueryParams({
    page,
    size,
    query
  })
  
  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<Artwork>>(`/artworks${queryString}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  })
}

export const getOne = async (
  id: string
): Promise<Artwork> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get<Artwork>(`/artworks/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  })
}

export const approve = async (id:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.put<void>(`/artworks/${id}/approve`)
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}

export const reject = async (
  id: string, reason: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.put<void>(`/artworks/${id}/reject`, {
      reason
    })
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}

export const remove = async (
  id: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.delete<void>(`/artworks/${id}`)
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}