import React from 'react'
import { 
  Box, Text, useColorModeValue, Grid
} from '@chakra-ui/react';

import { getActivityActionsByUser } from 'apis/analytics.api';
import { GetUsersByActionItem } from 'types/analytic.types';
import DataTable from 'components/dataTable/dataTable2'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { PaginatedQuery } from 'apis/api.types'
import { useRouteMatch, useHistory, Link, useParams } from 'react-router-dom'
import countries from 'i18n-iso-countries'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { formatAccountTypeName } from 'utils'
import { MediaType } from 'types/artwork.types';
import Config from 'config'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
dayjs.extend(localizedFormat)

type ActionUsersTableProps = {
  size?: number
}

const titleMap: Record<string, string> = {
  'voted': 'Voters'
}

const actionColumnLabelsMap: Record<string, string> = {
  'artworks': 'artwork',
  'articles': 'article'
}

const ActionUsersTable = ({
  size = 30
}: ActionUsersTableProps) => {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const { action, schemaName } = useParams<{
    action: string,
    schemaName: string
  }>()

  let title = titleMap[action] ?? action
  
  const searchParams = new URLSearchParams(window.location.search);

  const columnHelper = createColumnHelper<GetUsersByActionItem>()

  const columns: Array<ColumnDef<GetUsersByActionItem, any>> = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: props => props.getValue(),
      enableSorting: false,
      size: 30
    }),
    columnHelper.accessor('accountType', {
      header: 'Account Type',
      cell: props => formatAccountTypeName(props.getValue()),
      enableSorting: false,
      size: 30
    }),
    columnHelper.accessor('actionCount', {
      header: 'count',
      cell: props => props.getValue(),
      enableSorting: false,
      size: 10
    }),
    columnHelper.accessor('id', {
      header: `View User`,
      cell: props => <Link to={`/admin/users/sign-ups/${props.getValue()}`}>View user</Link>,
      enableSorting: false,
      size: 10
    }),
    columnHelper.accessor('id', {
      header: `View ${schemaName}`,
      cell: props => <Link to={`/admin/analytics/users/${props.getValue()}/${schemaName}/${action}/items`}>View {schemaName}</Link>,
      enableSorting: false,
      size: 13
    })
  ]

  const props: DataTableProps<GetUsersByActionItem, DataTableQuery> = {
    title,
    enableSorting: true,
    columnsMeta: columns,
    page: Number(searchParams.get('page') ?? 0),
    size: Number(searchParams.get('size') ?? 10),
    sort: searchParams.get('sort'),
    desc: (searchParams.get('desc') ?? 'true') === 'true',
    cacheKey: `activity-action-users-${action}`,
    queryFn: ({ page, size }) => getActivityActionsByUser({
      action,
      schemaName,
      page,
      size
    }),
    /*
    onRowClick: (data: GetUsersByActionItem) => {
      push(`/admin/users/sign-ups/` + data.id)
    },
    */
    /*
    onSort: (sort: string, desc: boolean) => {
      if (sort) {
        searchParams.set('sort', sort)
        searchParams.set('desc', desc.toString())
      } else {
        searchParams.delete('sort')
        searchParams.delete('desc')
      }

      push(`${url}?${searchParams.toString()}`)
    }
    */
  }

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      <DataTable {...props} />
    </Box>
  )
}

export default ActionUsersTable;