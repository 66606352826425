import React from 'react'
import { 
  Box, SimpleGrid, Flex,
  useColorModeValue, Link
} from "@chakra-ui/react";

import { 
  Route, Switch, useRouteMatch, 
  useHistory, Redirect 
} from 'react-router-dom';
import SubNavLink from 'components/subNav/link';

import Artworks from './artworks'
import Articles from './articles'

export default function Submissions() {
  const { path } = useRouteMatch()

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      
      <Flex
        ms="14px"
        mb="20px"
        align="center"
      >
        <SubNavLink 
          to={`${path}/artworks`}
          label="All Artworks"/>
        <SubNavLink 
          to={`${path}/articles`}
          label="All Articles"/>
      </Flex>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "20px", xl: "20px" }}>
          <Switch>
            <Route exact path={`${path}`}>
              <Redirect to={`${path}/artworks`} />
            </Route>
            <Route path={`${path}/artworks`}>
              <Artworks />
            </Route>
            <Route path={`${path}/articles`}>
              <Articles />
            </Route>
          </Switch>
      </SimpleGrid>
    </Box>
  )
}
