import React, { ChangeEvent, useState } from 'react';
import { 
  VStack, Select, StackDivider,
  Box, HStack, Button
} from '@chakra-ui/react';

import { 
  useHistory, useRouteMatch,
  Route, Switch 
} from 'react-router-dom'

import ChaptersGallery from './ChaptersGallery';
import ChaptersTable from './ChaptersTable';

const Chapters = () => {
  const { push } = useHistory()
  const { path } = useRouteMatch()
  const [ page, setPage ] = useState<number>(0)
  
  return (
    <>
    <HStack spacing='24px' justifyContent={"flex-end"}>
      <Box h='40px'>
        <Button
          onClick={() => {
            push(`${path}/new`)
          }}
          variant='brand'
          color='white'
          fontSize='sm'
          fontWeight='500'
          borderRadius='70px'
          px='24px'
          py='5px'>
          Add Chapter
        </Button>
      </Box>
    </HStack>
    <ChaptersTable size={10} />
    </>
  )
}

export default Chapters