import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ChangePassword from '../change-password';
import { Box } from '@chakra-ui/react';

const Settings = () => {
  const { path } = useRouteMatch()

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Switch>
        <Route path={`${path}/change-password`}>
          <ChangePassword/>
        </Route>
      </Switch>    
    </Box>
  )
}

export default Settings