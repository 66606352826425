import React, { useState} from 'react'
import { 
  Box, Text, useColorModeValue, Grid, Switch, Button
} from '@chakra-ui/react';
import { 
  FaEye
} from 'react-icons/fa';
import InputField from 'components/fields/InputField';

import { getAll, setUserGhost } from 'apis/users.api'
import DataTable from 'components/dataTable/dataTable2'
import { UserListRow } from 'types/user.types'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { PaginatedQuery } from 'apis/api.types'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { formatAccountTypeName } from 'utils'
import countries from 'i18n-iso-countries'
import SearchBox from 'components/searchBox/SearchBox'
import {
  useQueryClient, useMutation
} from '@tanstack/react-query'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const UsersTable = () => {
  const { search } = useLocation()
  const { push, replace } = useHistory()
  const { url } = useRouteMatch()
  const queryClient = useQueryClient()

  const toggleUserGhost = useMutation(({
    id, 
    ghost
  }: {
    id: string, 
    ghost: boolean
  }) => {
    return setUserGhost(id, ghost)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users']})
    }
  })
  
  const searchParams = new URLSearchParams(window.location.search);

  const columnHelper = createColumnHelper<UserListRow>()

  const columns: Array<ColumnDef<UserListRow, any>> = [
    columnHelper.accessor('accountType', {
      header: 'Account Type',
      cell: props => <div>{formatAccountTypeName(props.getValue())}</div>,
      size: 20
    }),
    columnHelper.accessor('categories', {
      header: 'Categories',
      cell: props => <div>{(props.getValue() ?? []).join(', ')}</div>,
      size: 20
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      cell: props => props.getValue(),
      size: 20
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: props => props.getValue(),
      size: 20
    }),
    columnHelper.accessor('countryCode', {
      header: 'Country',
      cell: props => <div>{countries.getName(props.getValue(), "en")}</div>,
      size: 10
    }),
    columnHelper.accessor('isGhostUser', {
      header: 'Ghost',
      cell: props => <Switch isChecked={props.getValue()} onChange={() => {
        toggleUserGhost.mutate({
          id: props.row.original.id,
          ghost: !props.getValue()
        })
      }}/>,
      size: 5
    }),
    columnHelper.accessor('id', {
      header: '',
      cell: props => <FaEye onClick={() => {
        push(`${url}/` + props.getValue())
      }} />,
      size: 5
    })
  ]

  const props: DataTableProps<UserListRow, DataTableQuery> = {
    title: 'All Signups',
    enableSorting: false,
    columnsMeta: columns,
    cacheKey: 'users',
    queryFn: getAll,
    onRowClick: (data: UserListRow) => {
      
    },
    onPageChange: (page => {
      searchParams.set('page', page.toString())
      push(`${url}?${searchParams.toString()}`)
    })
  }

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      <SearchBox 
        defaultValue={new URLSearchParams(search).get('name')??''}
        onSearch={(value) => {
          window.location.href = `${url}?name=${value}`
        }}/>
      <DataTable {...props} 
        query={{
          name: new URLSearchParams(search).get('name') ?? ''
        }} 
        page={Number(searchParams.get('page') ?? 0)} />
    </Box>
  )
}

export default UsersTable;