import React, { ChangeEvent, useState, useEffect } from 'react';
import { 
  VStack, Select, StackDivider,
  Box, HStack
} from '@chakra-ui/react';
import ArticlesTable from './ArticlesTable';

import { ReviewStatus } from 'types/shared/approvable.types';

import { useHistory, useRouteMatch } from 'react-router-dom'

import useUrlQuery from '../useUrlQuery';

const ArticlesListing = () => {
  const { push } = useHistory()
  const { path } = useRouteMatch()
  const { reviewStatus, page } = useUrlQuery()
  
  const handleStatusChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    push(path + '?reviewStatus=' + evt.target.value)
  }

  useEffect(() => {
    if (!reviewStatus) {
      push(path + '?reviewStatus=pending')
    }
  }, [])

  return (
    <VStack
      divider={<StackDivider borderColor='gray.200' />}
      spacing={4}
      align='stretch'
    >
      <HStack spacing='24px' justifyContent={"flex-end"}>
        <Box as="label" h='40px'>
          <Select placeholder='Select Status' onChange={handleStatusChange} value={reviewStatus}>
            <option value={ReviewStatus.Pending}>Pending</option>
            <option value={ReviewStatus.Approved}>Approved</option>
            <option value={ReviewStatus.Rejected}>Rejected</option>
          </Select>
        </Box>
      </HStack>
      <ArticlesTable reviewStatus={reviewStatus} size={30} />
    </VStack>
  )
}

export default ArticlesListing