export const usePagingationQuery = () => {
  const searchParams = new URLSearchParams(window.location.search.replace('?',''));

  const queryParams: {
    page: number,
    size: number
  } = {
    page: 0,
    size: 20
  }
  for (const p of searchParams) {
    if (p[0] === 'page')
      queryParams.page = parseInt(p[1])
    else
    if (p[0] === 'size')
      queryParams.size = parseInt(p[1])
  }

  return queryParams
}

export default usePagingationQuery