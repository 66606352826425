import React from 'react'
import {
  Redirect,
  Route,
  RouteProps
} from 'react-router-dom';
import useAuth from './hooks/use-auth.hook';

type PrivateRouteProps = {
  children: React.ReactNode
} & RouteProps

const PrivateRoute = ({
  children,
  ...rest
}: PrivateRouteProps) => {
  const auth = useAuth();
  return (
    <Route {...rest} 
      render={({ location }) => auth.state.loggedIn ? children : (
      <Redirect 
        to={{
          pathname: '/auth/login',
          state: { from: location }
        }}
      />
    )}>
    </Route>
  );
}

export default PrivateRoute;