import React, { useState } from 'react';
import { 
  VStack, StackDivider,
  Box, HStack, Button
} from '@chakra-ui/react';
import { 
  useHistory, useRouteMatch,
  Route, Switch 
} from 'react-router-dom'

import Articles from './Articles'
import ArticleForm from './ArticleForm';

const ItchArticles = () => {
  const { path } = useRouteMatch()
  
  return (
    <VStack
      divider={<StackDivider borderColor='gray.200' />}
      spacing={4}
      align='stretch'
    >
      <Switch>
        <Route exact path={path}>
          <Articles />
        </Route>
        <Route path={`${path}/add-article`}>
          <ArticleForm/>
        </Route>
        <Route path={`${path}/:id`}>
          <ArticleForm/>
        </Route>
      </Switch>
    </VStack>
  )
}

export default ItchArticles