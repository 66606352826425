import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import {
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand
} from "lexical";
import { useEffect } from "react";

import { $createVideoNode, VideoNode } from "../nodes/VideoNode";

export const INSERT_VIDEO_COMMAND: LexicalCommand<{videoType: string, videoId: string}> = createCommand(
  "INSERT_VIDEO_COMMAND"
);

export default function EmbedVideoPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([VideoNode])) {
      throw new Error("EmbedVideoPlugin: VideoNode not registered on editor");
    }

    return editor.registerCommand<{videoType: string, videoId: string}>(
      INSERT_VIDEO_COMMAND,
      ({ videoType, videoId }) => {
        const videoNode = $createVideoNode(videoId, videoType);
        $insertNodeToNearestRoot(videoNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
