import React, { useEffect } from 'react'
import {
  useForm,
  SubmitHandler,
  Controller
} from 'react-hook-form'
import {
  Checkbox,
  SimpleGrid,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Switch,
  useColorModeValue,
  Box,
  Flex,
  Button,
  Alert,
  AlertIcon,
  Image,
  Text,
  HStack,
} from '@chakra-ui/react'
import { useParams, useHistory } from 'react-router-dom'

import Card from 'components/card/Card';
import ImageUpload from 'components/upload-image/upload-image'

import {
  CreatePortraitInput,
  UpdatePortraitInput,
  GetOnePortraitOutput
} from 'types/portrait-types'

import { addPortrait, updatePortrait, deletePortrait, getOne } from 'apis/portraits'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';

import {
  imageUrl
} from 'shared/utils'

const PortraitForm = () => {
  const { id } = useParams<{ id: string }>()
  const { push } = useHistory()
  
  const dangerColor = 'red.500'
  const textColorSecondary = 'gray.900';
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  
  const queryClient = useQueryClient()
  const mutationAddPortrait = useMutation((data: CreatePortraitInput) => {
    return addPortrait(data)
  }, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['portraits'])
    }
  })
  const mutationUpdatePortrait = useMutation((data: UpdatePortraitInput) => {
    return updatePortrait(id, data)
  }, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['portraits', id])
      queryClient.invalidateQueries(['portraits'])
    }
  })
  const mutationDeletePortrait = useMutation((id: string) => {
    return deletePortrait(id)
  }, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['portraits', id])
      queryClient.invalidateQueries(['portraits'])
    }
  })
  const { isSuccess, data, refetch } = useQuery({
    queryKey: ['portraits', id],
    queryFn: async () => {
      return getOne(id)
    },
    enabled: false
  })
  useEffect(() => {
    if (id !== 'new') {
      refetch()
    }
  }, [id])

  const { 
    control,
    register, 
    handleSubmit, 
    watch, 
    reset,
    setValue,
    formState: { errors } 
  } = useForm<CreatePortraitInput>();

  const onSubmit: SubmitHandler<CreatePortraitInput> = (data) => {
    if (id === 'new') {
      mutationAddPortrait.mutate({
        image: data.image,
        userId: data.userId
      })
    } else {
      mutationUpdatePortrait.mutate({
        _id: id,
        userId: data.userId,
        image: data.image
      })
    }
    push('/admin/itch-portraits')
  }

  const onClose = () => {
    push('/admin/itch-portraits')
  }

  const onDelete = () => {
    mutationDeletePortrait.mutate(id)
    push('/admin/itch-portraits')
  }

  const hasErrors = Object.keys(errors).length > 0

  useEffect(() => {
    if (isSuccess && data) {
      reset({
        userId: data.userId,
        image: data.image
      })
    }
  }, [isSuccess, data])

  const isNew = (id === 'new')

  return (
    <Card p='20px' boxShadow={cardShadow} width={880}>
      {(isSuccess && data) || isNew ? <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={1} spacing={5}>
          {hasErrors ? <Alert status='error'>
            <AlertIcon />
            There was an error processing your request
          </Alert> : null}
        </SimpleGrid>

        <FormControl>
          <FormLabel>User Id</FormLabel>
          <Input type='text'
            {...register('userId', {
              required: 'This is required'
            })} />
          <FormHelperText></FormHelperText>
          <FormErrorMessage>
            {errors.userId && errors.userId.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl w={400} >
          <FormLabel>Upload Image</FormLabel>
          <VStack align={'left'} >
            <Controller
              name="image"
              control={control}
              rules={{
                required: false
              }}
              render={({ field }) =>
                <ImageUpload 
                  width={400} 
                  height={400} 
                  disabled={false} 
                  noUpload={false}
                  placeholderImg={''}
                  {...field}
                />
              }
            />
          </VStack>
          
          <FormHelperText></FormHelperText>
          <FormErrorMessage>
            {errors.image && errors.image.message}
          </FormErrorMessage>
        </FormControl>

        <Flex justifyContent={'flex-end'} mt={20}>
          <Button variant='outline' 
            color={dangerColor}
            borderColor={dangerColor} 
            mr={'auto'} onClick={onDelete}>
            Delete
          </Button>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant='darkBrand'
            color='white'
            fontSize='sm'
            fontWeight='500'
            borderRadius='70px'
            px='24px'
            py='5px'>
            Save
          </Button>
        </Flex>

      </form> : null}
    </Card>
  )
}

export default PortraitForm