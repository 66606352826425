import React, { useEffect} from 'react'

import { 
  Box,
  Text, 
  useColorModeValue, 
  Flex, 
  Divider, 
  Button,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Textarea,
  Image,
  Select
} from '@chakra-ui/react'

import { Artwork, MediaType } from 'types/artwork.types';
import { Article } from 'types/article.types';
import { reject } from 'apis/artworks.api'
import { useForm } from "react-hook-form";
import { RejectInput, RejectReason } from 'types/shared/approvable.types'
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query'
import Card from 'components/card/Card';
import config from 'config'
import { imageUrl } from 'shared/utils'

type Props = {
  id?: string | null;
  artwork?: Artwork;
  article?: Article;
  type: 'artworks' | 'articles';
  isOpen: boolean;
  onClose: () => unknown;
  btnRef: React.MutableRefObject<undefined>
}

export default function RejectDrawer({
  id,
  type,
  artwork,
  article,
  isOpen,
  onClose,
  btnRef
}: Props) {
  const { push, goBack } = useHistory()
  const { url } = useRouteMatch()
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const bg = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  
  const queryClient = useQueryClient()
  const mutation = useMutation((data: RejectInput) => {
    return reject(id, data.reason)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [type]})
      queryClient.invalidateQueries({ queryKey: ['counters']})
    }
  })

  const { 
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { 
      errors
    }
  } = useForm<RejectInput>()

  const onSubmit = async (data: RejectInput) => {
    mutation.mutate(data)
    onClose()
    goBack()
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      finalFocusRef={btnRef}
      size={"md"}
      isFullHeight={true}
    >
      <DrawerOverlay />
      <DrawerContent overflowY={"scroll"}>
        <DrawerCloseButton />
        <DrawerHeader>Reject</DrawerHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerBody>

            {artwork ? <Card p='20px' boxShadow={cardShadow}>
              <Flex direction={{ base: 'column' }} justify='center'>
                <Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
                  <Image
                    src={`${artwork.mediaType === MediaType.VideoLink ? artwork.video?.posterUrl : config.uploadsUrl + '/' + artwork.imageVariants?.small?.filename}`}
                    minHeight={400}
                    objectFit={'cover'}
                    w={{ base: '100%', '3xl': '100%' }}
                    h={{ base: '100%', '3xl': '100%' }}
                    borderRadius='20px'
                  />
                </Box>
                <Text
                  color={textColor}
                  fontSize={{
                    base: 'xl',
                    md: 'lg',
                    lg: 'lg',
                    xl: 'lg',
                    '2xl': 'md',
                    '3xl': 'lg'
                  }}
                  mb='5px'
                  fontWeight='bold'
                  me='14px'>
                  {artwork.title}
                </Text>
              </Flex>
            </Card> : null}

            {article ? <Card p='20px' boxShadow={cardShadow}>
              <Flex direction={{ base: 'column' }} justify='center'>
              <Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
                  <Image
                    src={imageUrl(article.coverImage)}
                    minHeight={300}
                    w={{ base: '100%', '3xl': '100%' }}
                    h={{ base: '100%', '3xl': '100%' }}
                    borderRadius='20px'
                  />
                </Box>
                <Text
                  color={textColor}
                  fontSize={{
                    base: 'xl',
                    md: 'lg',
                    lg: 'lg',
                    xl: 'lg',
                    '2xl': 'md',
                    '3xl': 'lg'
                  }}
                  mb='5px'
                  fontWeight='bold'
                  me='14px'>
                  {article.title}
                </Text>
              </Flex>
            </Card> : null}

            <Divider mt={10} mb={10} />
            
            <FormControl>
              <FormLabel>Reason</FormLabel>
              <Select 
                {...register('reason', {
                  required: true
                })}>
                <option value={RejectReason.Explicit}>Explicit</option>
                <option value={RejectReason.Legal}>Legal</option>
                <option value={RejectReason.Irrelevant}>Irrelevant</option>
                <option value={RejectReason.Unprofessional}>Unprofessional</option>
              </Select>
              <FormHelperText></FormHelperText>
            </FormControl>

          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant='darkBrand'
              color='white'
              fontSize='sm'
              fontWeight='500'
              borderRadius='70px'
              px='24px'
              py='5px'>
              Send
            </Button>
          </DrawerFooter>
        </form>

      </DrawerContent>
    </Drawer>
  )
}