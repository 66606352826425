import axios from 'axios';
import { getAuthToken } from '../contexts/AuthContext'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API
});

const token = getAuthToken();

axiosInstance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null

export const updateAuthorizationHeader = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null
}

export default axiosInstance;