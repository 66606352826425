import React from 'react'

import {
  useForm,
  SubmitHandler,
  Controller
} from 'react-hook-form'

import {
  SimpleGrid ,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Switch,
  useColorModeValue,
  Box,
  Flex,
  Button,
  Alert,
  AlertIcon,
  Checkbox,
  Progress
} from '@chakra-ui/react'
import Card from 'components/card/Card';

import { useHistory } from 'react-router-dom'
import Editor from 'components/editor/editor'
import { broadcastMessage } from 'apis/messaging'
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { ChatInput } from 'types/chat.types';

const ChatForm = () => {
  const queryClient = useQueryClient()
  const mutationBroadcast = useMutation((data: string) => {
    return broadcastMessage(data)
  }, {
    onSuccess: (data, variables) => {
    }
  })

  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  const { 
    control,
    register, 
    handleSubmit, 
    watch, 
    reset,
    formState: { errors } 
  } = useForm<ChatInput>();

  const hasErrors = Object.keys(errors).length > 0

  const onSubmit: SubmitHandler<ChatInput> = (data) => {
    mutationBroadcast.mutate(data.message)
  }

  return (
    <Flex flexDir={'row'}>
      <Card p='20px' boxShadow={cardShadow} width={500}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {hasErrors ? <Alert status='success'>
            <AlertIcon />
            There was an error processing your request
          </Alert> : null}

          <FormControl>
            <FormLabel>Message Body</FormLabel>
            <Controller
              name="message"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => <Editor {...field} />}
            />
            <FormHelperText></FormHelperText>
            <FormErrorMessage>
              {errors.message && errors.message.message}
            </FormErrorMessage>
          </FormControl>

          <Flex justifyContent={'flex-end'} mt={10}>
            <Button
              type="submit"
              variant='darkBrand'
              color='white'
              fontSize='sm'
              fontWeight='500'
              borderRadius='70px'
              px='24px'
              py='5px'>
              Send
            </Button>
          </Flex>
        </form>
      </Card>
    </Flex>
  )
}

export default ChatForm