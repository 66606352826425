import React from 'react'
import { 
  Box, Text, useColorModeValue, Grid
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';

import { getAllArticle } from 'apis/zine.api'
import DataTable from 'components/dataTable/dataTable2'
import { MediaType } from 'types/artwork.types'
import { ChapterListRow } from 'types/zine.types'
import { Article } from 'types/article.types';
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { PaginatedQuery } from 'apis/api.types'
import { useRouteMatch, useHistory } from 'react-router-dom'
import countries from 'i18n-iso-countries'
import dayjs from 'dayjs'
import Config from 'config'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { UserMethods } from 'types/user.types';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
dayjs.extend(localizedFormat)

type ItchArticlesTableProps = {
  size?: number
}

const ItchArticlesTable = ({
  size = 30
}: ItchArticlesTableProps) => {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  
  const searchParams = new URLSearchParams(window.location.search);
  
  const columnHelper = createColumnHelper<Article>()

  const columns: Array<ColumnDef<Article, any>> = [
    columnHelper.accessor('title', {
      header: 'Title',
      cell: props => props.getValue(),
      size: 50
    }),
    columnHelper.accessor('coverImage', {
      header: 'Cover Image',
      cell: props => <img width={400} src={`${Config.uploadsUrl}/${props.getValue().filename}`} alt=""/>,
      size: 30
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      cell: props => <>{dayjs(props.getValue()).format('LLLL')}</>,
      size: 20
    })
  ]

  const props: DataTableProps<Article, DataTableQuery> = {
    title: 'Chapters',
    columnsMeta: columns,
    cacheKey: `itch-articles`,
    queryFn: ({ page, size }) => getAllArticle({
      page,
      size
    }),
    onRowClick: (data: Article) => {
      push(`${url}/` + data.id)
    },
    onPageChange: (page => {
      push(`${url}?page=${page}`)
    })
  }

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      {false && <InputField id="searchKeyword" label="Search" type="text" placeholder="Enter a search keyword"/>}
      <DataTable {...props} page={Number(searchParams.get('page') ?? 0)} />
    </Box>
  )
}

export default ItchArticlesTable;