import React from 'react'
import { 
  Box, SimpleGrid, Flex,
  useColorModeValue, Link
} from "@chakra-ui/react";
import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import FeaturedArtworks from './FeaturedArtworks';
import ItchArticles from './ItchArticles'
import SubNavLink from 'components/subNav/link';

export default function Zine() {
  const { path } = useRouteMatch()
  const { push } = useHistory()
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      
      <Flex
        ms="14px"
        mb="20px"
        align="center"
      >
        <SubNavLink
          to={`${path}/chapters`}
          label="Artwork Features" />
          
        <SubNavLink
          to={`${path}/articles`}
          label="Itch Articles" />
          
      </Flex>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "20px", xl: "20px" }}>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/chapters`} />
            </Route>
            <Route path={`${path}/chapters`}>
              <FeaturedArtworks />
            </Route>
            <Route path={`${path}/articles`}>
              <ItchArticles />
            </Route>
          </Switch>
      </SimpleGrid>
    </Box>
  )
}
