import React from 'react'
import { 
  Box, SimpleGrid, Flex,
  useColorModeValue
} from "@chakra-ui/react";
import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import SubNavLevel2Link from 'components/subNavLevel2/link';
import ArtworksAnalyticsTable from './artworks-table';
import { filters } from 'apis/analytics.api';

const AnalyticsView = () => {
  const { path } = useRouteMatch()
  
  const view = path.split('/').pop()

  return (
    <Box>
      <Flex
        ms="14px"
        mb="20px"
        align="center"
      >
        <SubNavLevel2Link 
          to={`${path}/all-artworks`}
          label="All Artworks"/>
        <SubNavLevel2Link 
          to={`${path}/featured-artworks`}
          label="Featured Artworks"/>
        <SubNavLevel2Link 
          to={`${path}/non-featured-artworks`}
          label="Non Featured Artworks"/>
      </Flex>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "20px", xl: "20px" }}>
          <Switch>
            <Route exact path={`${path}`}>
              <Redirect to={`${path}/all-artworks`} />
            </Route>
            <Route path={`${path}/all-artworks`}>
              <ArtworksAnalyticsTable filter={filters.All} view={view} />
            </Route>
            <Route path={`${path}/featured-artworks`}>
              <ArtworksAnalyticsTable filter={filters.Featured} view={view} />
            </Route>
            <Route path={`${path}/non-featured-artworks`}>
              <ArtworksAnalyticsTable filter={filters.NonFeatured} view={view} />
            </Route>
          </Switch>
      </SimpleGrid>
    </Box>
  )
}

export default AnalyticsView
