import React from 'react'
import { 
  Box, Text, useColorModeValue, Grid
} from '@chakra-ui/react';

import { getActivityItemsByUserActionSchema } from 'apis/analytics.api';
import { GetItemsByUserActionSchemaItem } from 'types/analytic.types';
import DataTable from 'components/dataTable/dataTable2'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { useRouteMatch, useHistory, Link, useParams } from 'react-router-dom'
import countries from 'i18n-iso-countries'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Config from 'config'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
dayjs.extend(localizedFormat)

type ActionUserItemsTableProps = {
  size?: number
}

const ActionUserItemsTable = ({
  size = 30
}: ActionUserItemsTableProps) => {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const { action, schemaName, userId } = useParams<{
    action: string,
    schemaName: string,
    userId: string
  }>()

  let title = schemaName
  
  const searchParams = new URLSearchParams(window.location.search);

  const columnHelper = createColumnHelper<GetItemsByUserActionSchemaItem>()

  const columns: Array<ColumnDef<GetItemsByUserActionSchemaItem, any>> = [
    columnHelper.accessor('imageUrl', {
      header: 'Image',
      cell: props => <img style={{width:'200px'}} src={`${Config.uploadsUrl}/${props.getValue()}`} alt=""/>,
      enableSorting: false,
      size: 50
    }),
    columnHelper.accessor('title', {
      header: 'Title',
      cell: props => props.getValue(),
      enableSorting: false,
      size: 50
    })
  ]

  const props: DataTableProps<GetItemsByUserActionSchemaItem, DataTableQuery> = {
    title,
    enableSorting: true,
    columnsMeta: columns,
    page: Number(searchParams.get('page') ?? 0),
    size: Number(searchParams.get('size') ?? size),
    sort: searchParams.get('sort'),
    desc: (searchParams.get('desc') ?? 'true') === 'true',
    cacheKey: `activity-action-user-${action}-items`,
    queryFn: ({ page, size }) => getActivityItemsByUserActionSchema({
      action,
      schemaName,
      userId,
      page,
      size
    }),
    onRowClick: (data: GetItemsByUserActionSchemaItem) => {
      push(`/admin/submissions/${schemaName}/` + data.id)
    },
    /*
    onSort: (sort: string, desc: boolean) => {
      if (sort) {
        searchParams.set('sort', sort)
        searchParams.set('desc', desc.toString())
      } else {
        searchParams.delete('sort')
        searchParams.delete('desc')
      }

      push(`${url}?${searchParams.toString()}`)
    }
    */
  }

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      <DataTable {...props} />
    </Box>
  )
}

export default ActionUserItemsTable;