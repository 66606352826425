import axiosInstance from './api';
import Config from 'config'

export type Counter = Record<string, number>
export type Counters = Array<Counter>

export const getAllPending = async (): Promise<Counters> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get<Counters>(`/counters/pending`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
}