import React, { useEffect} from 'react'

import { 
  Box,
  Text, 
  useColorModeValue, 
  Flex, 
  Divider, 
  Button,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from '@chakra-ui/react'

import {
  AddIcon,
  DeleteIcon
} from '@chakra-ui/icons'

import { sendTestEmail } from 'apis/email';
import { TestEmailInput, EmailInput } from 'types/email.types';
import TestRecipientField from './test-recipient'
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query'

import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray
} from 'react-hook-form'

type Props = {
  isOpen: boolean;
  onClose: () => unknown;
  data: EmailInput;
  btnRef: React.MutableRefObject<undefined>
}

export default function SendTestEmailRecipientsDrawer({
  isOpen,
  onClose,
  btnRef,
  data
}: Props) {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const bg = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  const queryClient = useQueryClient()
  const { mutate } = useMutation((data: TestEmailInput) => {
    return sendTestEmail(data)
  })

  const { 
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { 
      errors
    }
  } = useForm<TestEmailInput>()

  const { 
    fields, append, prepend, 
    remove, swap, move, insert 
  } = useFieldArray({
      control, 
      name: "recipients",
    });

  useEffect(() => {
    reset({
      ...data
    })
  }, [data, reset])

  const onSubmit = async (data: TestEmailInput) => {
    mutate(data)
    onClose()
  }

  useEffect(() => {
    reset({
      recipients: [
        {
          name: '',
          email: ''
        }
      ]
    })
  }, [])

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      finalFocusRef={btnRef}
      isFullHeight={true}
    >
      <DrawerOverlay />
      <DrawerContent overflowY={"scroll"}>
        <DrawerCloseButton />
        <DrawerHeader>Send Test Email</DrawerHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerBody>
            {fields.map((field, index) => (
              <Box mb="20px">
                <TestRecipientField
                  index={index} 
                  key={field.id} 
                  register={register}
                  control={control}
                  onRemoveField={() => {
                    remove(fields.length - 1)
                  }}
                />
                <Divider/>
              </Box>
            ))}
            <Flex>
              <IconButton aria-label='Add' icon={<AddIcon />} 
                onClick={() => {
                  append({
                    name: '',
                    email: ''
                  })
                }}
              />
            </Flex>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant='darkBrand'
              color='white'
              fontSize='sm'
              fontWeight='500'
              borderRadius='70px'
              px='24px'
              py='5px'>
              Send
            </Button>
          </DrawerFooter>
        </form>

      </DrawerContent>
    </Drawer>
  )
}