import { 
  Box, Text, useColorModeValue, Grid, Switch, Button, HStack
} from '@chakra-ui/react';
import { 
  FaEye,
  FaEdit
} from 'react-icons/fa';
import { getAll } from 'apis/portraits'
import DataTable from 'components/dataTable/dataTable2';

import { GetAllPortraitsRowItem } from 'types/portrait-types';
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import {
  useQueryClient, useMutation
} from '@tanstack/react-query'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import Config from 'config'

const PortraitsTable = () => {
  const { search } = useLocation()
  const { push, replace } = useHistory()
  const { path } = useRouteMatch()
  const { url } = useRouteMatch()

  const searchParams = new URLSearchParams(window.location.search);

  const columnHelper = createColumnHelper<GetAllPortraitsRowItem>()

  const columns: Array<ColumnDef<GetAllPortraitsRowItem, any>> = [
    columnHelper.accessor('image', {
      header: 'Image',
      cell: props => <img alt="" src={`${Config.uploadsUrl}/${props.getValue().filename}`}/>,
      size: 30
    }),
    columnHelper.accessor('name', {
      header: 'User',
      cell: props => props.getValue(),
      size: 30
    }),
    columnHelper.accessor('userId', {
      header: 'View User',
      cell: props => <FaEye onClick={() => {
        push(`/admin/users/sign-ups/` + props.getValue())
      }} />,
      size: 5
    }),
    columnHelper.accessor('id', {
      header: 'Edit Portrait',
      cell: props => <FaEdit onClick={() => {
        push(`${url}/` + props.getValue())
      }} />,
      size: 5
    })
  ]
  
  const props: DataTableProps<GetAllPortraitsRowItem, DataTableQuery> = {
    title: 'All Portraits',
    enableSorting: false,
    columnsMeta: columns,
    cacheKey: 'portraits',
    queryFn: getAll,
    onRowClick: (data: GetAllPortraitsRowItem) => {
      
    },
    onPageChange: (page => {
      searchParams.set('page', page.toString())
      push(`${url}?${searchParams.toString()}`)
    })
  }

  return (
    <>
      <HStack spacing='24px' justifyContent={"flex-end"}>
        <Box h='40px'>
          <Button
            onClick={() => {
              push(`${path}/new`)
            }}
            variant='brand'
            color='white'
            fontSize='sm'
            fontWeight='500'
            borderRadius='70px'
            px='24px'
            py='5px'>
            Add Portrait
          </Button>
        </Box>
      </HStack>
      <DataTable {...props}
        page={Number(searchParams.get('page') ?? 0)} />
    </>
  )
}

export default PortraitsTable
