import React, { useEffect } from 'react'
import {
  useHistory,
  useParams,
  useRouteMatch,
  Link
} from 'react-router-dom'
import { 
  Box, Text, useColorModeValue, 
  VStack, Image, Divider, HStack, Button,
  useDisclosure, Tag, Flex, SimpleGrid, Icon
} from '@chakra-ui/react';
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Card from 'components/card/Card';
import RejectDrawer from '../../RejectReasonDrawer';
import { globalStyles } from 'theme/styles'
import { getOne, approve, remove } from 'apis/artworks.api'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
import { MediaType } from 'types/artwork.types'
import config from 'config';
import styled from 'styled-components'

import Comments from '../../Comments';

import YouTubeEmbed from 'components/youtube/youtube'
import VimeoEmbed from 'components/vimeo/vimeo'
import { copyToClipboard, removePreWrap } from 'utils'
import { CopyIcon } from '@chakra-ui/icons'

import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { 
  MdRemoveRedEye,
  MdThumbUpAlt,
  MdOutlineBookmark,
  MdOutlineChat,
  MdAutoGraph,
  MdRotate90DegreesCw
} from 'react-icons/md';

dayjs.extend(localizedFormat)

const Description = styled(Box)`
  overflow-wrap: break-word;
  width:100%;
  .editor-paragraph {
    width: 100%;
  }

  * {
    overflow-wrap: break-word;
  }
`
const ImageBox = styled(Box)`
  width: 100%;
  img {
    width: 100%;
  }
`

const VideoBox = styled(Box)`
  width: 100%;
  iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }
`

const Detail = () => {
  const { url } = useRouteMatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const queryClient = useQueryClient()

  const { push, goBack } = useHistory()
  const { id } = useParams<{id: string}>()
  const { isLoading, isSuccess, data } = useQuery({
    queryKey: ['artwork', id],
    queryFn: async () => {
      return getOne(id)
    }
  })

  const mutationApprove = useMutation((id: string) => {
    return approve(id)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['artworks']})
    }
  })

  const mutationRemove = useMutation((id: string) => {
    return remove(id)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['artworks']})
    }
  })

	const handleApprove = () => {
		mutationApprove.mutate(id)
    goBack()
	}

	const handleReject = () => {
		onOpen()
	}

  const handleDelete = () => {
    mutationRemove.mutate(id)
    goBack()
  }

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.900';
	const bg = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const isVimeo = data?.video?.url.indexOf('https://vimeo.com/') >= 0
  const isYoutube = data?.video?.url.indexOf('https://www.youtube.com/') >= 0 || data?.video?.url.indexOf('https://youtu.be/') >= 0

  const ActionStack = 
    <HStack pt={5} pb={5} alignContent="end">
      <Button variant='outline' mr={3} onClick={() => {
        goBack()
      }}>
        Cancel
      </Button>

      <Button
        onClick={handleDelete}
        variant='danger'
        fontSize='sm'
        fontWeight='500'
        borderRadius='70px'
        px='24px'
        py='5px'>
        Delete
      </Button>

      <Button
        onClick={handleReject}
        variant='outline'
        color={globalStyles.colors.red[500]}
        fontSize='sm'
        fontWeight='500'
        borderRadius='70px'
        px='24px'
        py='5px'>
        Reject
      </Button>
      
      <Button
        onClick={handleApprove}
        variant='darkBrand'
        color='white'
        fontSize='sm'
        fontWeight='500'
        borderRadius='70px'
        px='24px'
        py='5px'>
        Approve
      </Button>
    </HStack>
  


  return (
    <Card boxShadow={cardShadow} bg={bg}>
      {(isSuccess && data) ?
      <>
      <VStack alignItems={"flex-end"}>
        {ActionStack}
      </VStack>
      <VStack alignItems={"flex-start"}>
        <Divider pt={0} pb={0}/>
        <Text color={textColorSecondary} fontWeight='regular' fontSize='1xl' mt='10px' mb='4px'>
          Created on {dayjs(data.createdAt).format('LLLL')}
        </Text>
        <Text color={textColorPrimary} fontWeight='bold' fontSize='3xl' mt='10px' mb='4px'>
          {data.title}
        </Text>
        <Tag size={'md'} variant='solid' cursor={'pointer'} colorScheme='teal' onClick={() => {
          copyToClipboard(id)
        }}>
          {id}
          <CopyIcon ml={2} />
        </Tag>
        <ImageBox>
          {data.mediaType === MediaType.Image ? <Image src={config.uploadsUrl + '/' + data.image.filename} alt="" width={"100%"} /> : null}
          {data.mediaType === MediaType.VideoLink ? <Image src={data.video?.posterUrl} /> : null}
        </ImageBox>
        <VideoBox>
          {isYoutube ? <YouTubeEmbed className="videoplayer" url={data.video?.url} /> : null}
          {isVimeo ? <VimeoEmbed className="videoplayer" url={data.video?.url} /> : null}
        </VideoBox>
        
        <Description dangerouslySetInnerHTML={{__html: removePreWrap(data.description)}} />
        <Flex flexWrap={'wrap'} gap={2}>
          {data.tags.map(tag => <Tag size="md" variant="solid">{tag}</Tag>)}
        </Flex >

        <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
          Public Stats
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3, lg: 5, '2xl': 6 }} gap='20px' mb='20px'>
          <Link to={`${url}/activity/ext/viewed`}>
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdRemoveRedEye} color={brandColor} />}
                />
              }
              name='Views'
              value={data.activityAggregates.viewed.toString()}
            />
          </Link>
          <Link to={`${url}/activity/users/ext/voted`}>
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdThumbUpAlt} color={brandColor} />}
                />
              }
              name='Voted'
              value={(data.activityAggregates.voted?? 0).toString()}
            />
          </Link>
          <Link to={`${url}/activity/users/ext/itchbooked`}>
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdOutlineBookmark} color={brandColor} />}
                />
              }
              name='Itcbooked'
              value={data.activityAggregates.itchbooked.toString()}
            />
          </Link>
          <Link to={`${url}/activity/users/ext/commented+commented_artwork+responded_artwork`}>
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdOutlineChat} color={brandColor} />}
                />
              }
              name='Commented'
              value={data.activityAggregates.commented.toString()}
            />
          </Link>
          <Link to={`${url}/activity/ext/all`}>
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdAutoGraph} color={brandColor} />}
                />
              }
              name='Total'
              value={data.activityAggregates.total.toString()}
            />
          </Link>
        </SimpleGrid>
        <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
          Internal Stats
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3, lg: 5, '2xl': 6 }} gap='20px' mb='20px'>
          <Link to={`${url}/activity/int/viewed`}>
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdRemoveRedEye} color={brandColor} />}
                />
              }
              name='Views'
              value={Number(data.activityAggregates.viewed) > 0 ? Number(data.activityAggregates.viewed) - Number(data.activityAggregates_ghost?.viewed ?? 0) : 0}
            />
          </Link>
          <Link to={`${url}/activity/users/int/voted`}>
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdThumbUpAlt} color={brandColor} />}
                />
              }
              name='Voted'
              value={Number(data.activityAggregates.voted) > 0 ? Number(data.activityAggregates.voted) - Number(data.activityAggregates_ghost?.voted ?? 0) : 0}
            />
          </Link>
          <Link to={`${url}/activity/users/int/itchbooked`}>
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdOutlineBookmark} color={brandColor} />}
                />
              }
              name='Itcbooked'
              value={Number(data.activityAggregates.itchbooked) > 0 ? Number(data.activityAggregates.itchbooked) - Number(data.activityAggregates_ghost?.itchbooked ?? 0) : 0}
            />
          </Link>
          <Link to={`${url}/activity/users/int/commented+commented_artwork+responded_artwork`}>
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdRemoveRedEye} color={brandColor} />}
                />
              }
              name='Commented'
              value={Number(data.activityAggregates.commented) > 0 ? Number(data.activityAggregates.commented) - Number(data.activityAggregates_ghost?.commented ?? 0) : 0}
            />
          </Link>
          <Link to={`${url}/activity/int/all`}>
            <MiniStatistics
              startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={<Icon w='32px' h='32px' as={MdAutoGraph} color={brandColor} />}
                />
              }
              name='Total'
              value={Number(data.activityAggregates.total) > 0 ? Number(data.activityAggregates.total) - Number(data.activityAggregates_ghost?.total ?? 0) : 0}
            />
          </Link>
        </SimpleGrid>
        <Comments schemaName='artworks' id={id} />
        <Divider pt={5} pb={0}/>
      </VStack>
      <VStack alignItems={"flex-end"}>
        {ActionStack}
      </VStack>
      </>
      : null}
      <RejectDrawer id={id} type="artworks" artwork={data} isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
    </Card>
  )
}

export default Detail