import FAIcon from 'assets/img/icons/avatar_fa.png'
import FCIcon from 'assets/img/icons/avatar_fc.png'
import FLIcon from 'assets/img/icons/avatar_fl.png'
import { AccountType } from 'types/user.types'

export const toQueryParams = (obj: object) => {
  let out: string[] = [];
  for (const entry of Object.entries(obj)) {
    out.push(`${entry[0]}=${entry[1]}`)
  }
  return out.join('&');
}

export const formatAccountTypeName = (v:AccountType) => {
  switch (v) {
    case AccountType.FashionArtist: return 'Fashion Artist'
      
    case AccountType.FashionCompany: return 'Fashion Company'
      
    case AccountType.FashionLover: return 'Fashion Lover'
      
    default:
      return ''
  }
}

export const defaultAvatar = {
  FashionArtist: FAIcon,
  FashionCompany: FCIcon,
  FashionLover: FLIcon
}

export function getAccountTypeMeta(accountType: AccountType, iconSize = 32) {
  let name = '';
  let slug = '';
  let fallBackAvatar = null;
  switch (accountType) {
      case AccountType.FashionArtist:
          name = 'Fashion Artist';
          slug = 'fashion-artist';
          fallBackAvatar = defaultAvatar.FashionArtist
          break;
      case AccountType.FashionCompany:
          name = 'Fashion Company';
          slug = 'fashion-company';
          fallBackAvatar = defaultAvatar.FashionCompany
          break;
      case AccountType.FashionLover:
          name = 'Fashion Lover';
          slug = 'fashion-lover';
          fallBackAvatar = defaultAvatar.FashionLover
          break;
      default:
          break; 
  }
  return {
      name,
      slug,
      fallBackAvatar
  }
}

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const removePreWrap = (str: string) => {
  return str.replace(/white-space:\s*pre-wrap;/g, '');
}