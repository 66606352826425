import axiosInstance from './api';
import { 
  PaginatedQuery,
  PaginatedResult
} from './api.types'
import { toQueryParams } from 'utils'
import { 
  GetArtworkItem, GetUsersByActionItem, 
  GetItemsByUserActionSchemaItem, GetItemsByUserActionsItem 
} from 'types/analytic.types';

export const enum filters {
  All = 'trending-artworks',
  Featured = 'trending-featured-artworks',
  NonFeatured = 'trending-non-featured-artworks'
}

export const getArtworksSorted = async ({
  page,
  size,
  filter = filters.All,
  lessGhost = false,
  sort = 'total',
  desc = true
}: PaginatedQuery & {
  filter: filters,
  lessGhost: boolean;
  sort: string,
  desc: boolean
}): Promise<PaginatedResult<GetArtworkItem>> => {
  const query = '?' + toQueryParams({
    page,
    size,
    ghost: lessGhost,
    sort,
    desc
  })

  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<GetArtworkItem>>(`/analytics/${filter}${query}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getActivityActionsByUser = async ({
  action,
  schemaName,
  page,
  size
}: PaginatedQuery & {
  action: string;
  schemaName: string;
}): Promise<PaginatedResult<GetUsersByActionItem>> => {
  const query = '?' + toQueryParams({
    page,
    size
  })

  const res = await axiosInstance.get<PaginatedResult<GetUsersByActionItem>>(`/analytics/${schemaName}/${action}/users${query}`)
  return res.data
}

export const getActivityItemsByUserActionSchema = async ({
  action,
  schemaName,
  userId,
  page,
  size
}: PaginatedQuery & {
  action: string;
  schemaName: string;
  userId: string;
}): Promise<PaginatedResult<GetItemsByUserActionSchemaItem>> => {
  const query = '?' + toQueryParams({
    page,
    size
  })

  const res = await axiosInstance.get<PaginatedResult<GetItemsByUserActionSchemaItem>>(`/analytics/${schemaName}/${action}/users/${userId}/items${query}`)
  return res.data
}

export const getActivityItemsByUserActions = async ({
  actions,
  userId,
  page,
  size
}: PaginatedQuery & {
  actions: string[];
  userId: string;
}): Promise<PaginatedResult<GetItemsByUserActionsItem>> => {
  const query = '?' + toQueryParams({
    actions,
    page,
    size
  })

  const res = await axiosInstance.get<PaginatedResult<GetItemsByUserActionsItem>>(`/analytics/users/${userId}/actions${query}`)
  return res.data
}