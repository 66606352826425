import React, { useEffect} from 'react'

import { 
  Box,
  Text, 
  useColorModeValue, 
  Flex, 
  Divider, 
  Button,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  FormHelperText
} from '@chakra-ui/react'

import { approve, reject } from 'apis/articles.api';
import { useForm } from "react-hook-form";
import { ArticleApproveInput, ArticleRejectInput, ArticleTheme } from 'types/article.types'
import { RejectInput, RejectReason } from 'types/shared/approvable.types'

import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query'


type Props = {
  reviewType: 'approve' | 'reject';
  isOpen: boolean;
  onClose: () => unknown;
  btnRef: React.MutableRefObject<undefined>
}

type FormInput = {
  theme?: ArticleTheme
  reason?: string
}

export default function ReviewDrawer({
  reviewType,
  isOpen,
  onClose,
  btnRef
}: Props) {
  const { id } = useParams<{id:string}>()
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const bg = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  const queryClient = useQueryClient()
  
  const { mutate: mutationApprove } = useMutation((data: ArticleApproveInput) => {
    return approve(data)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['articles', id]})
      queryClient.invalidateQueries({ queryKey: ['counters']})
    }
  })
  
  const { mutate: mutationReject } = useMutation((data: ArticleRejectInput) => {
    return reject(data)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['articles', id]})
      queryClient.invalidateQueries({ queryKey: ['counters']})
    }
  })

  const { 
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { 
      errors
    }
  } = useForm<FormInput>()

  console.log(errors)

  const onSubmit = (data: FormInput) => {
    console.log('submit', data)
    if (reviewType === 'approve') {
      mutationApprove({
        id, 
        theme: data.theme
      })
    } else
    if (reviewType === 'reject') {
      mutationReject({
        id, 
        reason: data.reason
      })
    }
    
    onClose()
  }

  useEffect(() => {
  }, [])

  const body = reviewType === 'approve' ? 
    <>
      <FormControl>
        <FormLabel>Theme</FormLabel>
        
        <Select placeholder='Select Theme' width={180} 
          {...register('theme', {
            required: reviewType === 'approve' ? 'This is required' : false
          })}
        >
          <option value={ArticleTheme.Pink}>Pink</option>
          <option value={ArticleTheme.White}>White</option>
          <option value={ArticleTheme.Dark}>Dark</option>
        </Select>
        <FormErrorMessage>
          {errors.theme && errors.theme.message}
        </FormErrorMessage>
        <FormHelperText></FormHelperText>
      </FormControl>
    </> : <>
      <FormControl>
        <FormLabel>Reason</FormLabel>
        
        <Select 
          {...register('reason', {
            required: reviewType === 'reject' ? 'This is required' : false
          })}>
          <option value={RejectReason.Explicit}>Explicit</option>
          <option value={RejectReason.Legal}>Legal</option>
          <option value={RejectReason.Irrelevant}>Irrelevant</option>
          <option value={RejectReason.Unprofessional}>Unprofessional</option>
        </Select>
        <FormErrorMessage>
          {errors.reason && errors.reason.message}
        </FormErrorMessage>
        <FormHelperText></FormHelperText>
      </FormControl>
    </>

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      finalFocusRef={btnRef}
      isFullHeight={true}
    >
      <DrawerOverlay />
      <DrawerContent overflowY={"scroll"}>
        <DrawerCloseButton />
        <DrawerHeader>{reviewType === 'approve' ? 'Approve Article' : 'Reject Article'}</DrawerHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerBody>
            {body}
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant='darkBrand'
              color='white'
              fontSize='sm'
              fontWeight='500'
              borderRadius='70px'
              px='24px'
              py='5px'>
              Submit
            </Button>
          </DrawerFooter>
        </form>

      </DrawerContent>
    </Drawer>
  )
}