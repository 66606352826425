import React from 'react'

import { useRouteMatch } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Stats from './Stats';
import UserDetail from './UserDetails';
import EditDetail from './EditDetails';
import UserArtworks from './UserArtworks';
import UserArticles from './UserArticles';
import Followings from './Followings';
import Followers from './Followers';

const UserDetailsRoutes = () => {
  const { path, url } = useRouteMatch()

  console.log(path, url)
  return (  
    <Switch>
      <Route path={`${path}`} exact >
        <UserDetail />
      </Route>
      <Route path={`${path}/edit`} exact>
        <EditDetail />
      </Route>
      <Route path={`${path}/actions`} exact>
        <Stats />
      </Route>
      <Route path={`${path}/artworks`} exact>
        <UserArtworks/>
      </Route>
      <Route path={`${path}/articles`} exact>
        <UserArticles/>
      </Route>
      <Route path={`${path}/followings`} exact>
        <Followings/>
      </Route>
      <Route path={`${path}/followers`} exact>
        <Followers/>
      </Route>
    </Switch>
  )
}

export default UserDetailsRoutes;