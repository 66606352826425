import type {
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  Spread,
  DOMExportOutput,
  DOMConversionMap,
  DOMConversionOutput
} from "lexical";

import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents";
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode
} from "@lexical/react/LexicalDecoratorBlockNode";
import * as React from "react";

type VideoComponentProps = Readonly<{
  className: Readonly<{
    base: string;
    focus: string;
  }>;
  format: ElementFormatType | null;
  nodeKey: NodeKey;
  videoID: string;
  platform: string;
}>;

function VideoComponent({
  className,
  format,
  nodeKey,
  videoID,
  platform
}: VideoComponentProps) {

  const videoUrl = platform === 'youtube' ? `https://www.youtube.com/embed/${videoID}` : `https://player.vimeo.com/video/${videoID}?autoplay=0`

  return (
    <BlockWithAlignableContents
      className={className}
      format={format}
      nodeKey={nodeKey}
    >
      <iframe
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
        title="video"
      />
    </BlockWithAlignableContents>
  );
}

export type SerializedVideoNode = Spread<
  {
    videoID: string;
    platform: string;
    type: "youtube";
    version: 1;
  },
  SerializedDecoratorBlockNode
>;

function convertVideoElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  // const textContent = domNode.textContent;

  const videoUrl = domNode.getElementsByTagName('iframe')[0].src ?? ''

  if (videoUrl) {
    const { videoId, videoType} = getVideoIdPlatformFromUrl(videoUrl);
    const node = $createVideoNode(videoId, videoType);
    return {
      node
    };
  }
  
  return null;
}

const getVimeoIdFromUrl = (url: string) => {
  // Look for a string with 'vimeo', then whatever, then a
  // forward slash and a group of digits.
  const match = /vimeo.*\/(\d+)/i.exec(url);
  // If the match isn't null (i.e. it matched)
  if (match) {
    // The grouped/matched digits from the regex
    return match[1];
  }
};

export function getVideoIdPlatformFromUrl(url: string): { videoType: string, videoId: string } {
  let videoType = ''
  let videoId = ''
  if (url.indexOf('https://vimeo.com/') === 0) {
    videoId = getVimeoIdFromUrl(url)
    /*
    videoType = 'vimeo'
    const reVimeo = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/ig
    const groups = reVimeo.exec(url)
    if (groups?.length > 0) {
      videoId = groups[4]
    }
    */
  } else 
  if (url.indexOf('https://player.vimeo.com/video/') === 0) {
    videoType = 'vimeo'
    videoId = getVimeoIdFromUrl(url)
    /*
    const reVimeo = /(?:https:\/\/player.vimeo.com\/video\/)(.+)\/(?:autoplay=0)?/ig
    const groups = reVimeo.exec(url)
    if (groups?.length > 0) {
      videoId = groups[1]
    }
    */
  } else 
  if (url.indexOf('https://www.youtube.com/watch?v=') === 0 || url.indexOf('https://youtu.be/') === 0) {
    videoType = 'youtube'

    // https://www.youtube.com/watch?v=NgvxSqnA1jM&list=PLrNN1YufWexyFHNjvq5LRSzv_5YHa6g35 

    const reYoutube = /(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?/ig
    const groups = reYoutube.exec(url)
    if (groups?.length > 0) {
      videoId = groups[3]
    }
  } else 
  if (url.indexOf('https://www.youtube.com/embed/') === 0) {
    videoType = 'youtube'
    const reYoutube = /(?:https:\/\/(?:www\.)?youtube.com\/embed\/)(.+)/ig
    const groups = reYoutube.exec(url)
    if (groups?.length > 0) {
      videoId = groups[1]
    }
  }
  
  return {
    videoType,
    videoId
  }
}

function makeVideoUrl(id: string, platform: string):string {
  return platform === 'youtube' ? `https://www.youtube.com/embed/${id}` : `https://player.vimeo.com/video/${id}?autoplay=0`
}

export class VideoNode extends DecoratorBlockNode {
  __id: string;
  __platform: string;

  static getType(): string {
    return 'youtube';
  }

  static clone(node: VideoNode): VideoNode {
    return new VideoNode(node.__id, node.__platform, node.__format, node.__key);
  }

  static importJSON(serializedNode: SerializedVideoNode): VideoNode {
    const node = $createVideoNode(serializedNode.videoID, serializedNode.platform);
    node.setFormat(serializedNode.format);
    return node;
  }

  exportJSON(): SerializedVideoNode {
    return {
      ...super.exportJSON(),
      type: 'youtube',
      version: 1,
      videoID: this.__id,
      platform: this.__platform
    };
  }

  constructor(id: string, platform: string, format?: ElementFormatType, key?: NodeKey) {
    super(format, key);
    this.__id = id;
    this.__platform = platform
  }

  createDOM(): HTMLElement {
    const dom = super.createDOM();
    dom.className = "video";
    return dom;
  }

  updateDOM(): false {
    return false;
  }
  
  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-video")) {
          return null;
        }
        return {
          conversion: convertVideoElement,
          priority: 1
        };
      }
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    element.setAttribute("data-lexical-video", "true");
    element.setAttribute("class", 'video');
    
    const videoUrl = makeVideoUrl(this.__id, this.__platform)

    const iframe = document.createElement("iframe");
    iframe.setAttribute("src", videoUrl);
    iframe.setAttribute("frameBorder", "0");
    iframe.setAttribute("allow", 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute("allowFullScreen", 'video');
    iframe.setAttribute("title", 'video');

    element.appendChild(iframe);
    
    return { element };
  }

  getId(): string {
    return this.__id;
  }

  getTextContent(
    _includeInert?: boolean | undefined,
    _includeDirectionless?: false | undefined
  ): string {
    return makeVideoUrl(this.__id, this.__platform);
  }

  decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || "",
      focus: embedBlockTheme.focus || ""
    };
    return (
      <VideoComponent
        className={className}
        format={this.__format}
        nodeKey={this.getKey()}
        videoID={this.__id}
        platform={this.__platform}
      />
    );
  }

  isInline(): false {
    return false;
  }
}

export function $createVideoNode(videoID: string, platform: string): VideoNode {
  return new VideoNode(videoID, platform);
}

export function $isVideoNode(
  node: VideoNode | LexicalNode | null | undefined
): node is VideoNode {
  return node instanceof VideoNode;
}
