 import { UploadedFile } from 'components/upload-file/upload-file'
 
 export enum InvitationStatus {
  Pending = 1,
  Visited = 2,
  SignedUp = 3,
  EmailFailed = 4
}

export enum SendStatus {
  Unsent = 1,
  Sent = 2,
  Bounced = 3
}

export type Invitation = {
  _id: string,
  name: string,
  email: string,
  code: string,
  status: InvitationStatus,
  signedUpAt: Date
  visitedAt: Date,
  invitedAt: Date,
  unsubscribed: Boolean,
  sendStatus: SendStatus,
  updatedAt: Date
}

export type InviteListRow = {
  id: string,
  name: string,
  email: string,
  invitedAt: Date,
  code: string,
  status: InvitationStatus,
  updatedAt: Date
}

export type SendInviteItem = {
  name: string;
  email: string;
}

export type SendInvitesInput = {
  invites: Array<SendInviteItem>;
  uploadedFile?: UploadedFile
}
