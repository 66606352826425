import React, { ChangeEvent, useEffect, useState } from 'react';
import { 
  VStack, Select, StackDivider,
  Box, HStack
} from '@chakra-ui/react';
import ArtworksTable from './ArtworksTable';

import { ReviewStatus } from 'types/shared/approvable.types';

import useUrlQuery from '../useUrlQuery';
import { 
  Route, Switch, useRouteMatch, 
  useHistory, Redirect 
} from 'react-router-dom';

const ArtworksListing = () => {
  const { reviewStatus, page } = useUrlQuery()
  const { path } = useRouteMatch()
  const { push } = useHistory()
  
  const handleStatusChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    push(path + '?reviewStatus=' + evt.target.value)
  }

  useEffect(() => {
    if (!reviewStatus) {
      push(path + '?reviewStatus=pending')
    }
  }, [])

  return (

    <VStack
      divider={<StackDivider borderColor='gray.200' />}
      spacing={4}
      align='stretch'
    >
      <HStack spacing='24px' justifyContent={"flex-end"}>
        <Box h='40px'>
          <Select placeholder='Select Status' onChange={handleStatusChange} value={reviewStatus}>
            <option value={ReviewStatus.Pending}>Pending</option>
            <option value={ReviewStatus.Approved}>Approved</option>
            <option value={ReviewStatus.Rejected}>Rejected</option>
          </Select>
        </Box>
      </HStack>
      <ArtworksTable reviewStatus={reviewStatus}/>
    </VStack>
  )
}

export default ArtworksListing