
import React, { useEffect } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player/vimeo'

const PlayerContainer = styled.div`
  
`

export default function VimeoEmbed({
  url,
  ...rest
}) {
  const parts = url.split('/')
  const id = parts[parts.length - 1]

  return (
    <PlayerContainer {...rest}>
      <ReactPlayer 
        width={'100%'}
        height={'100%'}
        controls={true}
        url={`https://player.vimeo.com/video/${id}`} 
      />
    </PlayerContainer>
  )
}