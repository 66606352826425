import React from 'react'
import { 
  Box, Text, useColorModeValue, Grid
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';

import { getArtworksSorted, filters } from 'apis/analytics.api';
import { GetArtworkItem } from 'types/analytic.types';
import DataTable from 'components/dataTable/dataTable2'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { PaginatedQuery } from 'apis/api.types'
import { useRouteMatch, useHistory, Link } from 'react-router-dom'
import countries from 'i18n-iso-countries'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { MediaType } from 'types/artwork.types';
import Config from 'config'
import { number } from 'yup';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
dayjs.extend(localizedFormat)

type ArtworksTableProps = {
  size?: number,
  filter: filters
  view: string
}

const ArtworksAnalyticsTable = ({
  filter,
  view = 'ext',
  size = 30
}: ArtworksTableProps) => {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  
  const searchParams = new URLSearchParams(window.location.search);

  const columnHelper = createColumnHelper<GetArtworkItem>()

  const columns: Array<ColumnDef<GetArtworkItem, any>> = [
    columnHelper.accessor('imageUrl', {
      header: 'Artwork',
      cell: props => props.getValue() ? <img style={{display:'block',minWidth:'120px'}} src={`${Config.uploadsUrl}/${props.getValue()}`} alt=""/> : null,
      enableSorting: false,
      size: 20
    }),
    columnHelper.accessor('title', {
      header: 'Title',
      cell: props => props.getValue(),
      enableSorting: false,
      size: 20
    }),
    columnHelper.accessor('voteCount', {
      header: 'Voted',
      cell: props => props.getValue(),
      sortDescFirst: true,
      enableSorting: true,
      size: 5
    }),
    columnHelper.accessor('itchbookCount', {
      header: 'Itchbooked',
      cell: props => props.getValue(),
      sortDescFirst: true,
      enableSorting: true,
      size: 5
    }),
    columnHelper.accessor('commentCount', {
      header: 'Comments',
      cell: props => props.getValue(),
      sortDescFirst: true,
      enableSorting: true,
      size: 5
    }),
    columnHelper.accessor('viewCount', {
      header: 'Views',
      cell: props => props.getValue(),
      sortDescFirst: true,
      enableSorting: true,
      size: 5
    }),
    columnHelper.accessor('total', {
      header: 'Total',
      cell: props => props.getValue(),
      sortDescFirst: true,
      enableSorting: true,
      size: 5
    }),
    columnHelper.accessor('mediaType', {
      header: 'Type',
      cell: props => props.getValue() === MediaType.Image ? 'IMAGE': 'VIDEOLINK',
      enableSorting: false,
      size: 5
    }),
    columnHelper.accessor('authorName', {
      header: 'Author',
      cell: props => props.getValue(),
      enableSorting: false,
      size: 15
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      cell: props => dayjs(props.getValue()).format('LLL'),
      enableSorting: false,
      size: 15
    })
  ]

  const props: DataTableProps<GetArtworkItem, DataTableQuery> = {
    title: 'Artworks',
    enableSorting: true,
    columnsMeta: columns,
    page: Number(searchParams.get('page') ?? 0),
    size: Number(searchParams.get('size') ?? 10),
    sort: searchParams.get('sort'),
    desc: (searchParams.get('desc') ?? 'true') === 'true',
    cacheKey: `artworks-${filter}-${searchParams.get('sort')}-${searchParams.get('desc')}`,
    queryFn: ({ page, size, sort, desc }) => getArtworksSorted({
      page,
      size,
      filter,
      lessGhost: view === 'int',
      sort: sort ?? 'total',
      desc: desc ?? true
    }),
    onRowClick: (data: GetArtworkItem) => {
      push(`/admin/submissions/artworks/` + data._id)
    },
    /*
    onSort: (sort: string, desc: boolean) => {
      if (sort) {
        searchParams.set('sort', sort)
        searchParams.set('desc', desc.toString())
      } else {
        searchParams.delete('sort')
        searchParams.delete('desc')
      }

      push(`${url}?${searchParams.toString()}`)
    }
    */
  }

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      <DataTable {...props} />
    </Box>
  )
}

export default ArtworksAnalyticsTable;