import { useMutation  } from '@tanstack/react-query';
import { upload } from 'apis/upload.api';

export default function useUpload(uploadFunc: (files: FileList) => Promise<any> = upload) {
  const { mutate, isLoading, isSuccess, isError, data, error, reset } = useMutation({
    mutationFn: (files: FileList) => {
      return uploadFunc(files)
    }
  })

  return {
    uploadFiles: mutate,
    isLoading,
    isSuccess: isSuccess && data?.data.errors.length === 0,
    isError: isError || data?.data.errors.length > 0,
    result: data?.data.uploads,
    error: error || data?.data.errors,
    resetUpload: reset
  }
}