import axiosInstance from './api';
import { 
  User, GetUserDetail, UserListRow, AccountType, 
  CompanyType, GetAllUserItem, GetOneUser,
  hydrateDerivedUser, 
  UserCompact,
  UserMethods,
} from 'types/user.types'
import {
  Portrait,
  GetAllPortraitsRowItem,
  CreatePortraitInput,
  UpdatePortraitInput,
  GetOnePortraitOutput
} from 'types/portrait-types'

import { 
  PaginatedQuery,
  PaginatedResult
} from './api.types'
import { DataTableQuery } from 'components/dataTable/dataTable.types';

type GetAllProps = DataTableQuery & PaginatedQuery

export const getAll = async (props: GetAllProps): Promise<PaginatedResult<GetAllPortraitsRowItem>> => {
  let query = `?${props.page ? 'page=' + props.page : ''}`
  if (props.size)
    query = query + '&size=' + props.size
  if (props.query) {
    query = query + '&searchKeyword=' + props.query['name']
  }
  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<GetAllPortraitsRowItem>>(`/portraits${query}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map(o => ({
            id: o.id,
            image: o.image,
            name: o.name,
            userId: o.userId,
            itchUrl: o.itchUrl
          }))
        })
      })
      .catch(err => reject(err))
  })
}

export const getOne = async (id:string): Promise<GetOnePortraitOutput> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get<GetOnePortraitOutput>(`/portraits/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  })
}

export const addPortrait = async (props: CreatePortraitInput): Promise<void> => {
  return await axiosInstance.post('/portraits', props)
}

export const updatePortrait = async (id: string, props: UpdatePortraitInput): Promise<void> => {
  return await axiosInstance.put(`/portraits/${id}`, props)
}

export const deletePortrait = async (id: string): Promise<void> => {
  return await axiosInstance.delete(`/portraits/${id}`)
} 
