import axiosInstance from './api';
import { TestEmailInput, EmailInput } from 'types/email.types'

export const sendMassEmail = async (data: EmailInput):Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.post<void>(`/email`, data)
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}

export const sendTestEmail  = async (data: TestEmailInput): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.post<void>(`/email/test`, data).then(() => {
      resolve()
    }).catch(err => reject(err))
  })
}
