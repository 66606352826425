import React, { 
  forwardRef,
  useRef,
  useEffect,
  useState
} from 'react';

import {
  Box,
  Flex,
  useColorModeValue,
  Button,
  VStack,
  Text
} from '@chakra-ui/react'
import { AiOutlineFile, AiOutlineCloudUpload } from 'react-icons/ai'

import useUpload from 'hooks/use-upload.hook';
import useDeleteUpload from 'hooks/delete-upload.hook';
import { Progress } from '@chakra-ui/react'
import { uploadInvites } from 'apis/invites.api'

import {
  DeleteIcon
} from '@chakra-ui/icons'

export type UploadedFile = {
  mimeType: string,
  filename: string
}

type FileProps = {
  value?: UploadedFile,
  disabled?: boolean,
  noUpload?: boolean,
  onChange?: (props: UploadedFile) => unknown,
  onBlur?: () => unknown,
}

export default forwardRef(function FileUpload(
  props: FileProps,
  ref
) {
  // const { setErrorAlert } = useErrorAlert()
  const { 
    uploadFiles,
    isLoading,
    isSuccess,
    isError,
    result,
    error,
    resetUpload,
  } = useUpload(uploadInvites)

  const deleteUpload = useDeleteUpload()

  const { 
    value,
    disabled,
    noUpload,
    ...rest
  } = props;

  const dropZoneOnDragOverColor = useColorModeValue('navy.700', 'white');

  const [uploadCount, setUploadCount] = useState(0);
  const containerElem = useRef(null);
  const [fileDraggedOver, setFileDraggedOver] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(null)
    
  function handleFile(files: FileList) {
    if (files.length === 1) {
      uploadFiles(files)

      /*
      const reader = new FileReader();
      reader.onload = null;

      reader.readAsDataURL(files[0]); // read the data blob from upload
      
      reader.onload = () => {
        // img.src = reader.result as string;
        // todo - 
      };
      */
    }
  }

  useEffect(() => {
    if (props.value) {
      // const { filename } = props.value ?? null
    }
  }, [])

  useEffect(() => {
    if (value) {
      // previewElem.current.src = imageUrl(value)
    }
  }, [value])

  useEffect(() => {
    if (isSuccess) {
      if (result && result.length > 0) {
        const url = process.env.REACT_APP_UPLOADS_URL + '/' + result[0].name;

        const fileUploaded:UploadedFile = {
          mimeType: result[0].mimetype,
          filename: result[0].name
        }

        props.onChange && props.onChange(fileUploaded)
        props.onBlur && props.onBlur()

        setUploadCount(uploadCount + 1)

        setUploadedFile(fileUploaded)
      }
    } else
    if (isError) {
      let body = ''
      if (error) {
        switch(error[0].code) {
          case 'ERR_FILE_TYPES':
            body = 'Please upload a valid file type with a size of no more than 20mb.'
            break;
          case 'ERR_FILE_SIZE':
            body = 'Please upload a valid file type with a size of no more than 20mb.'
            break;
          default:
            body = 'Please upload a valid file type with a size of no more than 20mb.'
        }
        /*
        setErrorAlert({
          active: true,
          body,
        })
        */
      }
    }
  }, [isSuccess, isError])

  return (
    <Flex
      border={'1px solid black'}
      background={'#efefef'}
      style={{
        cursor: 'pointer'
      }}
      width={'100%'}
      height={'100%'}
      justifyContent={'center'}
      cursor={'pointer'}
      position={'relative'}
      userSelect={'none'}
      onDrop={(evt: React.DragEvent<HTMLDivElement>) => {
        // handle file
        if (evt.dataTransfer.files 
          && evt.dataTransfer.files.length === 1) {
          handleFile(evt.dataTransfer.files)
        }
        evt.preventDefault()
      }}
      onDragOver={(evt: React.DragEvent<HTMLDivElement>) => {
        setFileDraggedOver(true)
        evt.preventDefault()
      }}
    >
      {result && result?.length > 0 ? 
      <Flex width={'100%'} height={'100%'} direction="row"
        pr={3}
        backgroundColor={'rgba(255,255,255, .5)'}
        backdropFilter={'blur(2px)'}
        alignItems={'center'}
        position={'absolute'}
        zIndex={15}
        justifyContent={'center'} >
        <Flex mr={1} flex={'1 1 auto'} ml={4}>
          <AiOutlineFile size={30}/>
          <Box flex={'1 1 auto'} pl={1}>
            <Text mt={1} textOverflow={'ellipsis'} width={'95%'} whiteSpace={'nowrap'} overflow={'hidden'}>{result[0].name}</Text>
          </Box>
        </Flex>
        <DeleteIcon
          onClick={() => {
            deleteUpload.deleteFile(result[0].name)
            setUploadCount(0)
            resetUpload()
            props.onChange(null)
          }}
        />
      </Flex> : null}

      <Box
        position={'absolute'}
        zIndex={4}
        backgroundColor={'transparent'}
        cursor={'pointer'}
      >
        <Flex
          opacity={0}
          width={'100%'}
          height={'100%'}
        >
          <input 
            style={{
              zIndex: 9,
              width: "100%",
              height: "100%",
              opacity: 0
            }}
            disabled={disabled || noUpload}
            type="file"
            onChange={(evt) => {
              if (evt.target.files.length === 1) {
                handleFile(evt.target.files)
              }
          }} />
        </Flex>
      </Box>
      {isLoading ? 
      <Box 
        position={'absolute'}
        zIndex={9}
        top={0}
        left={0}
        width={'100%'}
        height={'100%'}
        backgroundColor={'rgba(0,0,0, .85'}
      >
        <Progress/>
      </Box> : null}
      
      {!disabled ? <Flex
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'} 
        height={'100%'}
        position={'absolute'}
        style={{
          cursor: 'pointer'
        }}
        zIndex={3}
      >
        <AiOutlineCloudUpload size={30}/>
      </Flex> : null}
    </Flex>
  );
});