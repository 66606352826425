import React from 'react'
import { 
  Box, SimpleGrid, Flex,
  useColorModeValue
} from "@chakra-ui/react";

import UsersTable from './UsersTable';
import InvitesTable from './InvitesTable';
import UnsubscribesTable from './UnsubscribesTable';
import UserDetailRoutes from './UserDetails';

import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import SubNavLink from 'components/subNav/link';

const Users = () => {
  const { path } = useRouteMatch()
  const { push } = useHistory()
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  return (  
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        ms="14px"
        mb="20px"
        align="center"
      > 
        <SubNavLink 
          to={`${path}/invites`}
          label="All Invites"/>
        
        <SubNavLink 
          to={`${path}/unsubscribes`}
          label="Unsubscribed"/>
        
        <SubNavLink 
          to={`${path}/sign-ups`}
          label="All Signups"/>
        
      </Flex>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "20px", xl: "20px" }}>
          <Switch>
            <Route exact path={`${path}`}>
              <Redirect to={`${path}/sign-ups`} />
            </Route>
            <Route path={`${path}/invites`} exact>
              <InvitesTable />
            </Route>
            <Route path={`${path}/unsubscribes`} exact>
              <UnsubscribesTable />
            </Route>
            <Route path={`${path}/sign-ups`} exact>
              <UsersTable />
            </Route>
            <Route path={`${path}/sign-ups/:id`}>
              <UserDetailRoutes />
            </Route>
          </Switch>
      </SimpleGrid>
    </Box>
  )
}

export default Users;