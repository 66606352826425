import React, { useEffect } from 'react'
import {
  useForm,
  SubmitHandler,
  Controller
} from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  useColorModeValue,
  Box,
  Flex,
  Button,
  SimpleGrid,
  Alert,
  AlertIcon,
} from '@chakra-ui/react'

import Card from 'components/card/Card';

import { updatePassword } from 'apis/admin-user.api'
import { ChangePasswordInput } from 'types/admin-user.types'
import { useQueryClient, useMutation } from '@tanstack/react-query';

const ChangePassword = () => {
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  const { push } = useHistory()
  
  const { mutate} = useMutation((data: ChangePasswordInput) => {
    return updatePassword({
      ...data
    })
  })
  const { 
    control,
    register, 
    handleSubmit, 
    watch, 
    reset,
    formState: { errors } 
  } = useForm<ChangePasswordInput>();

  const onSubmit: SubmitHandler<ChangePasswordInput> = (data) => {
    mutate(data)
    push('/admin/users/sign-ups')
  }

  const hasErrors = Object.keys(errors).length > 0

  return (
    <Card p='20px' boxShadow={cardShadow} width={700}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={1} spacing={5}>
          {hasErrors ? <Alert status='error'>
            <AlertIcon />
            There was an error processing your request
          </Alert> : null}

          <FormControl>
            <FormLabel>Current Passwrd</FormLabel>
            <Input type='text'
            {...register('current', {
              required: 'This is required'
            })} />
            <FormHelperText></FormHelperText>
            <FormErrorMessage>
              {errors.current && errors.current.message}
            </FormErrorMessage>  
          </FormControl>
          
          <FormControl>
            <FormLabel>New Passwrd</FormLabel>
            <Input type='text'
            {...register('password', {
              required: 'This is required'
            })} />
            <FormHelperText></FormHelperText>
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>  
          </FormControl>

          <Flex justifyContent={'flex-end'}>
            <Button
              type="submit"
              variant='darkBrand'
              color='white'
              fontSize='sm'
              fontWeight='500'
              borderRadius='70px'
              px='24px'
              py='5px'>
              Save
            </Button>
          </Flex>
        </SimpleGrid>
      </form>
    </Card>
  )
}

export default ChangePassword