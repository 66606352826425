import axiosInstance from './api';

export const upload = (
  files: FileList
) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++ ){
    const file = files.item(i)
    formData.append("files", file);
  }

  return axiosInstance.post('/uploads', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const deleteFile = (filename: string) => {
  return axiosInstance.delete('/uploads/' + filename)
}

export const uploadAndMakeVariants = (
  files: FileList
) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++ ){
    const file = files.item(i)
    formData.append("files", file);
  }

  return axiosInstance.post('/uploads/variants/article-attached', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

