import React from 'react'
import {
  useRouteMatch,
  useHistory
} from 'react-router-dom'
import {
  Link,
  useColorModeValue
} from '@chakra-ui/react'

type Props = {
  to: string;
  label: string;
}

const SubNavLevel2Link = ({
  to,
  label
}: Props) => {
  const match = useRouteMatch({
    path: to,
    exact: false
  })
  const { push } = useHistory()
  const textColorBrand = useColorModeValue('grey.500', 'white');
  const textColorActive = useColorModeValue('white', 'white');
  const textBackgroundColorActive = useColorModeValue('blackAlpha.800', 'white');

  return (
    <Link
      pt={2}
      pb={2}
      ps={4}
      pe={4}
      fontSize={12}
      borderRadius={8}
      backgroundColor={match ? textBackgroundColorActive : 'blackAlpha.100'}
      color={match ? textColorActive : textColorBrand}
      fontWeight='800'
      me={{ base: '34px', md: '44px' }}
      onClick={() => {
        push(to)
      }}>
      {label}
    </Link>
  )
}

export default SubNavLevel2Link