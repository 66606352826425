import React from 'react'
import { 
  Box, Grid, useColorModeValue,
  Alert, AlertDescription, AlertTitle, AlertIcon
} from '@chakra-ui/react'
import Card from 'components/card/Card';
import ArticleCard from 'components/card/ArticleCard';

import { getUserArticles } from 'apis/users.api'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const UserArticles = () => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  const bg = useColorModeValue('white', 'navy.700');

  const { push } = useHistory()
  const { id } = useParams<{id:string}>()
  const { isLoading, isSuccess, data } = useQuery({ 
    queryKey: ['user-articles', id], 
    queryFn: async () => {
      return getUserArticles(id)
    }
  })

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        lg: '1fr 1fr 1fr'
      }}
      templateRows={{
        base: 'repeat(1, 1fr)',
        lg: '1fr'
      }}
      gap={{ base: '20px', xl: '20px' }}>
      {isSuccess && data ? data.data.map(item => (
        <ArticleCard
          onClick={() => {
            push(`/admin/submissions/articles/${item.id}`)
          }}
          slug={item.slug}
          id={item.id}
          name={item.title}
          subtext={item.subHead}
          author={item.user.firstName}
          image={item.coverImage.url}
          createdAt={item.createdAt}
        />
      )) : null}
      {isSuccess && data && data.data.length === 0 ? 
      <Alert
        status='info'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          No Articles uploaded
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          
        </AlertDescription>
      </Alert> : null }
    </Grid>
  )
}

export default UserArticles;
