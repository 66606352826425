import React, { useState} from 'react'
import { getActivityHistoryUsers, deleteActivities } from 'apis/activity.api'
import DataTable from 'components/dataTable/dataTable2'
import { ActivityHistoryListItem } from 'types/activity.types'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { useRouteMatch, useHistory, useParams, Link } from 'react-router-dom'
import usePagingationQuery from 'hooks/use-pagination-query.hook'
import countries from 'i18n-iso-countries'
import dayjs from 'dayjs'
import { 
  FaEye
} from 'react-icons/fa';
import {
  Button,
  Checkbox
} from '@chakra-ui/react'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const ActivityHistory = () => {
  const [rowsSelection, setRowSelection] = useState<Array<ActivityHistoryListItem>>([])
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const { page, size } = usePagingationQuery()
  const { id, ghost, actions } = useParams<{
    id: string,
    ghost: string,
    actions: string
  }>()
  const queryClient = useQueryClient()

  const mutateDeleteActivities = useMutation((ids: Array<string>) => {
    return deleteActivities(ids)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['artwork-history', {
        id,
        ghost,
        action: actions.split('+').join(','),
        page,
        size
      }]})
    }
  })

  const columnHelper = createColumnHelper<ActivityHistoryListItem>()
  const columns: Array<ColumnDef<ActivityHistoryListItem, any>> = [
    columnHelper.accessor('name', {
      header: 'By',
      cell: props => props.getValue(),
      size: 60
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created',
      cell: props => <>{dayjs(props.getValue()).format('YYYY-MMM-D LTS')}</>,
      size: 30
    }),
    columnHelper.accessor('userId', {
      header: 'View User',
      cell: props => <Link to={`/admin/users/sign-ups/${props.getValue()}`}><FaEye /></Link>,
      size: 10
    })
  ]

  const tableActions = <>
    <Button onClick={() => {
      let ids = rowsSelection.map((item) => item.id)
      mutateDeleteActivities.mutate(ids)
    }}>Delete</Button>
  </>
  
  const props: DataTableProps<ActivityHistoryListItem, DataTableQuery> = {
    title: 'Activity Logs',
    columnsMeta: columns,
    cacheKey: 'activity-history',
    getRowId: (row, index, parent) => {
      return row.id
    },
    queryFn: ({ page, size }) => getActivityHistoryUsers({
      page,
      size,
      schema: 'artworks',
      actions: actions.split('+'),
      relatedId: id,
      ghost: ghost === 'int'
    }),
    onPageChange: (page => {
      push(`${url}?page=${page}&`)
    }),
    enableRowSelection: true,
    actions: tableActions,
    onRowSelectionChange: (rows: Array<ActivityHistoryListItem>) => {
      setRowSelection(rows)
    }
  }
  return (
    <DataTable {...props} size={50} page={page} />
  )
}

export default ActivityHistory;