import React, { useEffect, useState, useRef } from 'react'
import { 
  useForm 
} from 'react-hook-form'
import {
  useHistory,
  useParams
} from 'react-router-dom'

import { 
  Box, Text, useColorModeValue, Grid, Flex,
  VStack, Image, Divider, HStack, Button,
  useDisclosure, Tag, Wrap
} from '@chakra-ui/react';
import styled from 'styled-components'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Card from 'components/card/Card';
import { globalStyles } from 'theme/styles'

import FieldValue from 'components/fields/FieldValue'

import { getOne } from 'apis/articles.api'
import { approve, reject, remove } from 'apis/articles.api';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
import ArticleApproveDrawer from '../ArticleApproveDrawer';
import RejectDrawer from '../RejectReasonDrawer';
import ReviewDrawer from '../ReviewDrawer';
import { ArticleApproveInput, ArticleRejectInput } from 'types/article.types';
import { UserMethods } from 'types/user.types';

import {
  imageUrl
} from 'shared/utils'

dayjs.extend(localizedFormat)

const RichBodyContent = styled(Box)`
  width: 100%;
  max-width: 800px;

  iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }
`

const ArticleDetail = () => {
  const queryClient = useQueryClient()

  const [ reviewAction, setReviewAction ] = useState<'approve' | 'reject'>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef(null)
  
  const { push, goBack } = useHistory()
  const { id } = useParams<{id: string}>()
  const { isLoading, isSuccess, data } = useQuery({
    queryKey: ['article', id],
    queryFn: async () => {
      return getOne(id)
    }
  })

  const mutationRemove = useMutation((id: string) => {
    return remove(id)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['articles']})
    }
  })
  
  const handleDelete = () => {
    mutationRemove.mutate(id)
    goBack()
  }

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.900';
	const bg = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  const ActionStack = 
    <Flex pt={5} pb={5} width={'100%'} justifyContent={"space-between"}>

      <Button
        ms={"auto"}
        me={6}
        onClick={handleDelete}
        variant='danger'
        fontSize='sm'
        fontWeight='500'
        borderRadius='70px'
        px='24px'
        py='5px'>
        Delete
      </Button>

      <Button
        onClick={() => {
          setReviewAction('approve');
          onOpen()
        }}
        me={6}
        variant='darkBrand'
        color='white'
        fontSize='sm'
        fontWeight='500'
        borderRadius='70px'
        px='24px'
        py='5px'>
        Approve
      </Button>

      <Button
        onClick={() => {
          setReviewAction('reject');
          onOpen()
        }}
        variant='outline'
        color={globalStyles.colors.red[500]}
        fontSize='sm'
        fontWeight='500'
        borderRadius='70px'
        px='24px'
        py='5px'>
        Reject
      </Button>
    </Flex>

  return (
    <Card boxShadow={cardShadow} bg={bg}>
      {(isSuccess && data) ?
      <VStack alignItems={"flex-start"}>
        {ActionStack}
        <Text color={textColorSecondary} fontWeight='regular' fontSize='1xl' mt='10px' mb='4px'>
          ID {id}
        </Text>
        <Divider pt={0} pb={0}/>
        <Text color={textColorSecondary} fontWeight='regular' fontSize='1xl' mt='10px' mb='4px'>
          Created on {dayjs(data.createdAt).format('LLLL')} 
        </Text>
        <Text color={textColorSecondary} fontWeight='regular' fontSize='1xl' mt='10px' mb='4px'>
        by <a href={`/admin/users/sign-ups/${data.user._id}`}>{UserMethods.getName(data.user)}</a>
        </Text>
        <Text color={textColorPrimary} fontWeight='bold' fontSize='3xl' mt='10px' mb='4px'>
          {data.title}
        </Text>
        <Text color={textColorSecondary} fontWeight='regular' fontSize='2xl' mt='10px' mb='4px'>
          {data.subHead}
        </Text>
        <Image src={imageUrl(data.coverImage)} alt="" width={"100%"} height={'auto'}/>
        <Text color={textColorSecondary} fontWeight='regular' fontSize='1xl' mt='10px' mb='4px'>
          {data.caption}
        </Text>
        <Flex alignItems='center' direction='column' w={'100%'}>
          <RichBodyContent dangerouslySetInnerHTML={{__html: data.content}} />
        </Flex>
        <Flex flexWrap={'wrap'} gap={2}>
          {data.tags.map(tag => <Tag size="md" variant="solid">{tag}</Tag>)}
        </Flex >
        <Divider pt={5} pb={0}/>
        {ActionStack}
      </VStack>
      : null}
      <ReviewDrawer reviewType={reviewAction} isOpen={isOpen} onClose={() => {
        onClose()
        goBack()
      }} btnRef={btnRef} />
    </Card>
  )
}

export default ArticleDetail