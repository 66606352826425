import axiosInstance from './api';
import { 
  GetArchivedZinesItem,
  GetFeaturedArtworksChapterItem,
  CreateChapterInput,
  UpdateChapterInput,
  GetChapterOutput,
  ChapterListRow
} from 'types/zine.types'
import { 
  Article, GetOneArticle, CreateArticleInput, 
  CreateItchArticleInput, UpdateArticleInput, UpdateItchArticleInput 
} from 'types/article.types'
import { ReviewStatus, RejectInput } from 'types/shared/approvable.types';
import { 
  PaginatedQuery,
  PaginatedResult
} from './api.types'
import { toQueryParams } from 'utils';
import { MediaType } from 'types/artwork.types';
import Config from 'config'
import { UserMethods } from 'types/user.types';

export const getList = async ({page, size}: PaginatedQuery): Promise<PaginatedResult<ChapterListRow>> => {
  const query = '?' + toQueryParams({
    page,
    size
  })

  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<GetChapterOutput>>(`/zine/chapters${query}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map(item => {

            const { _id, chapter, featuredArtwork, colourTheme, published, createdAt, updatedAt } = item

            let imageUrl = null
            
            if (item.image) {
              imageUrl = `${Config.uploadsUrl}/${item.image?.filename ?? ''}`
            } else {
              imageUrl = item.featuredArtwork.mediaType === MediaType.Image ? 
              `${Config.uploadsUrl}/${item.featuredArtwork.imageVariants.small.filename}` : item.featuredArtwork.video.posterUrl
            }

            let authorName = UserMethods.getName(item.featuredArtwork.user)
            
            return {
              _id,
              chapter,
              featuredArtwork,
              imageUrl,
              authorName,
              colourTheme,
              published,
              createdAt,
              updatedAt
            }
          })
        })
      })
      .catch(err => reject(err))
  })
}

export const getAll = async (
  props: PaginatedQuery
): Promise<PaginatedResult<GetArchivedZinesItem>> => {
  let query = `?${props.page ? 'page=' + props.page : ''}`
  if (props.size)
    query = query + '&size=' + props.size
  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<GetArchivedZinesItem>>(`/zine${query}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  })
}

export const getOne = async (
  id: string
): Promise<GetArchivedZinesItem> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get<GetArchivedZinesItem>(`/zine/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  })
}

export const addArticle = async (input: CreateArticleInput | CreateItchArticleInput):Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.post<void>(`/zine/itch-articles`, input)
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}

export const getOneArticle = async (id: string): Promise<GetOneArticle> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get<GetOneArticle>(`/zine/itch-articles/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  })
}

export const getAllArticle = async (
  { page = 0, size = 10 }: PaginatedQuery
): Promise<PaginatedResult<Article>> => {
  return new Promise((resolve, reject) => {
    
    const query = '?' + toQueryParams({
      page,
      size
    })
    
    axiosInstance.get<PaginatedResult<Article>>(`/zine/itch-articles${query}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  })
}

export const updateOneArticle = async (
  input: CreateArticleInput & {
    id: string
  } | UpdateItchArticleInput & {
    id: string
  }
) => {
  const { id, ...rest } = input
  return axiosInstance.put<void>(`/zine/itch-articles/${id}`, rest)
}

export const deleteOneArticle = async (id: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.delete<void>(`/zine/itch-articles/${id}`)
      .then(response => {
        resolve()
      })
      .catch(err => reject(err))
  })
}

export const getFeaturedArtworks = async (
  { page = 0, size = 10 }: PaginatedQuery
): Promise<PaginatedResult<GetFeaturedArtworksChapterItem>> => {
  return new Promise((resolve, reject) => {
    
    const query = '?' + toQueryParams({
      page,
      size
    })
    
    axiosInstance.get<PaginatedResult<GetFeaturedArtworksChapterItem>>(`/zine/featured-artworks${query}`)
      .then(result => {
        resolve({
          data: result.data.data,
          page,
          size
        })
      })
      .catch(err => reject(err))
  })
}

export const addChapter = async (
  chapter: CreateChapterInput
): Promise<void> => {
  return axiosInstance.post(`zine/chapters`, chapter)
}

export const getChapter = async (
  chapter: number
): Promise<GetChapterOutput> => {
  const resp = await axiosInstance.get<GetChapterOutput>(`zine/chapters/${chapter}`)
  return resp.data
}

export const getAllChapters = async (
  { page = 0, size = 10 }: PaginatedQuery
): Promise<PaginatedResult<GetChapterOutput>> => {
  return new Promise((resolve, reject) => {
    
    const query = '?' + toQueryParams({
      page,
      size
    })
    
    axiosInstance.get<PaginatedResult<GetChapterOutput>>(`/zine/chapters${query}`)
      .then(result => {
        resolve(result.data)
      })
      .catch(err => reject(err))
  })
}

export const updateChapter = async (
  chapter: number,
  data: UpdateChapterInput
): Promise<void> => {
  return axiosInstance.put(`zine/chapters/${chapter}`, data)
}

export const deleteChapter = async (
  chapter: number
): Promise<void> => {
  return axiosInstance.delete(`zine/chapters/${chapter}`)
}