import axiosInstance from './api';
import {
  IComment,
} from 'types/comment.type'
import { 
  PaginatedQuery,
  PaginatedResult
} from './api.types'

export const getAll = async (props: PaginatedQuery & {
  id: string,
  schemaName: string
}): Promise<PaginatedResult<IComment>> => {
  let query = `?${props.page ? 'page=' + props.page : ''}`
  if (props.size)
    query = query + '&size=' + props.size
  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<IComment>>(`/comments/${props.schemaName}/${props.id}${query}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data
        })
      })
      .catch(err => reject(err))
  })
}

export const deleteComments = async ({
  relatedId,
  schemaName,
  commentIds,
  responseIds,
}: {
  relatedId: string,
  schemaName: string,
  commentIds: Array<string>,
  responseIds: Array<string>
}) => {
  await axiosInstance.delete(`/comments/${schemaName}/${relatedId}`, {
    data: {
      commentIds,
      responseIds
    }
  })
}

