import React, { useReducer, useEffect } from 'react'
import { updateAuthorizationHeader } from '../apis/api'
import { getAuthToken, AuthProviderProps, setAuth, AuthContext, AuthState } from '../contexts/AuthContext'

const initialState: AuthState = {
  loggedIn: getAuthToken() ? true : false,
  token: getAuthToken()
}

export const AuthProvider = ({
  children
}: AuthProviderProps) => {
  const [state, dispatch] = useReducer(setAuth, {...initialState})
  const auth = { state, dispatch }

  useEffect(() => {
    const token = getAuthToken()
    if (token) {
      updateAuthorizationHeader(token)
    }
  }, [])

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
