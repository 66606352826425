import React, { useRef, KeyboardEvent } from 'react'
import { 
  Box, Text, useColorModeValue, Flex,
  IconButton, HStack
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { SearchIcon } from '@chakra-ui/icons';

type SearchBoxProps = {
  defaultValue?: string,
  onSearch?: (value:string) => void
}

const SearchBox = ({
  defaultValue,
  onSearch = () => {}
}: SearchBoxProps) => {
  const inputElem = useRef<HTMLInputElement>()
  return (
    <HStack w={800}>
      <InputField id="searchKeyword" 
        defaultValue={defaultValue} 
        ref={inputElem} 
        label="Search" 
        type="text" 
        placeholder="Enter a search keyword"
        onKeyPress={(evt: KeyboardEvent<HTMLInputElement>) => {
          if (evt.key === 'Enter') {
            onSearch(evt.currentTarget.value)
          }
        }}
      />
      <IconButton
        onClick={() => {
          if (onSearch) onSearch(inputElem.current.value)
        }}
        colorScheme='blue'
        aria-label='Search database'
        icon={<SearchIcon />}
      />
    </HStack>
  )
}

export default SearchBox