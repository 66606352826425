import Image from './shared/image.type';
import { Approvable } from './shared/approvable.types';
import { Tags } from './shared/tags.type'
import { User } from './user.types'

export enum ArticleTheme {
  Pink = 'pink',
  White = 'white',
  Dark = 'dark'
}
export interface Article extends Approvable {
  id: string,
  slug: string,
  title: string,
  subHead: string,
  coverImage: Image,
  caption: string,
  content: string,
  category: string,
  tags: Tags,
  user: User & {
    _id?: string
    id?: string
  },
  itchArticleMentionUser: User & {
    _id?: string
    id?: string
  },
  isItchArticleLatest: boolean,
  order: number,
  userTags: Array<string>,
  theme: ArticleTheme,
  createdAt: Date,
  updatedAt: Date
}

export interface GetOneArticle extends Approvable {
  _id: string,
  slug: string,
  title: string,
  subHead: string,
  coverImage: Image,
  caption: string,
  content: string,
  category: string,
  tags: Tags,
  user: User & {
    _id?: string
    id?: string
  },
  itchArticleMentionUser: string,
  isItchArticleLatest: boolean,
  order: number,
  userTags: Array<string>,
  theme: ArticleTheme,
  createdAt: Date,
  updatedAt: Date
}

export type CreateArticleInput = Pick<Article, 
  'title' | 'subHead' | 'content' | 'coverImage' | 'caption' | 'tags' | 'theme'> & {
    published: boolean
  }
  
export type UpdateArticleInput = Pick<Article,
  'title' | 'subHead' | 'content' | 'coverImage' | 'caption' | 'tags' | 'theme'> & {
    published: boolean
  }

export type CreateItchArticleInput = Pick<Article, 
  'title' | 'subHead' | 'category' | 'content' | 'coverImage' 
    | 'caption' | 'theme' |  'isItchArticleLatest'> & {
    itchArticleMentionUser: string
    published: boolean
    userId: string,
    _id: string
  }
  
export type UpdateItchArticleInput = Pick<Article,
  'title' | 'subHead' | 'category' | 'content' | 'coverImage' 
  | 'caption' | 'theme'  | 'isItchArticleLatest'> & {
    itchArticleMentionUser: string
    published: boolean
    userId: string
  }

export type ArticleApproveInput = {
  id: string
  theme: ArticleTheme
}

export type ArticleRejectInput = {
  id: string
  reason: string
}

export type ArticleListRow = {
  id: string,
  title: string,
  imageUrl: string,
  userName: string,
  createdAt: Date
}