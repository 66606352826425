import React, { useEffect } from 'react'
import {
  Badge
} from '@chakra-ui/react'
import { getAllPending, Counters } from 'apis/counters.api'
import { 
  useQueryClient, useQuery, useMutation 
} from '@tanstack/react-query'

type Props = {
  statKey?: string
}

const Counter = ({
  statKey = null
}) => {
  const { isLoading, isSuccess, data } = useQuery({
    queryKey: ['counters'],
    queryFn: async () => {
      return getAllPending()
    }
  })

  const statValue = (d: Counters): number => {
    if (d) {
      if (statKey) {
        d.forEach(item => {
          const [[key,value]] = Object.entries(item)
          if (key === statKey) return value
        })
      } else return d.reduce((acc, curr) => {
        return acc + Object.entries(curr).reduce((a, c) => a + c[1], 0)
      }, 0)
    } else {
      return 0
    }
  }

  return (
    <>
      <Badge>{statValue(data)}</Badge>
    </>
  )
}

export default Counter