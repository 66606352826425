import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdGroup, MdImage, 
	MdHome, MdPages, MdPieChart, MdAnnouncement,
	MdPortrait, MdOutlinePersonOff, MdFlag, MdTableView
} from 'react-icons/md';

// Admin Imports

import Users from 'views/admin/users';
import Submissions from 'views/admin/submissions'
import Zine from 'views/admin/zine'
import Settings from 'views/admin/settings'
import DeleteAccounts from 'views/admin/delete-accounts';
import ActvityLog from 'views/admin/activity'
import MassConversation from 'views/admin/mass-contact'
import Analytics from 'views/admin/analytics'
import Portraits from 'views/admin/portraits';
import Flags from 'views/admin/flags'

// Auth Imports
import SignInCentered from 'views/auth/signIn';

import Counter from 'views/admin/submissions/Counter';

const routes = [
	{
		name: 'User Manager',
		layout: '/admin',
		path: '/users',
		icon: <Icon as={MdGroup} width='20px' height='20px' color='inherit' />,
		component: Users
	},
	{
		name: 'Submissions',
		layout: '/admin',
		path: '/submissions',
		icon: <Icon as={MdImage} width='20px' height='20px' color='inherit' />,
		component: Submissions,
		badge: <Counter/>
	},
	{
		name: 'Zine',
		layout: '/admin',
		path: '/zine',
		icon: <Icon as={MdPages} width='20px' height='20px' color='inherit' />,
		component: Zine
	},
	{
		name: 'Analytics',
		layout: '/admin',
		path: '/analytics',
		icon: <Icon as={MdPieChart} width='20px' height='20px' color='inherit' />,
		component: Analytics
	},
	{
		name: 'Mass Contact',
		layout: '/admin',
		path: '/mass-contact',
		icon: <Icon as={MdAnnouncement} width='20px' height='20px' color='inherit' />,
		component: MassConversation
	},
	{
		name: 'Itch Portraits',
		layout: '/admin',
		path: '/itch-portraits',
		icon: <Icon as={MdPortrait} width='20px' height='20px' color='inherit' />,
		component: Portraits
	},
	{
		name: 'Delete Accounts',
		layout: '/admin',
		path: '/delete-accounts',
		icon: <Icon as={MdOutlinePersonOff} width='20px' height='20px' color='inherit' />,
		component: DeleteAccounts
	},
	{
		name: 'Flags',
		layout: '/admin',
		icon: <Icon as={MdFlag} width='20px' height='20px' color='inherit' />,
		path: '/flags',
		component: Flags
	},
	{
		name: 'Activity Log',
		layout: '/admin',
		icon: <Icon as={MdTableView} width='20px' height='20px' color='inherit' />,
		path: '/activity',
		component: ActvityLog
	},
	{
		name: 'Admin Settings',
		layout: '/admin',
		path: '/settings',
		icon: <Icon as={MdImage} width='20px' height='20px' color='inherit' />,
		hideOnNav: true,
		component: Settings
	},
	{
		name: 'Login',
		layout: '/auth',
		icon: <Icon as={MdTableView} width='20px' height='20px' color='inherit' />,
		path: '/login',
		component: SignInCentered
	}
];

export default routes;
