import React, { useEffect } from 'react'
import {
  useForm,
  SubmitHandler,
  Controller
} from 'react-hook-form'
import {
  Checkbox,
  SimpleGrid,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Switch,
  useColorModeValue,
  Box,
  Flex,
  Button,
  Alert,
  AlertIcon,
  Image,
  Text,
  HStack,
} from '@chakra-ui/react'
import {
  ExternalLinkIcon
} from '@chakra-ui/icons'
import { useParams, useHistory } from 'react-router-dom'

import Card from 'components/card/Card';
import ImageUpload from 'components/upload-image/upload-image'

import { 
  CreateChapterInput, CreateChapterFormInput, 
  UpdateChapterInput, ZineColourTheme
} from 'types/zine.types'

import { addChapter, updateChapter, getChapter, deleteChapter } from 'apis/zine.api'
import { getOne } from 'apis/artworks.api'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { MediaType } from 'types/artwork.types';
import { UserMethods } from 'types/user.types'

import {
  imageUrl
} from 'shared/utils'

const ChapterForm = () => {
  const { id } = useParams<{ id: string }>()
  const { push } = useHistory()
  
  const dangerColor = 'red.500'
  const textColorSecondary = 'gray.900';
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  
  const queryClient = useQueryClient()
  const mutationAddChapter = useMutation((data: CreateChapterInput) => {
    return addChapter(data)
  }, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['chapters'])
    }
  })
  const mutationUpdateChapter = useMutation((data: UpdateChapterInput) => {
    return updateChapter(Number(id), data)
  }, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['chapter', id])
      queryClient.invalidateQueries(['chapters'])
    }
  })
  const mutationDeleteChapter = useMutation((id: number) => {
    return deleteChapter(id)
  }, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['chapter', id])
      queryClient.invalidateQueries(['chapters'])
    }
  })
  const { isSuccess, data, refetch } = useQuery({
    queryKey: ['chapter', id],
    queryFn: async () => {
      return getChapter(parseInt(id))
    },
    enabled: false
  })
  const getArtworkQuery = useQuery({
    queryFn: async () => {
      return getOne(watchArtworkId)
    },
    enabled: false,
    cacheTime: 0
  })

  useEffect(() => {
    if (id !== 'new') {
      refetch()
    }
  }, [id])

  const { 
    control,
    register, 
    handleSubmit, 
    watch, 
    reset,
    setValue,
    formState: { errors } 
  } = useForm<CreateChapterFormInput>();

  const watchArtworkId = watch('artworkId')
  const watchColourTheme = watch('colourTheme')
  const watchImage = watch('image')
  const watchChapter = watch('chapter')

  useEffect(() => {
    if (watchArtworkId) {
      getArtworkQuery.refetch()
    }
  }, [watchArtworkId])

  const onSubmit: SubmitHandler<CreateChapterFormInput> = (data) => {
    if (id === 'new') {
      mutationAddChapter.mutate({
        artworkId: data.artworkId,
        colourTheme: data.colourTheme,
        chapter: data.chapter,
        published: data.published,
        image: data.image
      })
    } else {
      mutationUpdateChapter.mutate({
        artworkId: data.artworkId,
        colourTheme: data.colourTheme,
        chapter: data.chapter,
        published: data.published,
        image: data.image
      })
    }
    push('/admin/zine/chapters')
  }

  const onClose = () => {
    push('/admin/zine/chapters')
  }

  const onDelete = () => {
    mutationDeleteChapter.mutate(parseInt(id))
    push('/admin/zine/chapters')
  }

  const hasErrors = Object.keys(errors).length > 0

  const tintColor = (colorTheme: string): string => {
    const colors: Record<string, string> = {
      'pink': 'rgba(179, 11, 80, 0.75)',
      'white': 'rgba(240, 240, 240, 0.75)',
      'dark': 'rgba(35, 31, 32, 0.75)'
    }
    return colors[colorTheme]
  }
  
  useEffect(() => {
    if (isSuccess && data) {
      reset({
        artworkId: data.featuredArtwork?._id,
        colourTheme: data.colourTheme,
        chapter: data.chapter,
        published: data.published,
        image: data.image
      })
    }
  }, [isSuccess, data])

  const isNew = (id === 'new')

  const featuredArtwork = isSuccess && data ? data.featuredArtwork : getArtworkQuery.isSuccess && getArtworkQuery.data ? getArtworkQuery.data: null

  return (
    <Card p='20px' boxShadow={cardShadow} width={880}>
     {(isSuccess && data) || isNew ? <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={1} spacing={5}>
          {hasErrors ? <Alert status='error'>
            <AlertIcon />
            There was an error processing your request
          </Alert> : null}
        </SimpleGrid>
        
        <FormControl>
          <FormLabel>Artwork Id</FormLabel>
          <Input type='artworkId'
            {...register('artworkId', {
              required: 'This is required'
            })} />
          <FormHelperText></FormHelperText>
          <FormErrorMessage>
            {errors.artworkId && errors.artworkId.message}
          </FormErrorMessage>
        </FormControl>

        {featuredArtwork ? <FormControl>
          <FormLabel>Artwork Details</FormLabel>
          <Box>
            <VStack align={'left'}>
              <Text color={textColorSecondary} fontWeight='regular' fontSize='l' mt='10px' mb='4px'>Preview Artwork Selected</Text>
              <HStack>
                <Box position={'relative'} width={400} height={400} objectFit="cover" overflow={'hidden'}>
                {featuredArtwork.mediaType === MediaType.Image ? 
                  <Image src={imageUrl(featuredArtwork?.imageVariants?.medium) ?? ''} alt="" /> : null }
                {featuredArtwork.mediaType === MediaType.VideoLink ? 
                  <Image src={featuredArtwork?.video.posterUrl ?? ''} alt="" /> : null }
                </Box>
              </HStack>
              <Text color={textColorSecondary} fontWeight='regular' fontSize='2xl' mt='10px' mb='4px'>{featuredArtwork.title}</Text>
              <Text color={textColorSecondary} fontWeight='bold' fontSize='1xl' mt='10px' mb='4px'>{UserMethods.getName(featuredArtwork.user)} / {UserMethods.getAccountTypeName(featuredArtwork.user?.accountType)}</Text>
            </VStack>
          </Box>
        </FormControl> : null}
        
        <Box pt={4} pb={4}>
          <hr/>
        </Box>

        <FormControl>
          <FormLabel>Theme</FormLabel>
          
          <Select placeholder='Select Status' width={130} 
            {...register('colourTheme', {
              required: 'This is required'
            })}
          >
            <option value={ZineColourTheme.Pink}>Pink</option>
            <option value={ZineColourTheme.White}>White</option>
            <option value={ZineColourTheme.Dark}>Dark</option>
          </Select>
          <FormErrorMessage>
            {errors.colourTheme && errors.colourTheme.message}
          </FormErrorMessage>
          <FormHelperText></FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>Chapter</FormLabel>
          <Input type='number'
            width={20}
            {...register('chapter', {
              required: 'This is required'
            })} />
          <FormHelperText></FormHelperText>
          <FormErrorMessage>
            {errors.chapter && errors.chapter.message}
          </FormErrorMessage>
        </FormControl>
        
        <FormControl w={400} >
          <FormLabel>Upload Quality Image</FormLabel>
          <VStack align={'left'} >
            <Controller
              name="image"
              control={control}
              rules={{
                required: false
              }}
              render={({ field }) =>
                <ImageUpload 
                  width={400} 
                  height={400} 
                  disabled={false} 
                  noUpload={false}
                  placeholderImg={''}
                  {...field}
                />
              }
            />
          </VStack>
          
          <FormHelperText></FormHelperText>
          <FormErrorMessage>
            {errors.image && errors.image.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Feature Artwork Preview</FormLabel>
          {watchArtworkId ? <Text textDecoration={'underline'} color={textColorSecondary} fontWeight='regular' fontSize='md' mt='10px' mb='4px'>
            <a href={`${process.env.REACT_APP_PUBLIC_URL}/previews/zine/${watchArtworkId}?chapter=${watchChapter}&colour-theme=${watchColourTheme}${watchImage ? '&image=' + watchImage.filename : ''}`} rel="noreferrer" target="_blank">Preview Artwork as Featured</a>
            <ExternalLinkIcon ml={2} boxSize={4} />
          </Text> : null}
        </FormControl>
        
        <FormControl>
          <FormLabel>Published</FormLabel>
          <Controller
            control={control}
            name="published"
            render={({ 
              field: { onChange, onBlur, value, name, ref },
              fieldState, formState }) => (
              <Checkbox
                type="checkbox"
                onChange={(evt) => {
                  onChange(evt.target.checked)
                }}
                onBlur={onBlur}
                defaultChecked={data?.published}
                name={name} 
                ref={ref} />
            )}
          />
          <FormHelperText></FormHelperText>
        </FormControl>

        <Flex justifyContent={'flex-end'} mt={20}>
          <Button variant='outline' 
            color={dangerColor}
            borderColor={dangerColor} 
            mr={'auto'} onClick={onDelete}>
            Delete
          </Button>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant='darkBrand'
            color='white'
            fontSize='sm'
            fontWeight='500'
            borderRadius='70px'
            px='24px'
            py='5px'>
            Save
          </Button>
        </Flex>
      </form> : null}
    </Card>
  )
}

export default ChapterForm
