import React from 'react'
import { 
  Box, Grid, useColorModeValue,
  Alert, AlertDescription, AlertTitle, AlertIcon
} from '@chakra-ui/react'
import ArtworkCard from 'components/card/ArtworkCard';

import { getUserArtworks } from 'apis/users.api'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import { MediaType } from 'types/artwork.types';
import config from 'config';
import { useHistory } from 'react-router-dom';

const UserArtworks = () => {
  const { push } = useHistory()
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  const { id } = useParams<{id:string}>()
  const { isLoading, isSuccess, data } = useQuery({ 
    queryKey: ['user-artworks', id], 
    queryFn: async () => {
      return getUserArtworks(id)
    }
  })

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        lg: '1fr 1fr 1fr'
      }}
      templateRows={{
        base: 'repeat(1, 1fr)',
        lg: '1fr'
      }}
      gap={{ base: '20px', xl: '20px' }}>
      {isSuccess && data ? data.data.map(item => (
        <ArtworkCard
          onClick={() => {
            push(`/admin/submissions/artworks/${item.id}`)
          }}
          showCopyId={true}
          id={item.id}
          name={item.title}
          author={item.user.firstName}
          image={`${item.mediaType === MediaType.VideoLink ? item.poster?.url : config.uploadsUrl + '/' + item.imageVariants?.small?.filename}`}
        />
      )) : null}
      {isSuccess && data && data.data.length === 0 ? 
      <Alert
        status='info'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          No Artworks uploaded
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          
        </AlertDescription>
      </Alert> : null }
    </Grid>
  )
}

export default UserArtworks;
