import React from 'react'
import { 
  Box, SimpleGrid, Flex,
  useColorModeValue
} from "@chakra-ui/react";
import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import PortraitsTable from './PortraitsTable'
import PortraitForm from './PortraitForm'

const Portraits = () => {
  const { path } = useRouteMatch()

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        ms="14px"
        mb="20px"
        align="center"
      >
      </Flex>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "20px", xl: "20px" }}>
          <Switch>
            <Route exact path={`${path}`}>
              <PortraitsTable/>
            </Route>
            <Route exact path={`${path}/:id`}>
              <PortraitForm/>
            </Route>
          </Switch>
      </SimpleGrid>
    </Box>
  )
}

export default Portraits
