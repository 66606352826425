import React, { forwardRef, useEffect, useRef, useState } from 'react'
import './style.scss'

import {$getRoot, $getSelection, $setSelection, $insertNodes} from 'lexical';
import adminTheme from "./themes/adminTheme"
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import {$generateHtmlFromNodes, $generateNodesFromDOM} from '@lexical/html';
import { AutoLinkNode, LinkNode } from "@lexical/link";
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";

import { Box } from '@chakra-ui/react'

import ImagePlugin from "./plugins/ImagePlugin";
import { ImageNode } from "./nodes/ImageNode";
import MentionsPlugin from './plugins/MentionsPlugin';
import { MentionNode } from './nodes/MentionNode';
import EmbedVideoPlugin from './plugins/EmbedVideoPlugin'
import { VideoNode } from './nodes/VideoNode';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';

function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}

const getInitialState = (editor, initialValue) => {
  editor.update(() => {
    // In the browser you can use the native DOMParser API to parse the HTML string.
    const parser = new DOMParser();
    const dom = parser.parseFromString(initialValue, "text/html");

    // Once you have the DOM instance it's easy to generate LexicalNodes.
    const nodes = $generateNodesFromDOM(editor, dom);

    // const root = $getRoot();

    // Select the root
    $getRoot().select();
      
    // Insert them at a selection.
    $insertNodes(nodes);
    $setSelection(null);
  });
};

const getEditorConfig = (initialValue) => ({
  // The editor theme
  editable: true,
  theme: adminTheme,
  editorState: (editor) => getInitialState(editor, initialValue),
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    LinkNode,
    ImageNode,
    MentionNode,
    VideoNode
  ]
});

const PasteFormattedContentPLugin = ({value}) => {
  //const [initialValue, setInitialValue] = useState('')
  // const [defaultValue, setDefaultValue] = useState('')
  const [editor] = useLexicalComposerContext();
  // console.log('initialValue', value)
  useEffect(() => {
    
    return editor.registerUpdateListener((listener) => {
      if (!value || !listener.tags.has('history-merge')) return;
      
      editor.update(() => {
        
        const parser = new DOMParser();
        const dom = parser.parseFromString(value, 'text/html');
        
        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);
        
        // Select the root
        $getRoot().select();
      
        // Insert them at a selection.
        $insertNodes(nodes);
        $setSelection(null);
      })
    })
  }, [editor, value])

  return null
}

function OnChangePlugin({ onChange }) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener((listener) => {
      const { editorState, ...rest } = listener
      editorState.read(() => {
        const htmlString = $generateHtmlFromNodes(editor, null);
        onChange(htmlString);
      })
    });
  }, [editor, onChange]);

  return null
}

const Editor = forwardRef((props, ref) => {
  const { onChange, onBlur, value } = props
  
  return (
    <Box border={"1px solid var(--chakra-colors-gray-200)"} ref={ref} minHeight={500}>
      <LexicalComposer initialConfig={getEditorConfig(value ?? '')}>
        <div className="editor-container">
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={<ContentEditable 
              className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <LinkPlugin />
            <ImagePlugin />
            <MentionsPlugin />
            <EmbedVideoPlugin />
            <HistoryPlugin />
            <PasteFormattedContentPLugin value={value}/>
            <OnChangePlugin onChange={(htmlString) => {
              onChange && onChange(htmlString)
            }} />
          </div>
          <ToolbarPlugin />
        </div>
      </LexicalComposer>
    </Box>
  );
})

export default Editor;
