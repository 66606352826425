import Image, { ImageVariants } from './shared/image.type';

export const ProfessionsMap:Record<string, string> = {
  'designer': 'Designer',
  'photographer': 'Photographer',
  'hairstylist': 'Hair stylist',
  'stylist': 'Stylist',
  'creativedirector': 'Creative director',
  'model': 'Model',
  'videographer': 'Videographer',
  'makeupartist': 'Makeup artist',
  'nailartist': 'Nail artist',
  'retoucher': 'Retoucher'
}

export enum AccountType {
  FashionCompany = 1,
  FashionArtist = 2,
  FashionLover = 3,
}

export enum CompanyType {
  Brand = 1,
  Service = 2,
  Agency = 3
}

enum Gender {
  Male = 1,
  Female = 2,
  NonBinary = 3
}

export enum ColourTheme {
  Flare = 1,
  Metro = 2
}

export enum HomePage {
  itchHome = 1,
  itchZine = 2,
  myAccount = 3
}

export interface DeactivationReason {
  reason: string;
  comments: string;
}

export type User = {
  _id?: string,
  accountType: AccountType,
  primaryProfession: string,
  secondaryProfessions: string[],
  firstName: string,
  lastName: string,
  email: string,
  countryCode: string,
  agreeToTerms: Boolean,
  verifiedOn: Date,
  gender: Gender,
  fashionStatement: string,
  profileImage: Image | null,
  billboardImage: Image | null,
  colourTheme: ColourTheme,
  companyName: string,
  companyType: CompanyType,
  itchUrl: string,
  homepage: HomePage,
  createdAt: Date
  companyTypeName?: string,
  name?: string,
  accountTypeName?: string,
  categories: string[],
  markedForDeactivation: Boolean,
  deleteScheduledDate: Date,
  deactivation: DeactivationReason, 
  isGhostUser: boolean
}

export const UserMethods = {
  getAccountTypeName: (v:AccountType) => {
    switch (v) {
      case AccountType.FashionArtist: return 'Fashion Artist'
        break;
      case AccountType.FashionCompany: return 'Fashion Company'
        break;
      case AccountType.FashionLover: return 'Fashion Lover'
        break;
      default:
        return ''
        break
    }
  },
  getCompanyTypeName: (v:CompanyType) => {
    switch (v) {
      case CompanyType.Agency : return 'Agency';
      case CompanyType.Brand : return 'Branding';
      case CompanyType.Service: return 'Services';
    }
  },
  getProfessionName: (v:string) => {
    return ProfessionsMap[v]
  },
  getName: ({
    accountType,
    companyType,
    firstName,
    lastName,
    companyName
  }: {
    accountType?: AccountType,
    companyType?: CompanyType,
    firstName?: string,
    lastName?: string,
    companyName?: string
  }) => {
    if (accountType === AccountType.FashionCompany) {
      return companyName
    } else {
      return firstName + ' ' + lastName
    }
  },
  getCategories: ({
    accountType,
    companyType,
    primaryProfession,
    secondaryProfessions = [],
  }: {
    accountType: AccountType,
    companyType: CompanyType,
    primaryProfession: string,
    secondaryProfessions: string[],
  }): string[] => {
    if (accountType === AccountType.FashionCompany) {
      return [UserMethods.getCompanyTypeName(companyType)]
    } else
    if (accountType === AccountType.FashionArtist) {
      if (primaryProfession)
        return [primaryProfession, ...secondaryProfessions]
      else
        return []
    } else {
      return []
    }
  },
  getCategoriesNames: ({
    accountType,
    companyType,
    primaryProfession,
    secondaryProfessions = [],
  }: {
    accountType: AccountType,
    companyType: CompanyType,
    primaryProfession: string,
    secondaryProfessions: string[],
  }): string[] => {
    if (accountType === AccountType.FashionCompany) {
      return [UserMethods.getCompanyTypeName(companyType)]
    } else
    if (accountType === AccountType.FashionArtist) {
      if (primaryProfession)
        return [primaryProfession, ...secondaryProfessions].map(UserMethods.getProfessionName)
      else
        return []
    } else {
      return []
    }
  }
}

export const hydrateDerivedUser = (user: GetAllUserItem):GetAllUserItem => ({
  ...user,
  name: UserMethods.getName(user),
  companyTypeName: UserMethods.getCompanyTypeName(user.companyType),
  accountTypeName: UserMethods.getAccountTypeName(user.accountType),
  categories: user.accountType === AccountType.FashionCompany ? [UserMethods.getCompanyTypeName(user.companyType)] : UserMethods.getCategories(user)
})

export type GetAllUserItem = User & {
  id: string;
}

export type GetUserDetail = User & {
  id: string
}

export type GetOneUser = User & {
  _id: string;
}

export type UserListRow = {
  id: string,
  accountType: AccountType,
  accountTypeName: string,
  categories: string[],
  name: string,
  email: string,
  verifiedOn: Date,
  countryCode: string,
  isGhostUser: boolean
}

export type FollowingUserListRow = {
  _id: string,
  accountType: AccountType,
  countryCode: string,
  name: string,
  email: string,
  profileImage: Image | null,
  profileImageVariants: ImageVariants,
}

export type UserCompact = {
  id: string;
  name: string;
  accountType: number;
  avatar?: {
    filename: string,
    height: number,
    width: number,
    mimeType: string,
    position: string,
    url: string
  }
}

export type UserActivitiesCountView = {
  viewed: number;
  voted: number;
  itchbooked: number;
  commented: number;
  responded: number;
  artworks: number;
  articles: number;
  following: number;
  followers: number;
  conversations: number;
}

export type UserActivitiesCountPayload = Record<string, number>

export type EditUserDetails = Pick<User, 
  'email' | 'accountType' | 'billboardImage' | 'colourTheme' | 'companyName' |
  'companyType' | 'countryCode' | 'fashionStatement' | 'firstName' | 'lastName' |
  'homepage' | 'itchUrl' | 'primaryProfession' | 'secondaryProfessions' | 'profileImage'>