import { 
	Flex, Table, Box, Button, HStack,
	Tbody, Td, Text, Tfoot, 
	Th, Thead, Tr, useColorModeValue,
	Select, Progress
} from '@chakra-ui/react';
import React, { useMemo, useState, useEffect } from 'react';
import Checkbox from 'components/checkbox/checkbox';
import {
	useReactTable, 
	getCoreRowModel, 
	getPaginationRowModel, 
	getSortedRowModel,
	flexRender, 
	functionalUpdate,
	ColumnDef 
} from '@tanstack/react-table';
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import { MdArrowForward, MdArrowBack } from "react-icons/md";

import { useQuery } from '@tanstack/react-query';
import { useRouteMatch, useHistory } from 'react-router-dom';

// Custom components
import Card from 'components/card/Card';
import DataTableMenu from 'components/menu/DataTableMenu';

// Types
import {
	DataTableProps,
	DataTableQuery
} from './dataTable2.types';
import {
  PaginatedResult
} from 'apis/api.types'
import { unescape } from 'querystring';

type SelectedRows = {
	key: string,
	ids: Record<string, boolean>
}

const getSelectedRows = (key: string): Record<string, boolean> => {
	return JSON.parse(localStorage.getItem(key) ?? '{}') as Record<string, boolean>
}

export default function DataTable<T extends object>(props: DataTableProps<T, DataTableQuery>) {
	const { 
		columnsMeta, 
		menuOptions, 
		page = 0, 
		size = 10, 
		sort, 
		desc, 
		query,
		cacheKey,
		cacheKeyArgs = null,
		actions,
		onRowClick,
		onRowSelectionChange,
		queryFn,
		getRowId = (row, index, parent) => {
			return index.toString()
		},
		rowKey = 'id',
		enableSorting = false,
		enableRowSelection = false
	} = props;

	const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({})

	const selectorColumn = useMemo<ColumnDef<T>>(
		() => ({
			accessorKey: rowKey,
			id: 'select',
			header: ({ table }) => (
				<>select</>
			),
			size: 5,
			cell: ({ row }) => (
				<Checkbox 
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler()
					}}
				/>
			)
		}), []
	)

	const { url } = useRouteMatch();
	const { push }= useHistory();

	const filterOptions: Record<string, any> = {
		pageIndex: page,
		pageSize: size
	}

	if (sort && desc) {
		filterOptions['sorting'] = {
			id: sort,
			desc: desc
		}
	}

	const { 
		isLoading, isSuccess, error, data, isFetching
	} = useQuery<PaginatedResult<T>>({
		queryKey: [cacheKey, {
			...filterOptions,
			...cacheKeyArgs
		}],
		queryFn: () => {
			return queryFn({
				page: page,
				size: size,
				query,
				sort,
				desc
			})
		},
		keepPreviousData: true
	})

	const columns = useMemo(() => {
		if (enableRowSelection)
			return [selectorColumn, ...columnsMeta]
		else
			return columnsMeta
	}, [ columnsMeta, enableRowSelection ]);
	
	const pagination = React.useMemo(
		() => ({
			pageIndex: page,
			pageSize: size,
		}),
		[page, size]
	)

	const sorting = React.useMemo(
		() => ([
			{
				id: sort,
				desc: desc
			}
		]),
		[sort, desc]
	)

	const table = useReactTable<T>({
			columns,
			data: data?.data??[],
			manualPagination: true,
			manualSorting: true,
			enableRowSelection,
			enableSorting,
			getRowId,
			pageCount: data?.totalPages ?? 0,
			state: {
				sorting,
				pagination,
				rowSelection
			},
			getCoreRowModel: getCoreRowModel(),
			getPaginationRowModel: getPaginationRowModel(),
			getSortedRowModel: getSortedRowModel(),
			onPaginationChange: (updater) => {
				const updatedState = functionalUpdate(updater, {
					pageIndex: page,
					pageSize: size
				})
				const moreParams = []
				if (query)
					for (const [key, value] of Object.entries(query)) {
						moreParams.push(`${key}=${value}`)
					}
				if (sort)
					moreParams.push(`sort=${sort}`)
				if (desc !== undefined)
					moreParams.push(`desc=${desc}`)
				if (updatedState) {
					if (updatedState.pageIndex)
						moreParams.push(`page=${updatedState.pageIndex}`)
					if (updatedState.pageSize)
						moreParams.push(`size=${updatedState.pageSize}`)
				}
				push(`${url}?${moreParams.length > 0 ? moreParams.join('&') : ''}`)
			},
			onSortingChange: (updater) => {
				const updatedState = functionalUpdate(updater, [
					{
						id: sort,
						desc: desc
					}
				])
				const moreParams = []
				if (query)
					for (const [key, value] of Object.entries(query)) {
						moreParams.push(`${key}=${value}`)
					}
				if (page)
					moreParams.push(`page=${page}`)
				if (size)
					moreParams.push(`size=${size}`)

				console.log(updatedState)
				if (updatedState.length > 0) {
					if (updatedState[0].id)
						moreParams.push(`sort=${updatedState[0].id}`)
					if (updatedState[0].desc !== undefined)
						moreParams.push(`desc=${updatedState[0].desc}`)
				}
				push(`${url}?${moreParams.length > 0 ? moreParams.join('&') : ''}`)
			},
			onRowSelectionChange: setRowSelection
	});

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const handleRowClick = (row: T) => {
		onRowClick && onRowClick(row)
	}

	useEffect(() => {
		const items: Array<T> = []
		const keys = Object.keys(rowSelection)
		for (const key of keys) {
			if (rowSelection[Number(key)] as boolean) {
				items.push(data.data[Number(key)])
			}
		}
		onRowSelectionChange && onRowSelectionChange(items)
	}, [rowSelection])

	const paginationUI = (pages: number, page: number): React.ReactElement => {
		const pageNumbers = []
		for (let i = 0; i < pages; i++) {
			pageNumbers.push(i)
		}

		return (
			<Flex alignItems={"center"} flexDirection='row'>
				<Box me={5}>Page</Box>
				<Select 
					me={2}
					value={page}
					onChange={(evt) => {
						table.setPageIndex(Number(evt.target.value ?? 0))
					}}
				>
					{pageNumbers.map(i => 
					<option
						key={`option-${i}`}
						value={i}>{i + 1}
					</option>)}
				</Select>
				<Flex>
					<Button
						disabled={!table.getCanPreviousPage()}
						onClick={() => {
							table.previousPage()
						}}><MdArrowBack/></Button>
					<Button
						disabled={!table.getCanNextPage()}
						onClick={() => {
							table.nextPage()
						}}><MdArrowForward/></Button>
				</Flex>
			</Flex>)
	}

	return (
		<>
		{isLoading || isFetching ? <Progress size='sm' isIndeterminate /> : null}
		{isSuccess && data ? <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' justify='space-between' mb='20px' align='center' w={'100%'}>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					{props.title}
				</Text>
				<HStack>
					{enableRowSelection ? 
					<HStack>
						<Box mr={3}>{Object.entries(rowSelection).length} selected </Box>
						<Button onClick={() => {
							table.resetRowSelection()
						}}>Reset</Button>
					</HStack> : null}
					{actions ? <Box>{actions}</Box> : null}
					<Box>
						{paginationUI(table.getPageCount(), page)}
					</Box>
					{menuOptions ? <DataTableMenu options={menuOptions} /> : null}
				</HStack>
			</Flex>
			<Table variant='simple' color='gray.500' mb='24px'>
				<Thead>
					{table.getHeaderGroups().map((headerGroup, index) => (
						<Tr {...headerGroup} key={index}>
							{headerGroup.headers.map((header, index) => (
								<Th
									style={{
										width: header.column.columnDef.size ? `${header.column.columnDef.size}%` : 'auto'
									}}
									pe='10px'
									key={header.id}
									borderColor={borderColor}>
									<Flex
										{...{
											onClick: header.column.getToggleSortingHandler()
										}}
										cursor={header.column.getCanSort() ? 'pointer' : 'default'}
										justify='space-between'
										align='center'
										fontSize={{ sm: '10px', lg: '12px' }}
										color='gray.400'>
										{flexRender(
											header.column.columnDef.header,
											header.getContext()
										)}
										{{
											asc: <TiArrowSortedUp />,
											desc: <TiArrowSortedDown />,
										}[header.column.getIsSorted() as string] ?? null}
									</Flex>
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map(row => {
						return (
							<Tr key={row.id}
								cursor={enableRowSelection || onRowClick ? 'pointer' : 'default'}
								onClick={() => handleRowClick(row.original)} >
								{row.getVisibleCells().map(cell => {
									return (
										<Td key={cell.id}
											fontSize={{ sm: '14px' }}
											borderColor='transparent' >
											<Flex align='center'>
												<Text color={textColor} fontSize='sm' fontWeight='700'>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
												</Text>
											</Flex>
										</Td>
									)
								})}
							</Tr>
						)
					})}
				</Tbody>
				<Tfoot>
					<Tr>
						<Td colSpan={columns.length}>
							<Flex justifyContent={'space-between'} alignItems={'flex-start'}>
								<Box flex={'0 0 150px'} mt={2.5}>
									Total Records {data?.totalRecords ?? 0}
								</Box>
								<Box>
									{paginationUI(table.getPageCount(), page)}
								</Box>
							</Flex>
						</Td>
					</Tr>
				</Tfoot>
			</Table>
		</Card> : null}
		</>
	);
}
