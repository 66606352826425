import React from 'react'
import { InviteListRow, InvitationStatus } from 'types/invite.types'
import { getAllUnsubscribed, deleteOne } from 'apis/invites.api'
import DataTable from 'components/dataTable/dataTable2'

import { 
  Box,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'

import { DeleteIcon } from '@chakra-ui/icons'

import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { PaginatedQuery } from 'apis/api.types'
import { Route, Switch, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query'
import SearchBox from 'components/searchBox/SearchBox'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'

const UnsubscribesTable = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  const queryClient = useQueryClient()
  const mutation = useMutation((id: string) => {
    return deleteOne(id)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['unsubscribes']})
    }
  })
  const { search } = useLocation()
  const { url, path } = useRouteMatch()
  const { push } = useHistory()
  const searchParams = new URLSearchParams(window.location.search);
  const columnHelper = createColumnHelper<InviteListRow>()
  const columns: Array<ColumnDef<InviteListRow, any>> = [
    columnHelper.accessor('invitedAt', {
      header: 'Invited At',
      cell: props => dayjs(props.getValue()).format('LLL'),
      size: 25
    }),
    columnHelper.accessor('updatedAt', {
      header: 'Invited At',
      cell: props => dayjs(props.getValue()).format('LLL'),
      size: 25
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: props => props.getValue(),
      size: 30
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      cell: props => props.getValue(),
      size: 30
    }),
    columnHelper.accessor('code', {
      header: 'Signup Code',
      cell: props => props.getValue(),
      size: 10
    }),
    columnHelper.accessor('id', {
      header: '',
      cell: props => <IconButton aria-label='delete' 
        icon={<DeleteIcon/>} 
        onClick={async () => {
          mutation.mutate(props.getValue())
        }} />,
      size: 5
    })
  ]

  const props: DataTableProps<InviteListRow, DataTableQuery> = {
    title: 'Unsubscribed',
    columnsMeta: columns,
    cacheKey: 'unsubscribes',
    queryFn: getAllUnsubscribed,
    onPageChange: (page => {
      let pushUrl = `${url}?page=${page}`
      const params = new URLSearchParams(search)
      if (params.has('searchKeyword'))
        pushUrl += '&searchKeyword=' + params.get('searchKeyword')
      push(pushUrl)
    })
  }
  return (
    <Box>
      <SearchBox 
        defaultValue={new URLSearchParams(search).get('searchKeyword')??''}
        onSearch={(value) => {
          window.location.href = `${url}?searchKeyword=${value}`
        }}/>
      <DataTable {...props} query={{ searchKeyword: new URLSearchParams(search).get('searchKeyword') ?? ''}}
        page={Number(searchParams.get('page') ?? 0)} 
      />
    </Box>
  )
}

export default UnsubscribesTable;