import { useEffect } from 'react';
import axiosInstance, { updateAuthorizationHeader } from 'apis/api';
import { useMutation } from '@tanstack/react-query';
import config from 'config'

type AuthenticateInput = {
  email: string,
  password: string
}

type AuthenticateOutput = {
  status: string,
  accessToken: {
    token: string,
    tokenExpiresAt: Date
  }
}

const authenticate = (data: AuthenticateInput) => axiosInstance.post(config.apiUrl + '/admin-users/authorize', data)

const useSignIn = () => {
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (data: AuthenticateInput) => {
      return authenticate(data)
    },
  });

  useEffect(() => {
    if (isSuccess) {
      updateAuthorizationHeader(data.data.accessToken.token)
    }
  }, [isSuccess])

  return {
    signIn: mutate,
    isLoading,
    isSuccess,
    isError,
    result: data?.data as AuthenticateOutput,
    error
  }
}

export default useSignIn