import React from 'react'
import { 
  Box, Text, useColorModeValue, Grid
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';

import { getList } from 'apis/zine.api'
import DataTable from 'components/dataTable/dataTable2'
import { MediaType } from 'types/artwork.types'
import { ChapterListRow } from 'types/zine.types'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { PaginatedQuery } from 'apis/api.types'
import { useRouteMatch, useHistory } from 'react-router-dom'
import countries from 'i18n-iso-countries'
import dayjs from 'dayjs'
import Config from 'config'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { UserMethods } from 'types/user.types';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
dayjs.extend(localizedFormat)

type ChaptersTableProps = {
  size?: number
}

const ChaptersTable = ({
  size = 30
}: ChaptersTableProps) => {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  
  const searchParams = new URLSearchParams(window.location.search);
  
  const columnHelper = createColumnHelper<ChapterListRow>()

  const columns: Array<ColumnDef<ChapterListRow, any>> = [
    columnHelper.accessor('imageUrl', {
      header: "Artwork",
      size: 25,
      cell: props => <img width={400} src={`${props.getValue()}`} alt=""/>
    }),
    columnHelper.accessor('featuredArtwork', {
      header: 'Featured Artwork',
      size: 30,
      cell: props => <>{props.getValue()?.title}</>
    }),
    columnHelper.accessor('chapter', {
      header: 'Chapter',
      size: 5,
      cell: props => props.getValue()
    }),
    columnHelper.accessor('authorName', {
      header: 'Author',
      size: 20,
      cell: props => props.getValue()
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      size: 20,
      cell: props => <>{dayjs(props.getValue()).format('LLLL')}</>
    })
  ]

  const props: DataTableProps<ChapterListRow, DataTableQuery> = {
    title: 'Chapters',
    columnsMeta: columns,
    cacheKey: `chapters`,
    queryFn: ({ page, size }) => getList({
      page,
      size
    }),
    onRowClick: (data: ChapterListRow) => {
      push(`${url}/` + data.chapter)
    },
    onPageChange: (page => {
      searchParams.set('page', page.toString())
      push(`${url}?${searchParams.toString()}`)
    })
  }

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      {false && <InputField id="searchKeyword" label="Search" type="text" placeholder="Enter a search keyword"/>}
      <DataTable {...props} page={Number(searchParams.get('page') ?? 0)} />
    </Box>
  )
}

export default ChaptersTable;