import Image from './shared/image.type';
import { 
  PaginationRequest,
  PaginationOutput
} from 'types/shared/pagination.type'
import { User } from './user.types'
import { Artwork } from 'types/artwork.types'
import { Article } from 'types/article.types'
import { ImageVariants } from './shared/image.type';

export enum ZineColourTheme {
  Pink = 'pink',
  White = 'white',
  Dark = 'dark'
}

export enum Orientation {
  Landscape = 'l',
  Portrait = 'p'
}

export type CreateChapterInput = {
  artworkId: string,
  colourTheme: ZineColourTheme,
  chapter: number,
  image?: Image,
  published: boolean
}

export type CreateChapterFormInput = {
  artworkId: string,
  colourTheme: ZineColourTheme,
  chapter: number,
  image?: Image,
  published: boolean
}

export type UpdateChapterInput = {
  artworkId: string,
  colourTheme: ZineColourTheme,
  chapter: number,
  image?: Image,
  published: boolean
}

export type GetChapterOutput = {
  _id: string,
  chapter: number,
  featuredArtwork: Artwork & { _id: string },
  colourTheme: ZineColourTheme,
  image?: Image,
  published: boolean,
  createdAt: Date,
  updatedAt: Date
}

export enum ZineItemType {
  User = 'user',
  FeaturedArtwork = 'featured_artwork',
  Article = 'article',
  itchArticle = 'itch_article'
}

export type ZineItem = {
  type: ZineItemType,
  user?: User,
  article?: Article,
  artwork?: Artwork
}

export type GetZineTrendingUserAndArticles = PaginationOutput<ZineItem>

export type GetArchivedZinesItem = {
  id: string,
  chapter: number,
  name: string,
  artworkTitle: string,
  publishedDate: Date,
  live: boolean
}

export type GetArchivedZinesOutput = PaginationOutput<GetArchivedZinesItem>

export type Zine = {
  default: boolean,
  chapter: number,
  colourTheme: ZineColourTheme
  featuredArtwork: Artwork,
  itchArticle: Article,
  image: Image,
  imageVariants: ImageVariants
}

export type GetFeaturedArtworksChapterItem = {
  id: string,
  chapter: number,
  name: string,
  artworkTitle: string,
  publishedDate: Date,
  image: string,
  live: boolean
}

export type GetFeaturedArtworksChapterOutput = PaginationOutput<GetFeaturedArtworksChapterItem>

export type ChapterListRow = {
  _id: string,
  chapter: number,
  featuredArtwork: Artwork & { _id: string },
  authorName: string,
  imageUrl?: string,
  colourTheme: ZineColourTheme,
  published: boolean,
  createdAt: Date,
  updatedAt: Date
}
