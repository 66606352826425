import React, { useRef } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player/youtube'

const PlayerContainer = styled.div`
  
`

export default function YouTubeEmbed({
  url,
  ...rest
}) {
  let ytId = ''
  if (url.indexOf('https://youtu.be/') === 0) {
    ytId = url.split('/')[3]
  } else {
    ytId = url.split('?')[1].split('&')[0].split('=')[1]
  }

  return (
    <PlayerContainer {...rest}>
      <ReactPlayer 
        width={'100%'}
        height={'100%'}
        controls={true}
        url={`https://www.youtube.com/watch?v=${ytId}`} 
      />
    </PlayerContainer>
  )
}