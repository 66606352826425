import React from 'react'
import { 
  Box, Flex, Text, useColorModeValue
} from '@chakra-ui/react';

type Props = {
  label: string;
  value: string;
}

const FieldValue = ({
  label,
  value
}: Props) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';

  return (
    <Box>
      <Flex direction={"row"} justifyContent="flex-start">
        <Text fontWeight='500' color={textColorSecondary} flex={"0 0 30%"} fontSize='sm'>
          {label}
        </Text>
        <Text color={textColorPrimary} fontWeight='500' flex={"1 1 auto"} fontSize='md'>
          {value}
        </Text>
      </Flex>
    </Box>
  )
}

export default FieldValue;
