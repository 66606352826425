import React, { ChangeEvent, useState } from 'react';
import { 
  VStack, Select, StackDivider,
  Box, HStack
} from '@chakra-ui/react';

import { 
  useHistory, useRouteMatch,
  Route, Switch 
} from 'react-router-dom'

import Chapters from './Chapters';
import ChapterForm from './ChapterForm';

const FeaturedArtworks = () => {
  const { path } = useRouteMatch()
  const [ page, setPage ] = useState<number>(0)
  
  return (
    <VStack
      divider={<StackDivider borderColor='gray.200' />}
      spacing={4}
      align='stretch'
    >
      <Switch>
        <Route exact path={path}>
          <Chapters />
        </Route>
        <Route path={`${path}/:id`}>
          <ChapterForm/>
        </Route>
        <Route path={`${path}/new`}>
          <ChapterForm/>
        </Route>
      </Switch>
    </VStack>
  )
}

export default FeaturedArtworks