import React from 'react'
import { 
  Box, Text, useColorModeValue, Grid
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';

import { getList } from 'apis/articles.api'
import DataTable from 'components/dataTable/dataTable2'
import { ArticleListRow } from 'types/article.types'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { PaginatedQuery } from 'apis/api.types'
import { useRouteMatch, useHistory } from 'react-router-dom'
import countries from 'i18n-iso-countries'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { ReviewStatus } from 'types/shared/approvable.types';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
dayjs.extend(localizedFormat)

type ArticlesTableProps = {
  size?: number,
  reviewStatus: ReviewStatus
}

const ArticlesTable = ({
  size = 30,
  reviewStatus
}: ArticlesTableProps) => {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  
  const searchParams = new URLSearchParams(window.location.search);
  const columnHelper = createColumnHelper<ArticleListRow>()

  const columns: Array<ColumnDef<ArticleListRow, any>> = [
    columnHelper.accessor('imageUrl', {
      header: 'Cover Image',
      cell: props => <img width={400} src={props.getValue()} alt=""/>,
      size: 30
    }),
    columnHelper.accessor('title', {
      header: 'Title',
      cell: props => props.getValue(),
      size: 30
    }),
    columnHelper.accessor('userName', {
      header: 'Author',
      cell: props => props.getValue(),
      size: 20
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      cell: props => <>{dayjs(props.getValue()).format('LLLL')}</>,
      size: 20
    })
  ]

  const props: DataTableProps<ArticleListRow, DataTableQuery> = {
    title: 'Articles',
    columnsMeta: columns,
    query: {
      reviewStatus
    },
    page: Number(searchParams.get('page') ?? 0),
    size: Number(searchParams.get('size') ?? 10),
    cacheKey: `article-${reviewStatus}`,
    queryFn: getList,
    onRowClick: (data: ArticleListRow) => {
      push(`${url}/` + data.id)
    }
  }
  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      {false && <InputField id="searchKeyword" label="Search" type="text" placeholder="Enter a search keyword"/>}
      <DataTable {...props} page={Number(searchParams.get('page') ?? 0)} />
    </Box>
  )
}

export default ArticlesTable;