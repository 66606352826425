import React, { ChangeEvent, useEffect, useState } from 'react';
import { 
  Route, Switch, useRouteMatch, 
  useHistory, Redirect 
} from 'react-router-dom';
import ArtworkDetail from './detail'
import ArtworksListing from './ArtworksListing'

const Artworks = () => {
  const { path } = useRouteMatch()
  
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ArtworksListing/>
      </Route>
      <Route path={`${path}/:id`}>
        <ArtworkDetail/>
      </Route>
    </Switch>
  )
}

export default Artworks