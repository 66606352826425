import React from 'react'
import { useLocation } from 'react-router-dom'

import { ReviewStatus } from 'types/shared/approvable.types'

const useUrlQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => {
    const query = new URLSearchParams(search)
    return {
      reviewStatus: query.get('reviewStatus') as ReviewStatus,
      page: parseInt(query.get('page') ?? '0')
    }
  }, [search])
}

export default useUrlQuery