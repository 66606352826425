import axiosInstance from './api';
import { ChangePasswordInput } from 'types/admin-user.types'

export const updatePassword = async (data: ChangePasswordInput): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.put<void>(`/admin-users/change-password`, data)
      .then(response => {
        resolve()
      })
      .catch(err => reject(err))
  })
}