import { useMutation  } from '@tanstack/react-query';
import { uploadAndMakeVariants } from '../apis/upload.api';

export default function useUploadMakeVariant() {
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: files => {
      return uploadAndMakeVariants(files)
    }
  })

  return {
    uploadFiles: mutate,
    isLoading,
    isSuccess: isSuccess && data?.data.errors.length === 0,
    isError: isError || data?.data.errors.length > 0,
    result: data?.data.uploads,
    error: error || data?.data.errors 
  }
}