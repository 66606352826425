import axiosInstance from './api';
import { Article, ArticleApproveInput, ArticleRejectInput, ArticleListRow } from 'types/article.types'
import { ReviewStatus } from 'types/shared/approvable.types';
import { 
  PaginatedQuery,
  PaginatedResult
} from './api.types'
import { toQueryParams } from 'utils'
import Config from 'config'
import { UserMethods } from 'types/user.types';

type GetAllQueryInput = PaginatedQuery & {
  query: Record<string, string>
}

export const getList = async ({page, size, query}: GetAllQueryInput): Promise<PaginatedResult<ArticleListRow>> => {
  const queryString = '?' + toQueryParams({
    page,
    size,
    ...query
  })

  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<Article>>(`/articles${queryString}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map(o => ({
            id: o.id,
            title: o.title,
            imageUrl: `${Config.uploadsUrl}/${o.coverImage.filename}`,
            userName: UserMethods.getName(o.user),
            createdAt: o.createdAt
          }))
        })
      })
      .catch(err => reject(err))
  })
}


export const getAll = async (
  { page = 0, size = 10, query }: GetAllQueryInput
): Promise<PaginatedResult<Article>> => {
  
  const queryString = '?' + toQueryParams({
    page,
    size,
    query
  })

  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<Article>>(`/articles${query}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  })
}

export const getOne = async (
  id: string
): Promise<Article> => {
  return new Promise((resolve, reject) => {
    axiosInstance.get<Article>(`/articles/${id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => reject(err))
  })
}

export const approve = async (
  {
    id, theme
  }: ArticleApproveInput
): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.put<void>(`/articles/${id}/approve`, {
      theme
    })
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}
/*
export const approve = async (
  id: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.put<void>(`/articles/${id}/approve`)
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}
*/

export const reject = async ({
  id,
  reason = '',
}: ArticleRejectInput): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.put<void>(`/articles/${id}/reject`, {
      reason
    })
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}

export const remove = async (
  id: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.delete<void>(`/articles/${id}`)
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}