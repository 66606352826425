import Image from './shared/image.type';
import { Approvable } from './shared/approvable.types';
import { Tags } from './shared/tags.type'
import { ImageFormatTypes, ImageMetaData, ImageVariants } from './shared/image.type';
import { User } from './user.types'
import { EmbeddedComment } from './comment.type';
import { ActivityAggregates, LastActivity } from './activity.types'

export enum MediaType {
  Image = 'image',
  VideoLink = 'videoLink'
}

export type Video = {
  url: string,
  posterUrl: string,
  position: string
}

export enum Orientation {
  Landscape = 'l',
  Portrait = 'p'
}

export type Poster = {
  url: string,
  position: string,
  orientation: Orientation
}

export interface Artwork extends Approvable {
  id: string,
  slug: string,
  title: string,
  description: string,
  tags: Tags,
  mediaType: MediaType, 
  image: Image,
  imageVariants: ImageVariants,
  video: Video,
  user: User & {
    id: string
  },
  order: number,
  poster: Poster,
  comments: Array<EmbeddedComment>,
  activityAggregates: ActivityAggregates,
  activityAggregates_ghost: ActivityAggregates,
  lastActivity: LastActivity,
  userTags: Array<string>,
  createdAt: Date
}

export type ArtworkListRow = {
  id: string,
  title: string,
  mediaType: MediaType, 
  imageUrl: string,
  userName: string,
  createdAt: Date
}
