import React from 'react'
import { getAll } from 'apis/activity.api'
import DataTable from 'components/dataTable/dataTable2'
import { AccountType } from 'types/user.types'
import { ActivityLogListItem } from 'types/activity.types'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { PaginatedQuery } from 'apis/api.types'
import { useRouteMatch, useHistory } from 'react-router-dom'
import usePagingationQuery from 'hooks/use-pagination-query.hook'
import countries from 'i18n-iso-countries'
import dayjs from 'dayjs'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const ActivityLogTable = () => {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const searchQuery = usePagingationQuery()

  const searchParams = new URLSearchParams(window.location.search);
  
  const columnHelper = createColumnHelper<ActivityLogListItem>()

  const columns: Array<ColumnDef<ActivityLogListItem, any>> = [
    columnHelper.accessor('action', {
      header: 'Action',
      cell: props => props.getValue()
    }),
    columnHelper.accessor('byUserName', {
      header: 'By',
      cell: props => props.getValue()
    }),
    columnHelper.accessor('objectSchemaName', {
      header: 'Content',
      cell: props => props.getValue()
    }),
    columnHelper.accessor('objectId', {
      header: 'Content Id',
      cell: props => props.getValue()
    }),
    columnHelper.accessor('objectOwnerName', {
      header: 'Content Owner',
      cell: props => props.getValue()
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created',
      cell: props => <>{dayjs(props.getValue()).format('YYYY-MMM-D LTS')}</>
    }),
  ]
  
  const props: DataTableProps<ActivityLogListItem, DataTableQuery> = {
    title: 'Activity Logs',
    columnsMeta: columns,
    cacheKey: 'activitylogs',
    queryFn: ({ page, size }) => getAll({
      page,
      size
    }),
    onRowClick: (data: ActivityLogListItem) => {
      push(`${url}/` + data.id)
    },
    onPageChange: (page => {
      push(`${url}?page=${page}`)
    })
  }
  return (
    <DataTable {...props} size={50} page={Number(searchParams.get('page') ?? 0)} />
  )
}

export default ActivityLogTable;