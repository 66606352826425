import React from 'react'
import { forwardRef, Box, Text, useColorModeValue, BoxProps } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { SendInvitesInput } from 'types/invite.types';
import { UseFormRegister, Controller, Control, FieldErrors } from 'react-hook-form'

type Props = {
  index: number,
  register: UseFormRegister<SendInvitesInput>,
  control: Control<SendInvitesInput, any>,
  errors: FieldErrors<SendInvitesInput>
}

const InviteField = forwardRef<Props, 'div'>(({
  index,
  register,
  control,
  errors
}: Props, ref) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const bg = useColorModeValue('white', 'navy.700');

  return (
    <Box ref={ref}>
      <Controller 
        control={control}
        name={`invites.${index}.email`}
        render={({ field }) => 
          <InputField {...field} id={`invite-email-${index}`}
            error={errors.invites && errors.invites[index] && errors.invites[index].email ? errors.invites[index].email.message : null}
            label="Email" type="email" placeholder="Enter an email"/>
        }
      />
      <Controller 
        control={control}
        name={`invites.${index}.name`}
        render={({ field }) => 
          <InputField {...field} id={`invite-name-${index}`} label="Name" type="text" placeholder="Enter a name"/>
        }
      />
    </Box>
  )
});

export default InviteField;
