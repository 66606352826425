import React, { useCallback, useEffect } from 'react'
import {
  useForm,
  SubmitHandler,
  Controller
} from 'react-hook-form'
import { 
  Box, Text, useColorModeValue, Grid, VStack, Flex, Button, Input,
  Radio, RadioGroup, HStack, Select, Checkbox, CheckboxGroup
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { getOne, updateUserProfile } from 'apis/users.api'
import countries from 'i18n-iso-countries'
import UserBannerForm from './UserBannerForm';

import {
  imageUrl
} from 'shared/utils'
import { UserMethods, EditUserDetails, AccountType, ProfessionsMap, CompanyType } from 'types/user.types';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';

import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const defaultBanner = ''
const defaultAvatar = ''

const EditDetail = () => {
  const dangerColor = 'red.500'

  const { path, url } = useRouteMatch()
  const { push } = useHistory()
  const { id } = useParams<{id:string}>()
  const { isLoading, isSuccess, data } = useQuery({ 
    queryKey: ['user', id], 
    queryFn: async () => {
      return getOne(id)
    }
  })
  const queryClient = useQueryClient()
  const mutationUpdateUserProfile = useMutation((data: EditUserDetails) => {
    return updateUserProfile(
      id,
      data
    )
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user']})
      queryClient.invalidateQueries({ queryKey: ['user', { id }]})
    }
  })

  const { 
    control,
    register, 
    handleSubmit, 
    watch, 
    reset,
    formState: { errors } 
  } = useForm<EditUserDetails>({
    mode: 'onSubmit',
    defaultValues: null
  });

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const bg = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  const onSubmit: SubmitHandler<EditUserDetails> = async (data) => {
    await mutationUpdateUserProfile.mutate(data)
    push(`/admin/users/sign-ups/${id}`)
  }

  const onClose = () => {
    push(`/admin/users/sign-ups/${id}`)
  }

  const watchAccountType = watch('accountType')
  const watchPrimaryProfession = watch('primaryProfession')

  const professionsList = () => {
    let list: Array<{
      label: string,
      value: string
    }> = []
    for (let key in ProfessionsMap) {
      list.push({
        label: ProfessionsMap[key],
        value: key
      })
    }
    return list
  }

  const secondaryProfessionsList = useCallback(() => {
    let list: Array<{
      label: string,
      value: string
    }> = []
    for (let key in ProfessionsMap) {
      if (key !== watchPrimaryProfession) {
        list.push({
          label: ProfessionsMap[key],
          value: key
        })
      }
    }
    return list
  }, [watchPrimaryProfession])

  useEffect(() => {
    if (isSuccess && data) {
      reset({
        accountType: data.accountType,
        companyName: data.companyName,
        firstName: data.firstName,
        lastName: data.lastName,
        primaryProfession: data.primaryProfession,
        secondaryProfessions: data.secondaryProfessions
      })
    }
  },[isSuccess, data])

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
      {(isSuccess && data) ?
      <Card boxShadow={cardShadow}>
        <Flex flexDirection={"column"}>
          <Grid
          mb={{ base: '20px' }}
          templateColumns={{
            base: '1fr',
            lg: '1fr 3fr'
          }}
          templateRows={{
            base: 'repeat(1, 1fr)'
          }}
          gap={{ base: '20px', xl: '20px' }}>
            <Text>Account Type</Text>
            <Select {...register('accountType')}>
              <option value={AccountType.FashionCompany}>Fashion Company</option>
              <option value={AccountType.FashionArtist}>Fashion Artist</option>
              <option value={AccountType.FashionLover}>Fashion Lover</option>
            </Select>
            <Text>Name</Text>
            <Box>
            {watchAccountType == AccountType.FashionCompany ? 
            <Input
            type='text'
            placeholder='Company Name'
            {...register('companyName')}
            defaultValue={data.companyName} />  
            : <Flex flexDirection={"column"}>
                <Input
                  type='text'
                  mb={4}
                  placeholder='First Name'
                  {...register('firstName')}
                  defaultValue={data.firstName} />
                <Input
                  type='text'
                  placeholder='Last Name'
                  {...register('lastName')}
                  defaultValue={data.lastName} />  
              </Flex>}
            </Box>
            
            {watchAccountType != AccountType.FashionLover ? 
            <>
            <Text>Sub Categories</Text>
            <Box>
              {watchAccountType == AccountType.FashionArtist ? 
              <>
                <Text mb={3} >Primary Profession</Text>
                <Select mb={3} {...register('primaryProfession')}>
                  {professionsList().map(({label, value}) => <option value={value}>{label}</option>)}
                </Select>

                <Text mb={3} >Secondary Professions</Text>
                <Box>
                  <Controller 
                    name="secondaryProfessions"
                    control={control}
                    render={({ field: { ref, ...rest }}) => (
                      <CheckboxGroup {...rest}>
                        {secondaryProfessionsList().map(({label, value}) => <Checkbox me={5} value={value} >{label}</Checkbox>)}
                      </CheckboxGroup>
                    )} />
                </Box>
              </>
              : <></>}
              {watchAccountType == AccountType.FashionCompany ?
              <>
                <Text mb={3} >Company Type</Text>
                <Select mb={3} {...register('companyType')}>
                  <option value={CompanyType.Agency}>Agency</option>
                  <option value={CompanyType.Brand}>Brand</option>
                  <option value={CompanyType.Service}>Service</option>
                </Select>
              </>: <></>}
            </Box>
            </>: <></>}

          </Grid>
          <Flex flexDirection={"row"} justifyContent={"flex-end"} mb={3}>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant='darkBrand'
              color='white'
              fontSize='sm'
              fontWeight='500'
              borderRadius='70px'
              px='24px'
              py='5px'>
              Save
            </Button>
          </Flex>
        </Flex>
      </Card>
      : <></>}
      </form>
    </Box>
  )
}

export default EditDetail