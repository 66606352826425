import React from 'react'
import { forwardRef, Box, Text, useColorModeValue, BoxProps, Flex, IconButton } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { UseFormRegister } from 'react-hook-form'
import { TestEmailInput } from 'types/email.types';
import { Controller, Control } from 'react-hook-form'

import {
  AddIcon,
  DeleteIcon
} from '@chakra-ui/icons'

type Props = {
  index: number,
  register: UseFormRegister<TestEmailInput>,
  control: Control<TestEmailInput, any>,
  onRemoveField: () => unknown
}

const TestRecipientField = forwardRef<Props, 'div'>(({
  index,
  register,
  control,
  onRemoveField
}: Props, ref) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const bg = useColorModeValue('white', 'navy.700');

  return (
    <Box ref={ref}>
      <Flex justifyContent={'flex-end'}>
        <IconButton aria-label='Remove' icon={<DeleteIcon />} 
          onClick={() => {
            onRemoveField && onRemoveField()
          }}
        />
      </Flex>
      <Controller 
        control={control}
        name={`recipients.${index}.email`}
        render={({ field }) => 
          <InputField {...field} id={`invite-email-${index}`} label="Email" type="email" placeholder="Enter an email"/>
        }
      />
      <Controller 
        control={control}
        name={`recipients.${index}.name`}
        render={({ field }) => 
          <InputField {...field} id={`invite-name-${index}`} label="Name" type="text" placeholder="Enter a name"/>
        }
      />
    </Box>
  )
});

export default TestRecipientField;
