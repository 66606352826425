import React from 'react'
import { 
  Box, Text, useColorModeValue, Grid, Flex, Link
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { getOne } from 'apis/users.api'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import FieldValue from 'components/fields/FieldValue'
import dayjs from 'dayjs'

import UserBanner from './UserBanner';
import UserArtworks from './UserArtworks';
import UserArticles from './UserArticles';
import UserActivitiesCount from './UserActivitiesCount';

import countries from 'i18n-iso-countries'

import {
  imageUrl
} from 'shared/utils'
import { UserMethods } from 'types/user.types';
import { useRouteMatch, useHistory } from 'react-router-dom';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const defaultBanner = ''
const defaultAvatar = ''

const UserDetail = () => {
  const { id } = useParams<{id:string}>()
  const { isLoading, isSuccess, data } = useQuery({ 
    queryKey: ['user', id], 
    queryFn: async () => {
      return getOne(id)
    }
  })

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const bg = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const textColorActive = useColorModeValue('white', 'white');
  const textBackgroundColorActive = useColorModeValue('brand.500', 'white');

  const { path, url } = useRouteMatch()
  const { push } = useHistory()

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      {(isSuccess && data) ?
      <>
      <Flex flexDirection={"row"} justifyContent={"flex-end"} mb={3}>
        <Link
          pt={2}
          pb={2}
          ps={4}
          pe={4}
          borderRadius={8}
          backgroundColor={textBackgroundColorActive}
          color={textColorActive}
          fontWeight='500'
          onClick={() => {
            push(`${url}/edit`)
          }}>
          Edit
        </Link>
      </Flex>
      <Grid
        mb={{ base: '20px' }}
        templateColumns={{
          base: '1fr',
          lg: '1fr 1fr'
        }}
        templateRows={{
          base: 'repeat(1, 1fr)',
          lg: '1fr'
        }}
        gap={{ base: '20px', xl: '20px' }}>
        <UserBanner
          itchUrl={data.itchUrl}
          avatar={imageUrl(data.profileImage) ?? defaultBanner}
          banner={imageUrl(data.billboardImage) ?? defaultAvatar}
          name={data.name}
          accountType={data.accountTypeName}
          categories={UserMethods.getCategoriesNames(data)}
        />
        <Card boxShadow={cardShadow} bg={bg}>
          <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
          User Information
          </Text>
          <FieldValue label="UserId" value={data.id} />
          <FieldValue label="Status" value={data.deleteScheduledDate > new Date() ? 'Deactivated':'Activated'} />
          <FieldValue label="Email" value={data.email} />
          <FieldValue label="Location" value={countries.getName(data.countryCode, "en")} />
          <FieldValue label="Sign up date" value={dayjs(data.createdAt).format('D MMM YYYY HH:MM')} />  
        </Card>
      </Grid>
      <UserActivitiesCount/>
      </>
      : null}
    </Box>
  )
}

export default UserDetail