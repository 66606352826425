import React, { useEffect} from 'react'

import { 
  Box,
  Text, 
  useColorModeValue, 
  Flex, 
  Divider, 
  Button,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Link
} from '@chakra-ui/react'

import { sendInvites, checkEmailSignedUp } from 'apis/invites.api'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { SendInvitesInput } from 'types/invite.types'
import InviteField from './InviteField';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query'

import FileUpload from 'components/upload-file/upload-file';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

type Props = {
  isOpen: boolean;
  onClose: () => unknown;
  btnRef: React.MutableRefObject<undefined>
}
declare module "yup" {
  interface StringSchema {
    notSignUp(message?: string): StringSchema;
  }
}

yup.addMethod(yup.mixed, 'notSignUp', function(args) {
  const message = args
  return this.test('email', message, function(value) {
    const { path, createError } = this;
    return new Promise((resolve, reject) => {
      checkEmailSignedUp(value).then(result => {
        if (result)
            reject(createError({ path, message }))
        else
            resolve(true)
      })
    })
  })
})

const schema = yup.object().shape({
  invites: yup
    .array().of(
      yup.object().shape({
        email: yup.string()
          .email('Oops! Invalid email address')
          .notSignUp('Email is signed up')
      })
    )
});

export default function SendInvitesDrawer({
  isOpen,
  onClose,
  btnRef
}: Props) {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const bg = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  const queryClient = useQueryClient()
  const mutation = useMutation((data: SendInvitesInput) => {
    return sendInvites(data)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invites']})
    }
  })

  const { 
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isSubmitted }
  } = useForm<SendInvitesInput>({
    shouldFocusError: false,
    resolver: yupResolver(schema),
    mode: 'onBlur'
  })

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
      control, 
      name: "invites",
    });

  const onSubmit = async (data: SendInvitesInput) => {
    const invites = data.invites.filter(invite => (invite.email !== ''))
    mutation.mutate({
      invites,
      uploadedFile: data.uploadedFile
    })
    onClose()
  }

  useEffect(() => {
    reset({
      invites: [
        { name: '', email: '' },
        { name: '', email: '' },
        { name: '', email: '' }
      ]
    })
  }, [])

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      finalFocusRef={btnRef}
      isFullHeight={true}
    >
      <DrawerOverlay />
      <DrawerContent overflowY={"scroll"}>
        <DrawerCloseButton />
        <DrawerHeader>Send Invites</DrawerHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerBody>
            {fields.map((field, index) => (
              <Box mb="10px" key={`invite-${index}`} >
                <InviteField
                  errors={errors}
                  index={index}
                  register={register}
                  control={control}
                />
                <Divider/>
              </Box>
            ))}

            <Box mb={'20px'}>
              <Text>Upload Invite CSV</Text>
              <Link target={"_blank"} href={`/assets/invites.csv`}>Download CSV Template</Link>
              <Box height={'50px'}>
                <Controller 
                  control={control}
                  name={`uploadedFile`}
                  render={({ field }) => 
                    <FileUpload {...field} />
                  }
                />
              </Box>
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant='darkBrand'
              color='white'
              fontSize='sm'
              fontWeight='500'
              borderRadius='70px'
              px='24px'
              py='5px'>
              Send
            </Button>
          </DrawerFooter>
        </form>

      </DrawerContent>
    </Drawer>
  )
}