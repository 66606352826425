import React from 'react'
import { 
  Box, SimpleGrid, Flex,
  useColorModeValue, Link
} from "@chakra-ui/react";
import { 
  Route, Switch, useRouteMatch, 
  useHistory, Redirect 
} from 'react-router-dom';
import SubNavLink from 'components/subNav/link';
import ChatForm from './chat';
import EmailForm from './email';

const MassConversation = () => {
  const { path } = useRouteMatch()

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>  
      <Flex
        ms="14px"
        mb="20px"
        align="center"
      >
        <SubNavLink 
          to={`${path}/chat`}
          label="Chat"/>
        <SubNavLink 
          to={`${path}/email`}
          label="Email"/>
      </Flex>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "20px", xl: "20px" }}>
          <Switch>
            <Route exact path={`${path}`}>
              <Redirect to={`${path}/chat`} />
            </Route>
            <Route path={`${path}/chat`}>
              <ChatForm/>
            </Route>
            <Route path={`${path}/email`}>
              <EmailForm/>
            </Route>
          </Switch>
      </SimpleGrid>
    </Box>
  )
}

export default MassConversation