import axiosInstance from './api';
import { 
  Activity,
  ActivityLogListItem,
  ActivityHistoryListItem,
  GetActivityHistoryUsersOutput,
  GetActivityHistoryUsersInput,
  DeleteActivityUsersInput
} from 'types/activity.types'
import { 
  PaginatedQuery,
  PaginatedResult
} from './api.types'
import {
  UserMethods,
  UserActivitiesCountView,
  UserActivitiesCountPayload
} from 'types/user.types'
import {
  toQueryParams
} from 'utils'


export const getAll = async (props: PaginatedQuery): Promise<PaginatedResult<ActivityLogListItem>> => {
  let query = `?${props.page ? 'page=' + props.page : ''}`
  if (props.size)
    query = query + '&size=' + props.size
  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<Activity>>(`/activity-logs${query}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map((item) => ({
            id: item._id,
            action: item.action,
            createdAt: item.createdAt,
            byUserName:item.metadata.user ? UserMethods.getName(item.metadata.user) : '',
            objectId: item.metadata.relatedId,
            objectOwnerName: UserMethods.getName(item.metadata.objectOwner),
            objectSchemaName: item.metadata.schemaName
          }))
        })
      })
      .catch(err => reject(err))
  })
}

export const getUserActivityCounts = async (id: string): Promise<UserActivitiesCountView> => {
  const actions = [
    'viewed',
    'voted',
    'itchbooked',
    'commented_artwork',
    'commented_article',
    'responded',
    'responded_artwork',
    'responded_article',
    'artworks',
    'articles',
    'conversations',
    'follows'
  ]
  return new Promise((resolve, reject) => {
    axiosInstance.get<UserActivitiesCountPayload>(`/users/${id}/activities-count?actions=${actions.join(',')}`)
      .then(response => {
        resolve({
          viewed: response.data.viewed,
          voted: response.data.voted,
          itchbooked: response.data.itchbooked,
          commented: response.data.commented_artwork + response.data.commented_article,
          responded: response.data.responded_artwork + response.data.responded_article,
          conversations: response.data.conversations,
          artworks: response.data.artworks,
          articles: response.data.articles,
          following: response.data.following,
          followers: response.data.followers
        })
      })
      .catch(err => reject(err))
  })
}

export const getActivityHistoryUsers = async (props: GetActivityHistoryUsersInput): Promise<PaginatedResult<ActivityHistoryListItem>> => {
  let query = toQueryParams(props)

  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<GetActivityHistoryUsersOutput>>(`/activity-logs/search?${query}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map((item) => ({
            id: item.id,
            userId: item.userId,
            name: UserMethods.getName(item),
            createdAt: item.createdAt
          }))
        })
      })
      .catch(err => reject(err))
  })
}

export const getActivityHistoryDistinctUsers = async (props: GetActivityHistoryUsersInput): Promise<PaginatedResult<ActivityHistoryListItem>> => {
  let query = toQueryParams(props)

  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<GetActivityHistoryUsersOutput>>(`/activity-logs/search-users?${query}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map((item) => ({
            id: item.id,
            userId: item.userId,
            name: UserMethods.getName(item),
            createdAt: item.createdAt
          }))
        })
      })
      .catch(err => reject(err))
  })
}

export const getActivityHistoryUniqueUsers = async (props: GetActivityHistoryUsersInput): Promise<PaginatedResult<ActivityHistoryListItem>> => {
  let query = toQueryParams(props)

  return new Promise((resolve, reject) => {
    axiosInstance.get<PaginatedResult<GetActivityHistoryUsersOutput>>(`/activity-logs/search?${query}`)
      .then(response => {
        resolve({
          page: response.data.page,
          size: response.data.size,
          totalPages: response.data.totalPages,
          totalRecords: response.data.totalRecords,
          data: response.data.data.map((item) => ({
            id: item.id,
            userId: item.userId,
            name: UserMethods.getName(item),
            createdAt: item.createdAt
          }))
        })
      })
      .catch(err => reject(err))
  })
}

export const deleteActivities = async (props: Array<string>): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.delete(`/activity-logs`, {
      data: props
    })
      .then(() => resolve())
      .catch(err => reject(err))
  })
}

export const deleteActivityUsers = async ({
  ids,
  schemaName,
  relatedId,
  action
}: DeleteActivityUsersInput): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.delete(`/activity-logs/${schemaName}/${relatedId}/${action}`, {
      data: {
        ids
      }
    })
      .then(() => resolve())
      .catch(err => reject(err))
  })
}