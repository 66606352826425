// Chakra imports
import { Avatar, Button, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useHistory } from 'react-router-dom';

export default function UserBanner(props: {
	banner: string;
	avatar: string;
	name: string;
	itchUrl: string;
	accountType: string;
  categories: string[];
}) {
	const { itchUrl, banner, avatar, name, accountType, categories, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const textColorSecondary2 = 'secondaryGray.800';
	const borderColor = useColorModeValue('white !important', '#111C44 !important');
	const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
	
	return (
		<Card alignItems='center' {...rest} boxShadow={cardShadow} >
			<Box bg={`url('${banner}')`} bgSize='cover' borderRadius='16px' h='131px' w='100%' />
			<Avatar mx='auto' src={avatar} h='87px' w='87px' mt='-43px' border='4px solid' borderColor={borderColor} />
			<Button size="xs" onClick={()=> {
				window.open(`${process.env.REACT_APP_PUBLIC_URL}/${itchUrl}`)
			}}>View Profile</Button>
			<Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
				{name}
			</Text>
			<Text color={textColorSecondary} fontSize='sm'>
				{accountType}
			</Text>
			<Text color={textColorSecondary2} fontSize='sm'>
				{categories.join(', ')}
			</Text>
		</Card>
	);
}
