import React, { useState } from 'react';
import { 
  VStack, StackDivider,
  Box, HStack, Button
} from '@chakra-ui/react';
import { 
  useHistory, useRouteMatch
} from 'react-router-dom'

import ItchArticlesGallery from './ItchArticlesGallery';
import ItchArticlesTable from './ItchArticlesTable';

const Articles = () => {
  const { path } = useRouteMatch()
  const { push } = useHistory()
  const [ page, setPage ] = useState<number>(0)

  return (
    <>
    <HStack spacing='24px' justifyContent={"flex-end"}>
      <Box h='40px'>
        <Button
          onClick={() => {
            push(`${path}/add-article`)
          }}
          variant='brand'
          color='white'
          fontSize='sm'
          fontWeight='500'
          borderRadius='70px'
          px='24px'
          py='5px'>
          Add Itch Article
        </Button>
      </Box>
    </HStack>
    <ItchArticlesTable size={20} />
    </>
  )
}

export default Articles
