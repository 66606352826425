import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'
import { getAll, deleteComments } from 'apis/comments.api'
import DataTable from 'components/dataTable/dataTable2'
import { UserMethods } from 'types/user.types'
import { IComment, ICommentResponse, CommentTableRow } from 'types/comment.type'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { PaginatedQuery, PaginatedResult } from 'apis/api.types'
import { useRouteMatch, useHistory } from 'react-router-dom'
import usePagingationQuery from 'hooks/use-pagination-query.hook'
import countries from 'i18n-iso-countries'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { FaReply } from "react-icons/fa";
import { useMutation, useQueryClient } from '@tanstack/react-query'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

type Props = {
  id: string;
  schemaName: string; 
}

const CommentsTable = (commentsTableProps: Props) => {
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const { page, size } = usePagingationQuery()
  const queryClient = useQueryClient()
  const [rowsSelection, setRowSelection] = useState<Array<CommentTableRow>>([])

  const mutateDeleteComments = useMutation(({commentIds, responseIds} : { 
    commentIds: Array<string>,
    responseIds: Array<string> 
  }) => {
    return deleteComments({
      relatedId: id,
      schemaName,
      commentIds,
      responseIds
    })
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`comments`, {
        id,
        schemaName,
        page,
        size
      }]})
    }
  })

  const { id, schemaName } = commentsTableProps
  const searchParams = new URLSearchParams(window.location.search);
  const columnHelper = createColumnHelper<CommentTableRow>()
  const columns: Array<ColumnDef<CommentTableRow, any>> = [

    columnHelper.accessor('isResponse', {
      header: '',
      size: 5,
      cell: props => props.getValue() ? <FaReply /> : null
    }),

    columnHelper.accessor('body', {
      header: 'Body',
      size: 40,
      cell: props => <div dangerouslySetInnerHTML={{__html: props.getValue() }}></div>
    }),
    columnHelper.accessor('user', {
      header: 'Name',
      size: 20,
      cell: props => UserMethods.getName(props.getValue())
    }),

    columnHelper.accessor('createdAt', {
      header: 'Created At',
      size: 30,
      cell: props => dayjs(props.getValue()).format('LLLL')
    }),
  ]

  const tableActions = <>
    <Button onClick={() => {
      let commentIds = rowsSelection.filter(item => !item.isResponse).map((item) => item.id)
      let responseIds = rowsSelection.filter(item => item.isResponse).map((item) => item.id)
      mutateDeleteComments.mutate({
        commentIds,
        responseIds
      })
    }}>Delete</Button>
  </>

  const props: DataTableProps<CommentTableRow, DataTableQuery> = {
    title: 'Comments',
    page: Number(searchParams.get('page') ?? 0),
    size: Number(searchParams.get('size') ?? 10),
    columnsMeta: columns,
    cacheKey: `comments`,
    queryFn: async (pagination) => {
      const data = await getAll({id, schemaName, ...pagination})
      const flattenComments: Array<CommentTableRow> = []
      
      for(const comment of data.data) {
        flattenComments.push({
          id: comment._id,
          user: comment.user,
          body: comment.body,
          flagged: comment.flagged,
          createdAt: comment.createdAt,
          isResponse: false
        })

        for(const response of comment.responses) {
          flattenComments.push({
            id: response._id,
            user: response.user,
            body: response.body,
            flagged: response.flagged,
            createdAt: response.createdAt,
            isResponse: true
          })
        }
      }
      
      const out: PaginatedResult<CommentTableRow> = {
        ...data,
        data: flattenComments
      }
      return out
    },
    cacheKeyArgs: {
      schemaName,
      id
    },
    onPageChange: (page => {
      push(`${url}?page=${page}&`)
    }),
    enableRowSelection: true,
    actions: tableActions,
    onRowSelectionChange: (rows: Array<CommentTableRow>) => {
      setRowSelection(rows)
    }
  }
  return (
    <DataTable {...props} />
  )
}

export default CommentsTable;