import React from "react";
import { 
  Box, Text, useColorModeValue, Grid, Switch, Button
} from '@chakra-ui/react';
import { 
  FaEye
} from 'react-icons/fa';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';

import { getFollowing } from 'apis/users.api'

import DataTable from 'components/dataTable/dataTable2'
import { DataTableProps, DataTableQuery } from 'components/dataTable/dataTable2.types'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import { FollowingUserListRow } from 'types/user.types'
import Config from 'config'
import { formatAccountTypeName } from 'utils'

import countries from 'i18n-iso-countries'
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

type FollowingsProps = {
  size?: number
}

export default function Followings({
  size = 30
}: FollowingsProps) {
  const { path, url } = useRouteMatch()
  const { push } = useHistory()
  const { id } = useParams<{
    id: string
  }>()

  const searchParams = new URLSearchParams(window.location.search);

  const columnHelper = createColumnHelper<FollowingUserListRow>()

  const columns: Array<ColumnDef<FollowingUserListRow, any>> = [
    columnHelper.accessor('accountType', {
      header: 'Account Type',
      cell: props => <div>{formatAccountTypeName(props.getValue())}</div>,
      size: 20
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      cell: props => props.getValue(),
      size: 20
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: props => props.getValue(),
      size: 20
    }),
    columnHelper.accessor('countryCode', {
      header: 'Country',
      cell: props => <div>{countries.getName(props.getValue(), "en")}</div>,
      size: 10
    }),
    columnHelper.accessor('_id', {
      header: '',
      cell: props => <FaEye onClick={() => {
        push(`/admin/users/sign-ups/` + props.getValue())
      }} />,
      size: 5
    })
  ]

  const props: DataTableProps<FollowingUserListRow, DataTableQuery> = {
    title: 'Following',
    enableSorting: false,
    columnsMeta: columns,
    cacheKey: 'users',
    queryFn: ({ page, size }) => getFollowing({id, page, size}),
    onRowClick: (data: FollowingUserListRow) => {
      
    },
    onPageChange: (page => {
      searchParams.set('page', page.toString())
      push(`${url}?${searchParams.toString()}`)
    })
  }

  return (
    <Box pt={{ md: '10px', xl: '10px' }}>
      <DataTable {...props} 
        page={Number(searchParams.get('page') ?? 0)} />
    </Box>
  )
}
