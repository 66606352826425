import React, { useRef } from 'react'
// Chakra imports
import { Avatar, Box, Button, Flex, Icon, Image, Link, Text, useColorModeValue, Input } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { globalStyles } from 'theme/styles'
import { approve, reject } from 'apis/artworks.api';
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { ReviewStatus } from 'types/shared/approvable.types';
import { MdContentCopy } from 'react-icons/md'

export default function ArtworkCard(props: {
	id: string;
	image: string;
	name: string;
	author: string;
	showApprovableActions?: boolean;
	status?: ReviewStatus;
	onReject?: (id:string) => unknown;
	showCopyId?: boolean;
	onClick?: () => unknown;
}) {
	const IdElem = useRef<HTMLInputElement>()
	const { id, image, name, author, showApprovableActions, status, onReject, showCopyId = false, onClick } = props;
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorBid = useColorModeValue('brand.500', 'white');
	const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

	const queryClient = useQueryClient()
  const mutationApprove = useMutation((data: string) => {
    return approve(data)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['artworks']})
    }
  })

	const handleApprove = () => {
		mutationApprove.mutate(id)
	}

	const handleReject = () => {
		onReject && onReject(id)
	}


	return (
		<Card p='20px' boxShadow={cardShadow}>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Box mb={{ base: '20px', '2xl': '20px' }} position='relative' height={400}>
					<Image
						onClick={onClick} cursor={onClick ? 'pointer': 'default'}
						src={image}
						objectFit={'cover'}
						objectPosition={'center center'}
						w={ '100%' }
						h={ '100%' }
						borderRadius='20px'
					/>
				</Box>
				<Flex flexDirection='column'justify='space-between' h='100%'>
					<Flex
						flex={'0 0 160px'} 
						overflow={'auto'}
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb='auto'>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{name}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'sm'
								}}
								fontWeight='400'
								me='14px'>
								{author}
							</Text>
						</Flex>
					</Flex>
					{showCopyId  ? <Flex direction={"row"} mt={4} cursor={'pointer'}>
						<Text fontSize={'sm'} pe={2} fontWeight={'600'}>ID</Text>
						<Input readOnly={true} size={'xs'} value={id} me={2} ref={IdElem} />
						<Box mt={1}>
							<MdContentCopy height={20} onClick={() => {
								navigator.clipboard.writeText(id)
							}}/>
						</Box>
					</Flex> : null}
					<Button
						onClick={() => {
							window.open(`${process.env.REACT_APP_PUBLIC_URL}/artworks/${id}`)
						}}
						variant='outline'
						fontSize='sm'
						color='blue'
						mt="5"
						fontWeight='500'
						borderRadius='70px'
						px='24px'
						py='5px'>
						View Solo Artwork
					</Button>
					{showApprovableActions ? <Flex
						align={{
							base: 'center',
							md: 'start',
							lg: 'center',
							xl: 'start',
							'2xl': 'center'
						}}
						marginTop={'auto'}
						justifySelf={'flex-end'}
						justify='space-between'
						direction={{
							base: 'row',
							md: 'row',
							lg: 'row',
							xl: 'row',
							'2xl': 'row'
						}}
						mt='25px'>
						{status === ReviewStatus.Pending || status === ReviewStatus.Approved ? <Button
							onClick={handleReject}
							variant='outline'
							color={globalStyles.colors.red[500]}
							fontSize='sm'
							fontWeight='500'
							borderRadius='70px'
							px='24px'
							py='5px'>
							Reject
						</Button> : null}
						{status === ReviewStatus.Pending || status === ReviewStatus.Rejected ? <Button
							onClick={handleApprove}
							variant='brand'
							color='white'
							fontSize='sm'
							fontWeight='500'
							borderRadius='70px'
							px='24px'
							py='5px'>
							Approve
						</Button> : null}
					</Flex> : null}
				</Flex>
			</Flex>
		</Card>
	);
}
