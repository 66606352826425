export enum ReviewStatus {
  Draft = 'draft',
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected'
}

export enum RejectReason {
  Explicit = 'explicit',
  Legal = 'legal',
  Irrelevant = 'irrelevant',
  Unprofessional = 'unprofessional' 
}

export interface Approvable {
  reviewStatus: ReviewStatus,
  reviewAt: Date,
  reviewRespondAt: Date,
  rejectedReason: RejectReason
}

export type RejectInput = {
  reason: RejectReason;
}