import { useMutation  } from '@tanstack/react-query';
import { deleteFile } from 'apis/upload.api';

export default function useDeleteUpload() {
  const { mutate, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: (filename: string) => {
      return deleteFile(filename)
    },
    onSuccess: (data, variables) => {
      
    }
  })

  return {
    deleteFile: mutate,
    isLoading,
    isSuccess,
    isError,
    error 
  }
}