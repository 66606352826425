import React from 'react'
import { 
  Box, Text, useColorModeValue, SimpleGrid, Icon
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom';
import { getUserActivityCounts } from 'apis/activity.api'
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { 
  MdRemoveRedEye,
  MdThumbUpAlt,
  MdOutlineBookmark,
  MdOutlineChat,
  MdOutlineReply,
  MdOutlinePhoto,
  MdSupervisorAccount,
  MdPersonAdd,
  MdQuestionAnswer,
  MdDescription
} from 'react-icons/md';
import { Link, useRouteMatch } from 'react-router-dom';

const UserActivitiesCount = () => {
  const { path, url } = useRouteMatch()

  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const { id } = useParams<{id:string}>()
  const { isLoading, isSuccess, data } = useQuery({ 
    queryKey: ['user-activity-counts', id], 
    queryFn: async () => {
      return getUserActivityCounts(id)
    }
  })

  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
    {isSuccess ? <>
      <MiniStatistics
        startContent={
          <Link to={`${url}/actions?actions=viewed`}>
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdRemoveRedEye} color={brandColor} />}
            />
          </Link>
        }
        name='Views'
        value={data.viewed}
      />
      <MiniStatistics
        startContent={
          <Link to={`${url}/actions?actions=voted`}>
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdThumbUpAlt} color={brandColor} />}
            />
          </Link>
        }
        name='Votes'
        value={data.voted}
      />
      <MiniStatistics
        startContent={
          <Link to={`${url}/actions?actions=itchbooked`}>
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdOutlineBookmark} color={brandColor} />}
            />
          </Link>
        }
        name='Itchbooks'
        value={data.itchbooked}
      />
      <MiniStatistics
        startContent={
          <Link to={`${url}/actions?actions=commented_article,commented_artwork`}>
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdOutlineChat} color={brandColor} />}
            />
          </Link>
        }
        name='Comment'
        value={data.commented}
      />
      <MiniStatistics
        startContent={
          <Link to={`${url}/actions?actions=responded_article,responded_artwork`}>
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdOutlineReply} color={brandColor} />}
            />
          </Link>
        }
        name='Respond'
        value={data.responded}
      />
      <MiniStatistics
        startContent={
          <Link to={`${url}/artworks`}>
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdOutlinePhoto} color={brandColor} />}
            />
          </Link>
        }
        name='Gallery'
        value={data.artworks}
      />
      <MiniStatistics
        startContent={
          <Link to={`${url}/articles`}>
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdDescription} color={brandColor} />}
            />
          </Link>
        }
        name='Articles'
        value={data.articles}
      />
      <MiniStatistics
        startContent={
          <Link to={`${url}/followings`}>
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdPersonAdd} color={brandColor} />}
            />
          </Link>
        }
        name='Following'
        value={data.following}
      />
      <MiniStatistics
        startContent={
          <Link to={`${url}/followers`}>
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdSupervisorAccount} color={brandColor} />}
            />
          </Link>
        }
        name='Followers'
        value={data.followers}
      />
      <MiniStatistics
        startContent={
          <Link to={`${url}/actions?actions=started_conversation`}>
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='32px' h='32px' as={MdQuestionAnswer} color={brandColor} />}
            />
          </Link>
        }
        name='Conversations'
        value={data.conversations}
      />
    </> : null}
    </SimpleGrid>
  )
}

export default UserActivitiesCount
