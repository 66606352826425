import axiosInstance from './api';

export const broadcastMessage = async (message: string):Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.post<void>(`/messaging/all`, {
      body: message
    })
      .then(() => {
        resolve()
      })
      .catch(err => reject(err))
  })
}

export const deleteLastBroadcast  = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstance.delete<void>(`/messaging/all/last`).then(() => {
      resolve()
    }).catch(err => reject(err))
  })
}
